import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Button,
  Divider,
  Select,
  Icon,
  message,
  Tabs,
  InputNumber,
  Table,
  Modal,
} from 'antd';
import CurrencyInput from 'react-currency-input';
import { fireBase } from '../../../firebase/firebase';
import { getOwnerId, isCashbackPresent, isDebitPresent, isLoyaltyPresent } from '../../auth/auth-provider';
import { clearCpfFormat, formatCNPJ, isCNPJ } from '../../funcs/utils';
import mensagem from '../../messages/message';
import usuarioDB from '../../../dataManager/dtmUsuario';
import administradorDB from '../../../dataManager/dtmAdministrador';
import userAdmin from '../../userAdmin/userAdmin';
import './AdministradorCRUD.css';

const { Option } = Select;
const { TabPane } = Tabs;
const key = 'updatemessage';


class AdministradorCRUD extends React.Component {
  state = {
    loading: false,
    confirmDirty: false,
    autoCompleteResult: [],
    showAtivo: 'none',
    nomeCadastro: 'cadastro',
    data: { 
      key: this.props.record.key,
      ativo: 'Sim',
      razaoSocial: '',
      nomeFantasia: '',
      cnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: '',
      },
      premiacao: {
        cashback: {
          valor: undefined,
          limite: undefined,
        },
        fidelidade: {
          valor: undefined,
        }
      },

    },
    permissoes: {
      financeiro: false,
      fidelidade: false,
      cashback: false,
    },

    columns: [
      {
        title: 'Pontos',
        dataIndex: 'pontos',
        key: 'pontos',
      },
      {
        title: 'Prêmio',
        dataIndex: 'premio',
        key: 'premio',
      },
      {
        title: 'Cód Produto *',
        dataIndex: 'codProduto',
        key: 'codProduto',
      },
      {
        title: '',
        key: 'action',
        width: '100px',
        render: (text, record) => (
          <span>

            <Tooltip placement="topLeft" title="Editar Cadastro">
              <Icon
                type="edit"
                onClick={() => this.editPontos(record)}
              />
            </Tooltip>

            <Divider type="vertical"></Divider>

            <Tooltip placement="topLeft" title="Excluir">
              <Icon
                type="delete"
                onClick={() => this.deletePontos(record)}
              />
            </Tooltip>

          </span>
        ),
      }
    ],

    premios: [],
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.limitValueCashbackChange = this.limitValueCashbackChange.bind(this);
    this.editPontos = this.editPontos.bind(this);
    this.deletePontos = this.deletePontos.bind(this);
    this.updatePremiacao = this.updatePremiacao.bind(this);
    this.ValorParaPontosChange = this.ValorParaPontosChange.bind(this);
    this.premioEditElement = React.createRef();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        var keys = [];
        for (var k in err) {
          keys.push(k);
        }
        let counter = 1;
        for (let index = 0; index < keys.length; index++) {
          const key = keys[index];
          if (counter <= 3) {
            message.error(err[key].errors[0].message);
            counter += 1;
          }
        }
        return;
      }

      const temPremiacao = this.state.data.premiacao !== undefined && this.state.data.premiacao.fidelidade !== undefined && this.state.data.premiacao.fidelidade.valor !== undefined;

      if (!this.state.premios) {
        this.state.premios = [];
      }

      if (temPremiacao && this.state.premios.length <= 0) {
        mensagem.openNotificationWithIcon('error', 'Prêmiação faltando', 'Defina pelo menos uma regra de pontuação e prêmio para resgate');
        return;
      }

      this.setState({ loading: true });

      if (!isCNPJ(values.cnpj)) {
        mensagem.openNotificationWithIcon('error', 'CNPJ inválido', 'O CNPJ informado não está correto.');
        this.setState({ loading: false });
        return;
      }
      values.cnpj = formatCNPJ(values.cnpj);

      // procuro pelo CNPJ para checar se está repetido
      let snapshot = await administradorDB.getByCNPJ(values.cnpj);

      // Verifico se está havendo uma duplicação de CNPJ
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O CNPJ informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = await administradorDB.getByEmail(values.email);
      if (snapshot !== undefined) {
        if ((this.props.editMode && snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) || (!this.props.editMode)) {
          mensagem.openNotificationWithIcon('error', 'Duplicação', 'O email informado já foi cadastrado.');
          this.setState({ loading: false });
          return;
        }
      }

      const ownerId = await getOwnerId();

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        razaoSocial: values.razaoSocial,
        nomeFantasia: values.nomeFantasia,
        cnpj: values.cnpj,
        email: values.email,
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        ownerId,
      }

      const premiacao = {}
      if (values.cashback) {
        premiacao.cashback = {
          valor: values.cashback ? Number(values.cashback) : 0,
          limite: values.cashbackLimit ? values.cashbackLimit : 0,
        }
      }

      if (temPremiacao) {
        premiacao.fidelidade = {
          valor: values.fidelidadeValor ? values.fidelidadeValor : 0,
          premios: this.state.premios
        }
      }

      if (values.cashback || temPremiacao) {
        item.premiacao = premiacao;
      }

      let novoId = '';
      if (!this.props.editMode) {

        // Antes de mais nada, crio um novo login com o email
        // e os seis dígitos do cnpj como senha
        const cnpj = clearCpfFormat(values.cnpj);
        let obj = undefined;
        try {
          obj = await fireBase.auth().createUserWithEmailAndPassword(values.email, cnpj.substr(0, 6));
        } catch (error) {
          if (error.code === 'auth/email-already-in-use') {
            message.error('Este email já está cadastrado');
            this.setState({ loading: false });
            return;
          }
        }

        if (!obj) {
          message.error(`Erro ao criar o administrador com o email ${values.email}`);
          this.setState({ loading: false });
          return;
        }

        const rec = await administradorDB.add(item);
        novoId = rec.id;

        const usuario = {
          uid: obj.user.uid,
          tipo: 'Administrador',
          ownerId,
        }

        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);
      }
      else {
        const isChanged = this.props.record.email !== values.email;
        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loading: false });
          message.warning('Verifique o email do administrador');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }

        await administradorDB.update(this.props.record.key, item);
        const active = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;
        userAdmin.activeOrDeactiveUser(item.email, active);
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O ${this.state.nomeCadastro} ${values.nomeFantasia} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
      this.props.handleOk(item);
      this.setState({ loading: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentDidMount() {

    const permissoes = this.state.permissoes;

    permissoes.financeiro = await isDebitPresent();
    permissoes.cashback = await isCashbackPresent();
    permissoes.fidelidade = await isLoyaltyPresent();

    if (this.props.editMode && this.props.record) {
      const data = {
        key: this.props.record.key,
        ativo: this.props.record.ativo ? 'sim' : 'nao',
        razaoSocial: this.props.record.razaoSocial,
        nomeFantasia: this.props.record.nomeFantasia,
        cnpj: this.props.record.cnpj,
        email: this.props.record.email,
        endereco: this.props.record.endereco,
      }

      if (this.props.record.premiacao) {
        data.premiacao = this.props.record.premiacao;
      }

      const premios = this.props.record.premiacao && this.props.record.premiacao.fidelidade && this.props.record.premiacao.fidelidade.premios ? this.props.record.premiacao.fidelidade.premios : [];

      this.setState({
        data,
        showAtivo: 'block',
        permissoes,
        premios,
      });
    } else {
      this.setState({ permissoes });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  editPontos(record) {
    this.premioEditElement.current.premioEdit(record)
  }

  updatePremiacao(premio, updateMode) {

    if (!updateMode) {

      // Prêmio com a mesma pontuação
      let jaExiste = this.state.premios.filter((item) => {
        return item.pontos === premio.pontos
      });

      if (jaExiste.length > 0) {
        message.error({ content: `Já existe uma premiação com a pontuação ${premio.pontos}.`, key });
        return;
      }
    }

    // Prêmio com o mesmo código de produto
    if (premio.codProduto !== '') {

      let jaExiste = this.state.premios.filter((item) => {
        return item.codProduto === premio.codProduto
      });

      if (jaExiste.length > 0 && premio.codProduto && jaExiste[0].pontos !== premio.pontos) {
        debugger;
        message.error({ content: `Já existe uma premiação com o código de produto ${premio.codProduto}.`, key });
        return;
      }
    }


    let premios = this.state.premios;

    if (updateMode) {
      premios = this.state.premios.filter((item) => {
        return item.pontos !== premio.pontos
      });
    }

    premios.push(premio);
    premios.sort((a, b) => (a.pontos > b.pontos) ? 1 : -1);
    this.setState({ premios });
  }

  async deletePontos(record) {

    const msg = `Deseja excluir a faixa de ${record.pontos} pontos?`;
    let isOk = await mensagem.confirmar(msg);
    if (!isOk) {
      return;
    }

    const premios = this.state.premios.filter((item) => {
      return item.pontos !== record.pontos
    });
    this.setState({ premios });
  }

  limitValueCashbackChange(value) {
    const data = this.state.data;
    if (!data.premiacao) {
      data.premiacao = {
        cashback: {
          valor: 0,
          limite: 0,
        }
      }
    }

    if (!data.premiacao.cashback) {
      data.premiacao.cashback = {
        valor: 0,
        limite: 0,
      }
    }

    data.premiacao.cashback.limite = value;
    this.setState({ data });
  }

  ValorParaPontosChange(value) {
    const data = this.state.data;
    if (!data.premiacao) {
      data.premiacao = {
        fidelidade: {
          valor: 0,
          pontos: 0,
        }
      }
    }

    if (!data.premiacao.fidelidade) {
      data.premiacao.fidelidade = {
        valor: 0,
        pontos: 0,
      }
    }

    data.premiacao.fidelidade.valor = value;
    this.setState({ data });
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Tabs defaultActiveKey="geral">
          <TabPane tab="Geral" key="geral">
            <Form.Item
              style={{ display: this.state.showAtivo }}
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title={`Esse ${this.state.nomeCadastro} está ativo?`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: `Informe se o ${this.state.nomeCadastro} está ativo` }],
              })(

                <Select style={{ width: 120 }}>
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>

              )}
            </Form.Item>

            <Form.Item
              ref='txtRazaoSocial'
              label={
                <span>
                  Razão Social&nbsp;
                  <Tooltip title="Qual é a Razão Social?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('razaoSocial', {
                initialValue: this.state.data.razaoSocial,
                rules: [{ required: true, message: 'Informe a Razão Social', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome Fantasia&nbsp;
                  <Tooltip title="Qual é o Nome Fantasia?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('nomeFantasia', {
                initialValue: this.state.data.nomeFantasia,
                rules: [{ required: true, message: 'Informe o Nome Fantasia', whitespace: true }],
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CNPJ&nbsp;
                  <Tooltip title="Qual é o CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cnpj', {
                initialValue: this.state.data.cnpj,
                rules: [{ required: true, message: 'Informe o CNPJ', whitespace: false }],
              })(<Input />)}
            </Form.Item>


            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator('email', {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: 'email',
                    message: 'Email não é válido',
                  },
                  {
                    required: true,
                    message: 'Informe o email',
                  },
                ],
              })(<Input />)}
            </Form.Item>
          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Telefone">
              {getFieldDecorator('telefone', {
                initialValue: this.state.data.endereco.telefone,
                rules: [{ required: true, message: 'Informe o telefone' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>


            <Form.Item label="Endereço">
              {getFieldDecorator('endereco', {
                initialValue: this.state.data.endereco.logradouro,
                rules: [{ required: false, message: 'Informe o endereço' }],
              })(<Input style={{ width: '100%' }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator('bairro', {
                initialValue: this.state.data.endereco.bairro,
                rules: [{ required: false, message: 'Informe o bairro' }],
              })(<Input style={{ width: '50%' }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator('cidade', {
                initialValue: this.state.data.endereco.cidade,
                rules: [{ required: false, message: 'Informe a cidade' }],
              })(<Input style={{ width: '70%' }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator('uf', {
                initialValue: this.state.data.endereco.uf,
                rules: [{ required: false, message: 'Informe a UF' }],
              })(<Input style={{ width: '20%' }} />)}
            </Form.Item>
          </TabPane>


          {(this.state.permissoes.cashback) ?
            <TabPane tab="Cashback" key="cashback">

              <Form.Item label="Percentual de cashback">
                {getFieldDecorator('cashback', {
                  initialValue: this.state.data.premiacao && this.state.data.premiacao.cashback ? this.state.data.premiacao.cashback.valor : undefined,
                  rules: [{ required: this.state.permissoes.cashback, message: 'Informe o percentual de cashback' }],
                })(
                  <InputNumber style={{ width: '30%' }} />
                )}
              </Form.Item>

              <Form.Item label="Até o limite de:">
                {getFieldDecorator('cashbackLimit', {
                  initialValue: this.state.data.premiacao && this.state.data.premiacao.cashback ? this.state.data.premiacao.cashback.limite : undefined,
                  rules: [{ required: this.state.permissoes.cashback, message: 'Informe o limite (R$) de cashback' }],
                })(
                  <CurrencyInput
                    className="premiacaoValor"
                    prefix="R$ "
                    decimalSeparator=","
                    thousandSeparator="."
                    value={this.state.premiacao && this.state.premiacao.cashback ? this.state.premiacao.cashback.limite : 0}
                    onChange={this.limitValueCashbackChange} />
                )}
              </Form.Item>
            </TabPane>
            : ''
          }

          {(this.state.permissoes.fidelidade) ?
            <TabPane tab="Fidelidade" key="fidelidade">

              <Form.Item label="Ganhe 1 ponto a cada:">
                {getFieldDecorator('fidelidadeValor', {
                  initialValue: this.state.data.premiacao && this.state.data.premiacao.fidelidade ? this.state.data.premiacao.fidelidade.valor : undefined,
                  rules: [{ required: this.state.permissoes.fidelidade, message: 'Informe quanto o cliente precisa gastar para ganhar cada ponto' }],
                })(
                  <CurrencyInput
                    className="premiacaoValor"
                    prefix="R$ "
                    decimalSeparator=","
                    thousandSeparator="."
                    value={this.state.premiacao && this.state.premiacao.fidelidade ? this.state.premiacao.fidelidade.valor : 0}
                    onChange={this.ValorParaPontosChange} />
                )}
              </Form.Item>


              <Table pagination={{ pageSize: 10, position: 'top' }} title={() =>
                <HeaderPremio
                  updatePremiacao={this.updatePremiacao}
                  ref={this.premioEditElement}
                />}
                columns={this.state.columns}
                dataSource={this.state.premios}
                bordered
                loading={this.state.loading}
              />
            </TabPane>
            : ''
          }

        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>
            Cancelar
          </Button>

        </Form.Item>
      </Form>
    );
  }
}

class HeaderPremio extends React.Component {
  constructor(props) {
    super(props);
    this.novoPremioElement = React.createRef();
    this.state = {
      visible: false,
    };
    this.novoPremio = this.novoPremio.bind(this);
    this.premioEdit = this.premioEdit.bind(this);
  }

  novoPremio() {
    this.novoPremioElement.current.show(false);
  }

  premioEdit(record) {
    this.novoPremioElement.current.show(true, record);
  }

  render() {
    const novoUsuarioText = 'Novo Prêmio';
    return (
      <div>
        <NovoPremio
          ref={this.novoPremioElement}
          updatePremiacao={this.props.updatePremiacao}
        >
        </NovoPremio>

        <Button disabled={this.props.naoCadastrar} className="btnSuccess" onClick={this.novoPremio}><Icon className="icon" type="plus" /> {novoUsuarioText}</Button>

      </div>
    );
  }
}

class NovoPremio extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    premiacao: {
      pontos: 0,
      premio: '',
      codProduto: '',
    }
  }

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.valorParaPontosChange = this.valorParaPontosChange.bind(this);
    this.pontosOnChange = this.pontosOnChange.bind(this);
    this.premioOnChange = this.premioOnChange.bind(this);
    this.codProdutoOnChange = this.codProdutoOnChange.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record,
      premiacao: {
        pontos: !editMode ? 0 : record.pontos,
        premio: !editMode ? '' : record.premio,
        codProduto: !editMode ? '' : record.codProduto,
      }
    });
  }

  handleOk() {
    if (this.state.premiacao.pontos <= 0) {
      message.warn({ content: `Informe a quantidade de pontos`, key });
      return;
    }

    if (this.state.premiacao.premio === '') {
      message.warn({ content: `Informe o prêmio`, key });
      return;
    }

    this.props.updatePremiacao(this.state.premiacao, this.state.editMode);
    this.setState({ visible: false });
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  valorParaPontosChange(value) {
    const premiacao = this.state.premiacao;
    premiacao.valor = value;
    this.setState({ premiacao });
  }

  pontosOnChange(value) {
    const premiacao = this.state.premiacao;
    premiacao.pontos = value;
    this.setState({ premiacao });
  }

  premioOnChange(obj) {
    const premiacao = this.state.premiacao;
    premiacao.premio = obj.target.value;
    this.setState({ premiacao });
  }

  codProdutoOnChange(obj) {
    const premiacao = this.state.premiacao;
    premiacao.codProduto = obj.target.value;
    this.setState({ premiacao });
  }

  render() {
    return (
      <Modal
        title="Faixa de Prêmio"
        visible={this.state.visible}
        onOk={this.handleOk}
        onCancel={this.handleCancel}
      >
        <div className="divPremiacaoValor">
          {/* <p>Ganhe um ponto a cada</p>
          <CurrencyInput
            className="premiacaoValor"
            prefix="R$ "
            decimalSeparator=","
            thousandSeparator="."
            value={this.state.premiacao.valor}
            onChange={this.valorParaPontosChange} /> */}

          <p>Pontos para troca</p>
          <InputNumber
            disabled={this.state.editMode}
            style={{ width: '30%' }}
            value={this.state.premiacao.pontos}
            onChange={this.pontosOnChange}
          />

        </div>

        <div className="divPontosParaTroca">
          <p>Prêmio</p>
          <Input
            value={this.state.premiacao.premio}
            onChange={this.premioOnChange}>
          </Input>
        </div>


        <div className="divPontosParaTroca">
          <p>
            Código do Produto (Integração)
            <Tooltip title="O código de produto deve ser o que vincula o produto no Moub com seu sistema de PDV. Pode ser um código EAN ou um código único do produto.">
              <Icon className="iconCodigoProdutoHelp" type="question-circle" />
            </Tooltip>
          </p>
          <Input
            value={this.state.premiacao.codProduto}
            onChange={this.codProdutoOnChange}>
          </Input>
        </div>


      </Modal>
    );
  }
}

export default Form.create()(AdministradorCRUD);