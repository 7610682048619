import React from 'react';
import './editableTable.css';
import { Table, Input, Popconfirm, Form, Button, Icon, message } from 'antd';
import Highlighter from 'react-highlight-words';


const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

const EditableFormRow = Form.create()(EditableRow);

class EditableCell extends React.Component {
    state = {
        editing: false,
    };

    toggleEdit = () => {
        const editing = !this.state.editing;
        this.setState({ editing }, () => {
            if (editing) {
                this.input.focus();
            }
        });
    };

    save = e => {
        const { record, handleSave } = this.props;
        this.form.validateFields((error, values) => {
            if (error && error[e.currentTarget.id]) {
                return;
            }
            this.toggleEdit();
            handleSave({ ...record, ...values });
        });
    };

    renderCell = form => {
        this.form = form;
        const { children, dataIndex, record, title } = this.props;
        const { editing } = this.state;
        return editing ? (
            <Form.Item style={{ margin: 0 }}>
                {form.getFieldDecorator(dataIndex, {
                    rules: [
                        {
                            required: true,
                            message: `${title} is required.`,
                        },
                    ],
                    initialValue: record[dataIndex],
                })(<Input ref={node => (this.input = node)} onPressEnter={this.save} onBlur={this.save} />)}
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{ paddingRight: 24 }}
                onClick={this.toggleEdit}
            >
                {children}
            </div>
        );
    };

    render() {
        const {
            editable,
            dataIndex,
            title,
            record,
            index,
            handleSave,
            children,
            ...restProps
        } = this.props;
        return (
            <td {...restProps}>
                {editable ? (
                    <EditableContext.Consumer>{this.renderCell}</EditableContext.Consumer>
                ) : (
                    children
                )}
            </td>
        );
    }
}

class EditableTable extends React.Component {
    state = {
        dataSource: [],
        filteredInfo: null,
        sortedInfo: null,
    };

    constructor(props) {
        super(props);
        this.tabbleHandleChange = this.tabbleHandleChange.bind(this);
        this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleReset = this.handleReset.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ dataSource: nextProps.data.dataSource });
    }

    UNSAFE_componentWillMount() {
        this.columns = [
            {
                title: 'Nome',
                dataIndex: 'nome',
                sorter: (a, b) => a.nome.localeCompare(b.nome),
                ...this.getColumnSearchProps('nome', 'Nome'),
            },
            {
                title: 'Sobrenome',
                dataIndex: 'sobrenome',
                sorter: (a, b) => a.sobrenome.localeCompare(b.sobrenome),
                ...this.getColumnSearchProps('sobrenome', 'Sobrenome'),
            },
            {
                title: 'Valor',
                dataIndex: 'valor',
                width: '30%',
                editable: !this.props.disableGrid && !this.props.disabledEditValue,
                render: (text, record) => {
                    if (typeof text === 'string') {
                        // Limpa a formatação
                        text = text.replace('R$ ', '').split('.').join('').split(',').join('.');
                    }

                    return (
                        <div style={{ width: '12rem' }}>
                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(text)}
                        </div>
                    )
                },
            },
            {
                title: 'Ação',
                dataIndex: 'operation',
                render: (text, record) =>
                    this.state.dataSource.length >= 1 ? (
                        <Popconfirm
                            title="Excluir?"
                            disabled={this.props.disableGrid}
                            onConfirm={() => this.handleDelete(record.key)}
                            okText="Sim"
                            cancelText="Não"
                        ><a href={() => false}>Excluir</a>
                        </Popconfirm>
                    ) : null,
            },
        ];
    }

    getColumnSearchProps = (dataIndex, dataName) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Procurar ${dataName}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{ width: 90, marginRight: 8 }}
                >
                    Procurar
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                    Limpar
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    tabbleHandleChange = (pagination, filters, sorter) => {
        this.setState({
            filteredInfo: filters,
            sortedInfo: sorter,
        });
    };

    componentDidMount() {
        this.setState({ dataSource: this.props.data.dataSource });
    }

    handleDelete = key => {
        const dataSource = [...this.state.dataSource];
        const newData = dataSource.filter(item => item.key !== key);
        this.setState({ dataSource: newData });

        if (this.props.callback) {
            this.props.callback(newData);
        }

        if (this.props.deleteCallBack) {
            const deletedData = dataSource.filter(item => item.key === key)[0];
            this.props.deleteCallBack(deletedData.regKey);
        }
    };

    handleSave = row => {

        if (row.valor === "0") {
            message.warning("Valor zerado não é permitido");
            return;
        }
        const newData = [...this.state.dataSource];
        const index = newData.findIndex(item => row.key === item.key);
        const item = newData[index];

        if (!row.added) {
            row.modified = true;
        }

        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        this.setState({ dataSource: newData });
        if (this.props.callback) {
            this.props.callback(newData);
        }
    };

    render() {
        const { dataSource } = this.state;
        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };
        const columns = this.columns.map(col => {
            if (!col.editable) {
                return col;
            }
            return {
                ...col,
                onCell: record => ({
                    record,
                    editable: col.editable,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    handleSave: this.handleSave,
                }),
            };
        });
        return (
            <div>
                <Table
                    className="tableBeneficiariosPedido"
                    components={components}
                    rowClassName={() => 'editable-row'}
                    bordered
                    dataSource={dataSource.sort((a, b) => a.nome.localeCompare(b.nome))}
                    columns={columns}
                    onChange={this.tabbleHandleChange}
                    pagination={{
                        defaultPageSize: 100,
                        position: 'both',
                        pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                        showSizeChanger: true,
                        locale: { items_per_page: "" }
                    }}
                />
            </div>
        );
    }
}

const EditableFormTable = Form.create()(EditableTable);

export default EditableFormTable;