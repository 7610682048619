import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import { Select, DatePicker, Button, Table, Radio } from 'antd';
//import { getConvenios } from './VencimentoCartaoF';
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import './VencimentoCartao.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import history from '../../../history';
import funcs from '../../../components/funcs/funcs';


const { Option } = Select

class VencimentoCartao extends React.Component {

    state = {
        loading: true,
        columns: [],
        tableData: [],
        vencimento: '3meses',
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onFiltroChange = this.onFiltroChange.bind(this);
    }

    onFiltroChange(e) {
        this.setState({ vencimento: e.target.value });
    }


    async componentDidMount() {
        // this.setState({ loading: false });

        // const userCol = currentUserCol();
        // const userType = await getUserType();
        // if (userCol || userType !== 'Gestor') {
        //     const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-movimentacao');
        //     if (!isOk) {
        //         this.setState({ loading: false });
        //         await mensagem.avisar('Você não tem permisão para acessar essa página.');
        //         history.push('/dashboard');
        //         history.go();
        //         return;
        //     }
        // }

        // const convenios = await getConvenios();
        // if (!convenios) {
        //     return;
        // }

        // for (const item of convenios) {
        //     this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        // }

        // const storage = JSON.parse(vertteStorage.get('@reports/movimentos'));

        // if (storage) {

        //     const columns = [];
        //     storage.columns.forEach(column => {
        //         if (column.title === 'Débito') {
        //             column.render = (text, record) => (
        //                 <label className="red">{text}</label>
        //             )
        //         }
        //         columns.push(column);
        //     });

        //     this.setState({
        //         convenioId: storage.convenioId,
        //         beneficiario: storage.beneficiario,
        //         periodo: {
        //             inicial: moment(storage.periodo.inicial),
        //             final: moment(storage.periodo.final),
        //         },
        //         tableData: storage.tableData,
        //         cardCredito: storage.cardCredito,
        //         cardCreditoPre: storage.cardCreditoPre,
        //         cardDebito: storage.cardDebito,
        //         cardDebitoPre: storage.cardDebitoPre,
        //         cardSaldo: storage.cardSaldo,
        //         beneficiarioSaldos: storage.beneficiarioSaldos,
        //         columns: columns,
        //     })


        //     for (let b of storage.beneficiariosOptions) {
        //         this.beneficiariosOptions.push(<Option key={b.key} value={b.key}>{b.props.children}</Option>)
        //     }

        //     this.mountChart(storage.chartData)
        // }

    }

    async onFilterClick() {
        alert(this.state.vencimento);
    }

    render() {
        return (
            <div>
                <PageLayout selectItem={"relatorios/movimentacao"}>
                    <div className="containerMovimentacao">
                        <label>A vencer em até:</label>
                        <Radio.Group onChange={this.onFiltroChange} defaultValue={this.state.vencimento}>
                            <Radio.Button value="3meses">3 Meses</Radio.Button>
                            <Radio.Button value="6meses">6 Meses</Radio.Button>
                        </Radio.Group>
                        &nbsp;
                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Filtrar</Button>
                    </div>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(VencimentoCartao);

