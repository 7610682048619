import DataManager from './dtm';
import { fbDatabase, fieldPath } from '../firebase/firebase';


async function queryBy10(ownerId, ids, dataIni, dataFim, fromCache) {
    const query = fbDatabase
        .collection('movimentos')
        .where('ownerId', '==', ownerId)
        .where('data', '>=', dataIni)
        .where('data', '<=', dataFim)
        .where('convenioId', 'in', ids);

    const snapshot = await new DataManager().getQueryData(query, fromCache);

    if (snapshot.empty) {
        return [];
    }

    const data = snapshot.docs.map((item) => ({
        key: item.id,
        ...item.data(),
    }));

    return data;
}

class MovimentoDB extends DataManager {

    state = {
        collection: 'movimentos'
    }


    async getByBeneficiarioAndPeriodo(beneficiarioId, dataIni, dataFinal, fromCache) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .where('data', '>=', dataIni)
            .where('data', '<=', dataFinal);

        const snapshot = await this.getQueryData(query, fromCache);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        return data;
    }

    async getByEstabelecimentoAndPeriodo(estabelecimentoId, dataIni, dataFinal) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('autorizacao.estabelecimento.key', '==', estabelecimentoId)
            .where('data', '>=', dataIni)
            .where('data', '<=', dataFinal);

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        return data;
    }

    async getByPeriodo(dataIni, dataFinal, ownerId, conveniosIds, fromCache) {
        if (conveniosIds.constructor !== Array) {
            const conv = []
            conv.push({ key: conveniosIds });
            conveniosIds = conv;
        }

        let movimentos = [];
        if (!conveniosIds || conveniosIds.length === 0) {
            const query = fbDatabase
                .collection(this.state.collection)
                .where('ownerId', '==', ownerId)
                .where('data', '>=', dataIni)
                .where('data', '<=', dataFinal);

            const snapshot = await this.getQueryData(query, fromCache);

            const data = snapshot.docs.map((item) => ({
                key: item.id,
                ...item.data(),
            }));

            movimentos.push.apply(movimentos, data);
            return movimentos;
        }

        // Quando houver convenios ids informados na lista

        let ids = [];
        if (!conveniosIds) {
            conveniosIds = []
        }

        for (let index = 0; index < conveniosIds.length; index++) {
            ids.push(conveniosIds[index].key);
            if (ids.length >= 10) {
                const data = await queryBy10(ownerId, ids, dataIni, dataFinal, false);
                movimentos.push.apply(movimentos, data);
                ids = [];
            }
        }

        if (ids.length > 0) {
            const data = await queryBy10(ownerId, ids, dataIni, dataFinal, false);
            movimentos.push.apply(movimentos, data);
        }

        return movimentos;
    }

    async getUltimoCredito(ownerId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where(fieldPath.documentId(), '>=', 'ped')
            .where(fieldPath.documentId(), '<=', 'pee');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }))

        const reduced = data.reduce((result, item) => {
            const id = item.beneficiarioId;

            result[id] = result[id] || [];

            result[id] = result[id].data ?
                item.data.seconds > result[id].data.seconds ? item : result[id]
                :
                item

            return result;
        }, {});

        return reduced;
    }

    async getLastPurchase(beneficiarioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .orderBy('data', 'desc')
            .limit(1);

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }));

        let lastPurchase = undefined;
        for (let index = 0; index < data.length; index++) {
            const movimento = data[index];
            if (movimento.debito > 0 && !lastPurchase) {
                lastPurchase = movimento;
            }
        }

        if (lastPurchase) {
            lastPurchase.saldo = data[0].saldo;
        }

        return lastPurchase;
    }

    async getSaldo(beneficiarioId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('beneficiarioId', '==', beneficiarioId)
            .orderBy('data', 'desc')
            .limit(1);

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((doc) => ({
            key: doc.id,
            ...doc.data()
        }));

        return (data.length > 0) ? data[0].saldo : 0;
    }
}

const movimentoDB = new MovimentoDB();
export default movimentoDB;