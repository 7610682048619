import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  message,
  TreeSelect,
  Tabs,
} from "antd";
import { fireBase } from "../../../firebase/firebase";
import { isCPF, formatCPF, clearCpfFormat } from '../../funcs/utils';
import { currentUserCol, getOwnerId, getUserType, isAdministrador, isCashbackPresent, isDebitPresent, isLoyaltyPresent } from '../../auth/auth-provider';
import mensagem from '../../messages/message';
import administradorUsuarioDB from '../../../dataManager/dtmAdministradorUsuario';
import usuarioDB from '../../../dataManager/dtmUsuario';
import userAdmin from '../../userAdmin/userAdmin';
import convenioDB from '../../../dataManager/dtmConvenio';


const { Option } = Select;
const { SHOW_PARENT } = TreeSelect;
const { TabPane } = Tabs;


async function createEmail(email, password) {
  try {
    const obj = await fireBase.auth().createUserWithEmailAndPassword(email, password);
    if (!obj) {
      message.error(`Erro ao criar o usuário com o email ${email}`);
      this.setState({ loadingButton: false });
      return undefined;
    }
    return obj;
  } catch (error) {
    debugger;
    console.log(error.code);
    return undefined;
  }
}

class AdministradorUsuario extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    administradores: [],
    selecteds: [],
    convenios: [],
    convenio: [],
    loading: false,
    loadingButton: false,
    edited: false,
    fileList: [],
    data: {
      key: this.props.record.key,
      ativo: 'Sim',
      apelido: "",
      nome: "",
      cpf: "",
      email: "",
      endereco: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        telefone: ""
      },
      operador: 'nao'
    },
    permissoes: [],
    treeData: [
      {
        title: 'Administradores',
        value: 'administradores',
        key: 'administradores',
      },
      {
        title: 'Usuários',
        value: 'usuarios',
        key: 'usuarios',
        children: [
          {
            title: 'Cadastrar',
            value: 'usuarios-cadastrar',
            key: 'usuarios-cadastrar',
          },
          {
            title: 'Editar',
            value: 'usuarios-editar',
            key: 'usuarios-editar',
          },
        ],
      },
      {
        title: 'Convênios',
        value: 'convenios',
        key: 'convenios',
      },
      {
        title: 'Beneficiários',
        value: 'beneficiarios',
        key: 'beneficiarios',
        children: [
          {
            title: 'Cadastrar',
            value: 'beneficiarios-cadastrar',
            key: 'beneficiarios-cadastrar',
          },
          {
            title: 'Editar',
            value: 'beneficiarios-editar',
            key: 'beneficiarios-editar',
          },
          {
            title: 'Ver/Editar Senha Cartão',
            value: 'beneficiarios-senha',
            key: 'beneficiarios-senha',
          },
        ],
      },
      {
        title: 'Pedidos',
        value: 'pedidos',
        key: 'pedidos',
        children: [
          {
            title: 'Cadastrar',
            value: 'pedidos-cadastrar',
            key: 'pedidos-cadastrar',
          },
          {
            title: 'Editar/Cancelar',
            value: 'pedidos-cancelar',
            key: 'pedidos-cancelar',
          },
        ],
      },
    ],
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.convenioChange = this.convenioChange.bind(this);
    this.onPermissoesChange = this.onPermissoesChange.bind(this);
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }

      if (this.state.selecteds.length === 0) {
        mensagem.openNotificationWithIcon(
          "error",
          "Cadastro incompleto",
          "Selecione a empresa"
        );
        return;
      }

      this.setState({ loadingButton: true });

      const gravarAdministradores = [];

      if (!Array.isArray(this.state.selecteds)) {
        this.state.selecteds = [this.state.selecteds];
      }

      this.state.selecteds.forEach(obj => {
        const administrador = this.state.administradores.filter(item => {
          return item.key === obj;
        })[0];

        gravarAdministradores.push({
          id: administrador.key,
          nome: administrador.props.children
        });
      });

      if (!isCPF(values.cpf)) {
        mensagem.openNotificationWithIcon('error', 'CPF inválido', 'O CPF informado não está correto.');
        this.setState({ loadingButton: false });
        return;
      }
      values.cpf = formatCPF(values.cpf);

      // procuro pelo CPF para checar se está repetido
      let snapshot = await administradorUsuarioDB.getByCPF(values.cpf);

      // Verifico se está havendo uma duplicação de CPF
      if (snapshot !== undefined) {
        if (
          (this.props.editMode &&
            snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O CPF informado já foi cadastrado."
          );
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = values.email ? await administradorUsuarioDB.getByEmail(values.email) : undefined;

      if (snapshot !== undefined) {
        if (
          (this.props.editMode &&
            snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O email informado já foi cadastrado."
          );
          return;
        }
      }

      const ownerId = await getOwnerId();

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        nome: values.nome,
        sobrenome: values.sobrenome,
        cpf: values.cpf,
        administrador: {
          ...gravarAdministradores[0]
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        permissoes: this.state.permissoes,
        ownerId,
        operador: values.operador === 'sim' ? true : false,
        convenioId: values.selectConvenio ?? null
      };

      if (values.email) {
        item['email'] = values.email;
      }

      let novoId = '';
      if (!this.props.editMode) {

        let obj = undefined;

        // Antes de mais nada, TENTO criar um novo login com o email
        // e os seis dígitos do cpf como senha
        if (values.email) {
          const cpf = clearCpfFormat(values.cpf);
          obj = await createEmail(values.email, cpf.substr(0, 6));
          if (!obj) {
            return;
          }
        }
        const rec = await administradorUsuarioDB.add(item);
        novoId = rec.id;

        const usuario = {
          uid: obj.user.uid,
          tipo: 'AdministradorUsuario',
          ownerId,
        }

        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);


      } else {
        if (values.email && !this.state.data.email) {
          const cpf = clearCpfFormat(values.cpf);
          const obj = await createEmail(values.email, cpf.substr(0, 6));
          if (!obj) {
            return;
          }
        }

        const isChanged = this.props.record.email !== values.email;
        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loadingButton: false });
          message.warning('Verifique o email do usuário');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }

        await administradorUsuarioDB.update(this.props.record.key, item);
        if (item.email) {
          const active = values.ativo.toLocaleLowerCase() === 'sim' ? true : false;
          userAdmin.activeOrDeactiveUser(item.email, active);
        }
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O usuário ${values.nome} foi ${tipoAtualizacao} com sucesso`, 3);
      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';
      this.props.handleOk(item);
      this.setState({ loadingButton: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentDidMount() {
    const { Option } = Select;
    const administradores = [];

    this.props.record.administradores.forEach(item => {
      administradores.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    });

    let _convenios;
    const ownerId = await getOwnerId();
    const isAdm = await isAdministrador();

    if (isAdm || await getUserType() === 'AdministradorUsuario') {
      const { key, administrador } = currentUserCol();
      const administradorId = isAdm ? key : administrador.id;

      _convenios = await convenioDB.getByOwnerIdAdminIdAndAtivo(ownerId, administradorId, true);
    } else {
      _convenios = await convenioDB.getByOwnerIdAndAtivo(ownerId, true);
    }

    const convenios = [];
    _convenios.forEach(item => {
      convenios.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    });

    this.setState({ administradores, convenios });

    const selecteds = [];
    if (this.props.editMode && this.props.record) {
      selecteds.push(this.props.record.administrador.id);
      this.setState({
        selecteds,
        convenio: this.props.record.convenioId,
        data: {
          key: this.props.record.key,
          ativo: this.props.record.ativo ? 'sim' : 'nao',
          nome: this.props.record.nome,
          sobrenome: this.props.record.sobrenome,
          cpf: this.props.record.cpf,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          operador: this.props.record.operador ? 'sim' : 'nao'
        },
        permissoes: this.props.record.permissoes,
      });
    }
    else {
      // Só seleciono automaticamente se o length for igual a 1 
      // para evitar erros de cadastramento
      if (this.props.record.administradores.length === 1) {
        selecteds.push(this.props.record.administradores[0].key);
        this.setState({ selecteds });
      }
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  convenioChange(value) {
    this.setState({ convenio: value });
  }

  onPermissoesChange(value) {
    this.setState({ permissoes: value });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const debitPresent = isDebitPresent();
    const cashBackPresent = isCashbackPresent();
    const loyaltyPresent = isLoyaltyPresent();

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Tabs defaultActiveKey="geral">

          <TabPane tab="Geral" key="geral">

            <Form.Item
              label={
                <span>
                  Nome&nbsp;
                  <Tooltip title="Qual é o nome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("nome", {
                initialValue: this.state.data.nome,
                rules: [
                  { required: true, message: "Informe o apelido", whitespace: true }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item
              ref="txtSobrenome"
              label={
                <span>
                  Sobrenome&nbsp;
                  <Tooltip title="Qual é o sobrenome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("sobrenome", {
                initialValue: this.state.data.sobrenome,
                rules: [
                  { required: true, message: "Informe o sobrenome", whitespace: true }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item
              ref="selectAdministrador"
              label={
                <span>
                  Administrador&nbsp;
                  <Tooltip title="Escolha o administrador do usuário">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("selectAdministrador", {
                initialValue: this.state.selecteds,
                rules: [
                  { required: true, message: "selecione um adminstrador" }
                ]
              })(
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Selecione um administrador"
                  onChange={this.selectHandleChange}

                  showSearch

                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }

                >
                  {this.state.administradores}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title="Esse usuário está ativo?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: 'Informe se o usuário está ativo' }],
              })(

                <Select style={{ width: 120 }}>
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>

              )}
            </Form.Item>

            {loyaltyPresent && (
              <div>
                <Form.Item
                  label={
                    <span>
                      Operador&nbsp;
                      <Tooltip title='Esse usuário é um operador do fidelidade?'>
                        <Icon type='question-circle-o' />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('operador', {
                    initialValue: this.state.data.operador,
                    rules: [{ required: true, message: 'Informe se o usuário é um operador' }],
                  })(

                    <Select
                      style={{ width: 120 }}
                      onChange={value => {
                        let data = this.state.data;
                        data.operador = value;
                        this.setState({ data });
                      }}
                    >
                      <Option value='sim'>Sim</Option>
                      <Option value='nao'>Não</Option>
                    </Select>

                  )}
                </Form.Item>

                <Form.Item
                  label={
                    <span>
                      Convênio&nbsp;
                      <Tooltip title='Escolha o convênio do usuário'>
                        <Icon type='question-circle-o' />
                      </Tooltip>
                    </span>
                  }
                >
                  {getFieldDecorator('selectConvenio', {
                    initialValue: this.state.data.operador === 'sim' ? this.state.convenio : null,
                    rules: [{ required: this.state.data.operador === 'sim', message: "selecione um adminstrador" }]
                  })(
                    <Select
                      mode='single'
                      style={{ width: '100%' }}
                      placeholder='Selecione um convênio'
                      onChange={this.convenioChange}
                      showSearch
                      disabled={this.state.data.operador === 'nao'}
                      filterOption={(input, option) =>
                        option.props.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {this.state.convenios}
                    </Select>
                  )}
                </Form.Item>
              </div>
            )}

            {(cashBackPresent || debitPresent) && (
              <Form.Item
                label={
                  <span>
                    Permissões&nbsp;
                    <Tooltip title={`Que recursos esse usuário pode acessar? Não é obrigatório para operadores`}>
                      <Icon type="question-circle-o" />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('permissoes', {
                  initialValue: this.state.permissoes,
                  rules: [{
                    required: this.state.data.operador === 'nao',
                    message: `Informe que recursos o usuário pode acessar`
                  }],
                })(

                  <TreeSelect
                    style={{ width: '100%' }}
                    treeCheckable={true}
                    showCheckedStrategy={SHOW_PARENT}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.state.treeData}
                    placeholder="Selecione uma ou mais permissões"
                    treeDefaultExpandAll
                    onChange={this.onPermissoesChange} />
                )}
              </Form.Item>
            )}

            <Form.Item
              label={
                <span>
                  CPF&nbsp;
                  <Tooltip title="Qual é o CPF?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("cpf", {
                initialValue: this.state.data.cpf,
                rules: [
                  { required: true, message: "Informe o CPF", whitespace: false }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido"
                  },
                  {
                    required: true,
                    message: "Informe o email"
                  }
                ]
              })(<Input />)}
            </Form.Item>
          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Telefone">
              {getFieldDecorator("telefone", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.telefone : '',
                rules: [{ required: false, message: "Informe o telefone" }]
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator("endereco", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.logradouro : '',
                rules: [{ required: false, message: "Informe o endereço" }]
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator("bairro", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.bairro : '',
                rules: [{ required: false, message: "Informe o bairro" }]
              })(<Input style={{ width: "50%" }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator("cidade", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.cidade : '',
                rules: [{ required: false, message: "Informe a cidade" }]
              })(<Input style={{ width: "70%" }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator("uf", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.uf : '',
                rules: [{ required: false, message: "Informe a UF" }]
              })(<Input style={{ width: "20%" }} />)}
            </Form.Item>

          </TabPane>

        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loadingButton}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(AdministradorUsuario);
