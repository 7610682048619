import { getOwnerId } from "../../components/auth/auth-provider";
import recoConvenioDB from "../../dataManager/dtmRecoConv";

const listBackgroundColor = [
	'rgba(255, 99, 132, 0.2)',
	'rgba(255, 159, 64, 0.2)',
	'rgba(255, 205, 86, 0.2)',
	'rgba(75, 192, 192, 0.2)',
	'rgba(54, 162, 235, 0.2)',
	'rgba(153, 102, 255, 0.2)',
	'rgba(201, 203, 207, 0.2)'
];

const listBorderColor = [
	'rgb(255, 99, 132)',
	'rgb(255, 159, 64)',
	'rgb(255, 205, 86)',
	'rgb(75, 192, 192)',
	'rgb(54, 162, 235)',
	'rgb(153, 102, 255)',
	'rgb(201, 203, 207)'
];


export async function buildRecurrency() {
	const ownerId = await getOwnerId();
	const snapShot = await recoConvenioDB.getByOwnerId(ownerId, true, '/recoConvenio/getByOwnerId');

	const labels = [];
	let datasets = [];
	const backgroundColor = [];
	const borderColor = [];

	if (snapShot && snapShot.length > 0) {
		const firstIndex = snapShot.length > 7 ?
			snapShot.length - 7 : 0;

		let indexColor = 0;

		for (let index = firstIndex; index < snapShot.length; index++) {
			const {
				totalVezesPontuou,
				convenio
			} = snapShot[index];

			datasets.push(totalVezesPontuou);
			labels.push(convenio.nome);

			backgroundColor.push(listBackgroundColor[indexColor]);
			borderColor.push(listBorderColor[indexColor]);

			indexColor++;
		}
	}

	let title = (datasets.length === 0) ? 'Ainda não há dados a serem exibidos' : 'Total geral de recorrências em sua loja';

	if (datasets.length > 1) {
		title = `Recorrência geral em suas ${datasets.length} lojas`;
	}

	if (datasets.length > 7) {
		title = 'Maiores 7 lojas com recorrência';
	}

	return {
		title,
		labels,
		datasets,
		backgroundColor,
		borderColor
	};
}