// ========= MOUB 4C ========= 
require('../../components/layout/moub.css');        

export function getLogoClass() {
    return 'logoMoub';
    // return 'logoRegina';
}

export function getColor() {
    return '#5F2A64';
    // return '#BD3029';
}


// ========= REGINA SUPERMERCADOS ========= 

// require('../../components/layout/regina.css');

// export function getLogoClass() {
//     return 'logoRegina';
// }

// export function getColor() {
//     return '#BD3029';
// }
