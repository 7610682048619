import React from 'react';
import { withRouter } from 'react-router';
import { Select, Table, Button, message, Input } from 'antd';
import { getConvenios, getData } from './Funcs-Saldos';
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';

import PageLayout from '../../../components/layout/PageLayout'
import listObj from "../../../components/listSearch/listSearch";
import history from '../../../history';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import vertteStorage from '../../../components/localstorage/localstorage';

import './Saldos.css'

const { Search } = Input;
const { Option } = Select;


class Saldos extends React.Component {

    state = {
        loading: false,
        columns: [],
        tableData: [],
        tableDataOriginal: [],
        convenio: {}
    }

    convenioOptions = [];

    constructor(props) {
        super(props);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.buildColumns = this.buildColumns.bind(this);
        this.onConvenioChange = this.onConvenioChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.updateList = this.updateList.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();

        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-saldos');

            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();

        for (const item of convenios) {
            this.convenioOptions.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const storage = JSON.parse(vertteStorage.get('@reports/saldos'));

        if (storage) {
            this.setState({
                tableData: storage.tableData,
                tableDataOriginal: storage.tableData,
                convenio: storage.convenio,
            });

            this.buildColumns();
        }
    }

    async onFilterClick() {
        if (!this.state.convenio.id) {
            message.warning('Selecione um convênio');
        }

        this.setState({ loading: true });

        this.buildColumns();

        const data = await getData(this.state.convenio.id);

        const storageElement = {
            tableData: data,
            convenio: this.state.convenio
        }

        vertteStorage.set('@reports/saldos', JSON.stringify(storageElement));
        this.setState({ tableData: data, tableDataOriginal: data, loading: false });
    }

    onConvenioChange(value, obj) {
        const convenio = {
            id: value,
            nome: obj.props.children
        }

        this.setState({ convenio })
    }

    buildColumns() {
        const columns = [
            {
                title: 'Beneficiário',
                dataIndex: 'beneficiario.nome',
                key: 'beneficiario.nome',
            },
            {
                title: 'Último Crédito',
                dataIndex: 'beneficiario.ultimoCredito',
                key: 'beneficiario.ultimoCredito',
                render: (value, row) => {
                    let data = '';
                    let valor = '';
                    if (value) {
                        data = value === '--' ? '--' : 'Data: ' + value.data;
                        valor = value === '--' ? '' : 'Valor: ' + value.valor;
                    }

                    return {
                        children: <div>{data} <br /> {valor}</div>,
                    }
                },

            },
            {
                title: 'Saldo Atual',
                dataIndex: 'beneficiario.saldoAtual',
                key: 'beneficiario.saldoAtual',
            },

        ]

        this.setState({ columns })
    }

    async applyFilter(text) {
        if (text === '') {
            this.setState({ tableData: this.state.tableDataOriginal });
            return;
        };

        const keys = [
            'beneficiario.nome',
            'beneficiario.ultimoCredito',
            'beneficiario.saldoAtual',
        ];

        const data = listObj.search(this.state.tableDataOriginal, text, keys);
        this.setState({ tableData: data });
    }

    updateList(record) {
        let data = this.state.tableData;
        let registroEncontrado = false;

        if (data) {
            this.state.tableData.forEach((item, index) => {
                if (item.key === record.key) {
                    data[index] = record;
                    registroEncontrado = true;
                }
            });
        } else {
            data = []
        }

        if (!registroEncontrado) {
            data.push(record);
        }
        this.setState({ dataTable: data, dataTableOriginal: data });
    }


    render() {
        return (
            <PageLayout selectItem="relatorios/saldos">
                <div className="containerSaldos">
                    <label>Convênio:</label>
                    <Select
                        showSearch
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }

                        style={{ width: '500px' }}
                        value={this.state.convenio.id}
                        onChange={this.onConvenioChange}
                    >
                        {this.convenioOptions}
                    </Select>
                    <Button type="primary" loading={this.state.loading} onClick={this.onFilterClick}>Filtrar</Button>
                </div>

                <div className="tableSaldos">

                    <Table
                        title={() => (
                            <Header
                                ref={this.headerElement}
                                empresas={this.state.empresas}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                            />
                        )}
                        dataSource={this.state.tableData}
                        columns={this.state.columns}
                        rowClassName={(record) => record.color ? record.color.replace('#', '') : ''}
                        rowKey={(record) => record.key}
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["25", "50", "100", "200"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                    >

                    </Table>
                </div>

            </PageLayout>
        )
    }

}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
        this.filterTable = this.filterTable.bind(this);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        return (
            <div>
                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ width: 200 }}
                />

            </div>
        );
    }
}

export default withRouter(Saldos);