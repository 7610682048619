import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout'
import { Select, Table, Button, message, Input, DatePicker } from 'antd';
import { getConvenios, getData } from './DescFolhaF';
import vertteStorage from '../../../components/localstorage/localstorage';
import listObj from "../../../components/listSearch/listSearch";
import history from '../../../history';
import './DescFolha.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import jsPDF from "jspdf";
import "jspdf-autotable";


const { Search } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY';
const key = 'updatemessage';


class DescFolha extends React.Component {

    state = {
        loading: false,
        columns: [],
        tableData: [],
        tableDataOriginal: [],
        convenio: {},
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        textFilter: 'Filtrar',
    }

    convenioOptions = [];

    constructor(props) {
        super(props);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.buildColumns = this.buildColumns.bind(this);
        this.onConvenioChange = this.onConvenioChange.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.updateList = this.updateList.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.exportPDF = this.exportPDF.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-descFolha');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        for (const item of convenios) {
            this.convenioOptions.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const storage = JSON.parse(vertteStorage.get('@reports/descontoFolha'));

        if (storage) {
            this.setState({
                tableData: storage.tableData,
                tableDataOriginal: storage.tableData,
                convenio: storage.convenio,
                periodo: {
                    inicial: moment(storage.periodo.inicial),
                    final: moment(storage.periodo.final),
                },
            });

            this.buildColumns();
        }
    }

    exportPDF() {

        if (this.state.tableData.length === 0) {
            message.warn({ content: 'Não há dados para exportar', key });
            return;
        }

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const dataIni = this.state.periodo.inicial._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 00:00:00', '');

        const dataFim = this.state.periodo.final._d.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 23:59:59', '');;

        const title = `Desc. Folha de ${dataIni} até ${dataFim} - Convênio: ${this.state.convenio.nome}`;
        const headers = [['Beneficiário', 'Desconto em Folha']];

        const source = [];

        for (let index = 0; index < this.state.tableData.length; index++) {
            const bnf = this.state.tableData[index];
            const item = {
                nome: bnf.nome,
                descontoFolha: bnf.valorDebitadoSaldoPre,
            }
            source.push(item);
        }

        const data = source.map(elt => [elt.nome, elt.descontoFolha]);

        let content = {
            startY: 50,
            head: headers,
            body: data
        };

        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("Desconto em Folha.pdf")
    }

    onDateChange(value) {

        const periodo = {
            inicial: value[0],
            final: value[1]
        }

        const timeDiff = periodo.final._d.getTime() - periodo.inicial._d.getTime();
        const dayDiff = (timeDiff / (1000 * 3600 * 24)) + 1;
        if (dayDiff > 31) {
            message.error({ content: 'Período não pode ser maior que 30 dias', key });
            periodo.inicial = undefined;
            periodo.final = undefined;
        }

        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    async onFilterClick() {
        if (!this.state.convenio.id) {
            message.warning({ content: 'Selecione um convênio', key });
            return;
        }

        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning({ content: 'Selecione um período', key });
            return;
        }

        this.setState({ loading: true, textFilter: 'Aguarde...', tableData: [] });
        this.buildColumns();

        const data = await getData(this.state.convenio.id, this.state.periodo.inicial, this.state.periodo.final);
        const storageElement = {
            tableData: data,
            convenio: this.state.convenio,
            periodo: this.state.periodo,
        }

        vertteStorage.set('@reports/descontoFolha', JSON.stringify(storageElement));
        this.setState({ tableData: data, tableDataOriginal: data, loading: false, textFilter: 'Filtrar' });
    }

    onConvenioChange(value, obj) {
        const convenio = {
            id: value,
            nome: obj.props.children
        }

        this.setState({ convenio })
    }

    buildColumns() {
        const columns = [
            {
                title: 'Beneficiário',
                dataIndex: 'nome',
                key: 'nome',
                render: (text, record) => (
                    <label className={record.key === 'valorDebitadoSaldoPreTotal' ? 'totalDebitoViaCredito' : ''}>{text}</label>
                ),

            },
            {
                title: 'Desconto Folha',
                dataIndex: 'valorDebitadoSaldoPre',
                key: 'valorDebitadoSaldoPre',
                align: 'right',

                render: (text, record) => (
                    <label className={record.key === 'valorDebitadoSaldoPreTotal' ? 'totalDebitoViaCreditoRed' : 'red'}>{text}</label>
                ),
            },
        ]

        this.setState({ columns })
    }

    async applyFilter(text) {
        if (text === '') {
            this.setState({ tableData: this.state.tableDataOriginal });
            return;
        };

        const keys = [
            'beneficiario.nome',
            'beneficiario.ultimoCredito',
            'beneficiario.saldoAtual',
        ];

        const data = listObj.search(this.state.tableDataOriginal, text, keys);
        this.setState({ tableData: data });
    }

    updateList(record) {
        let data = this.state.tableData;
        let registroEncontrado = false;

        if (data) {
            this.state.tableData.forEach((item, index) => {
                if (item.key === record.key) {
                    data[index] = record;
                    registroEncontrado = true;
                }
            });
        } else {
            data = []
        }

        if (!registroEncontrado) {
            data.push(record);
        }
        this.setState({ dataTable: data, dataTableOriginal: data });
    }


    render() {
        return (
            <PageLayout selectItem="relatorios/desconto-folha">
                <div className="containerSaldos">
                    <label>Convênio:</label>
                    <Select
                        showSearch
                        disabled={this.state.loading}
                        filterOption={(input, option) =>
                            option.props.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                        }

                        style={{ width: '500px' }}
                        value={this.state.convenio.id}
                        onChange={this.onConvenioChange}
                    >
                        {this.convenioOptions}
                    </Select>

                    <label>Período: </label>
                    <RangePicker
                        style={{ width: '25%' }}
                        locale={locale}
                        disabled={this.state.loading}
                        format={dateFormat}
                        placeholder={['Data Inicial', 'Data Final']}
                        onChange={this.onDateChange}
                        value={[this.state.periodo.inicial, this.state.periodo.final]}
                    />
                    <Button type="primary" disabled={this.state.loading} onClick={this.onFilterClick}>{this.state.textFilter}</Button>
                </div>

                <div className="tableSaldos">
                    <Button onClick={() => this.exportPDF()}>Exportar para PDF</Button>
                    <Table
                        title={() => (
                            <Header
                                ref={this.headerElement}
                                empresas={this.state.empresas}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                            />
                        )}
                        rowExpandable={true}
                        expandRowByClick={true}
                        loading={this.state.loading}
                        dataSource={this.state.tableData}
                        columns={this.state.columns}
                        rowKey={(record) => record.key}
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["25", "50", "100", "200"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                    >

                    </Table>
                </div>

            </PageLayout >
        )
    }

}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
        this.filterTable = this.filterTable.bind(this);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        return (
            <div>
                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ width: 200 }}
                />

            </div>
        );
    }
}

export default withRouter(DescFolha);