import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  message,
  Tabs,
} from "antd";
import { isCPF, formatCPF, clearCpfFormat, isCNPJ, formatCNPJ } from '../../funcs/utils';
import { getOwnerId } from '../../auth/auth-provider';
import { salvarAuditoriaAtivo } from './ParticipanteCRUDF';

import mensagem from '../../messages/message';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import usuarioDB from '../../../dataManager/dtmUsuario';
import userAdmin from '../../userAdmin/userAdmin';
import CPFInput from '../../masks/cpf';
import TelefoneInput from '../../masks/telefone';

const { Option } = Select;
const { TabPane } = Tabs;

class ParticipanteCRUD extends React.Component {
  state = {
    rede: [],
    redeSelecionada: '',
    loading: false,
    loadingButton: false,
    edited: false,
    data: {
      key: this.props.record.key,
      ativo: '',
      apelido: '',
      nome: '',
      cpfCnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: ''
      },
    },
  };

  constructor(props) {
    super(props);

    this.cancelClick = this.cancelClick.bind(this);

    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.onChangeSelectAtivo = this.onChangeSelectAtivo.bind(this);
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {


      if (err) {
        return;
      }

      this.setState({ loadingButton: true });
      let snapshot = undefined;


      if (!values.cpfCnpj && !values.telefone) {
        mensagem.openNotificationWithIcon('error', 'CPF/CNPJ ou Telefone', 'É necessário informar um CPF/CNPJ ou um telefone.');
        this.setState({ loadingButton: false });
        return;
      }

      if (values.cpfCnpj) {
        if (!isCPF(values.cpfCnpj) && !isCNPJ(values.cpfCnpj)) {
          mensagem.openNotificationWithIcon('error', 'CPF/CNPJ inválido', 'O CPF/CNPJ informado não está correto.');
          this.setState({ loadingButton: false });
          return;
        }
        values.cpfCnpj = isCPF(values.cpfCnpj) ? formatCPF(values.cpfCnpj) : formatCNPJ(values.cpfCnpj);

        // procuro pelo CPF para checar se está repetido
        snapshot = await beneficiarioDB.getByCPF(values.cpfCnpj);

        // Verifico se está havendo uma duplicação de CPF
        if (snapshot !== undefined) {
          if (
            (this.props.editMode &&
              snapshot[0].key !== this.props.record.key) ||
            (this.props.editMode && snapshot.length > 1) ||
            !this.props.editMode
          ) {
            this.setState({ loadingButton: false });
            mensagem.openNotificationWithIcon(
              "error",
              "Duplicação",
              "O CPF/CNPJ informado já foi cadastrado."
            );
            return;
          }
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = values.email ? await beneficiarioDB.getByEmail(values.email) : undefined;

      if (snapshot !== undefined) {
        if (
          (this.props.editMode &&
            snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O email informado já foi cadastrado."
          );
          return;
        }
      }

      const ownerId = await getOwnerId();
      const administrador = this.state.rede.filter(key => key.key === this.state.redeSelecionada)[0];
      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        nome: values.nome,
        sobrenome: values.sobrenome,
        cpf: values.cpfCnpj,
        administrador: {
          id: administrador.key,
          nome: administrador.props.children,
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        ownerId,
      };

      if (values.email) {
        item['email'] = values.email;
      }

      let novoId = '';
      if (!this.props.editMode) {
        const password = clearCpfFormat(values.cpfCnpj);

        // Antes de mais nada, crio um novo login com o email
        // e os seis dígitos do cpf ou cnpj como senha
        if (values.email) {
          const obj = await userAdmin.createEmail(values.email, password.substr(0, 6));
          if (!obj) {
            return;
          }
        }

        const rec = await beneficiarioDB.add(item);
        novoId = rec.id;

        const usuario = {
          uid: '',
          key: novoId,
          tipo: 'Beneficiario',
          ownerId,
        }
        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);

      } else {
        if (values.email && !this.state.data.email) {
          const password = clearCpfFormat(values.cpfCnpj);
          const obj = await userAdmin.createEmail(values.email, password.substr(0, 6));
          if (!obj) {
            return;
          }
        }

        let isChanged = this.state.data.email !== null && this.state.data.email !== undefined && this.state.data.email !== '';
        if (isChanged) {
          isChanged = (this.props.record.email !== values.email);
        }

        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loading: false });
          message.warning('Verifique o email do beneficiário');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }

        if (this.props.record.ativo !== item.ativo) salvarAuditoriaAtivo(item, this.props.record, ownerId);

        await beneficiarioDB.update(this.props.record.key, item);

        if (item.email) {
          const active = !item.ativo;
          userAdmin.activeOrDeactiveUser(item.email, active);
        }
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O beneficiário ${values.nome} foi ${tipoAtualizacao} com sucesso`, 3);

      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';

      this.props.handleOk(item);
      this.setState({ loadingButton: false });
    });
  };

  async componentDidMount() {
    const { Option } = Select;

    const rede = [];
    const administradoresAdicionados = new Set();
    this.props.record.convenios.forEach(item => {
      const administrador = item.administrador;
      if (!administradoresAdicionados.has(administrador.id)) {
        rede.push(<Option key={administrador.id}>{administrador.nome}</Option>);
        administradoresAdicionados.add(administrador.id);
      }
    });

    this.setState({ rede });

    if (this.props.editMode && this.props.record) {
      const redeSelecionada = this.props.record.administrador.id;
      this.setState({
        redeSelecionada,
        data: {
          key: this.props.record.key,
          ativo: this.props.record.ativo ? 'sim' : 'nao',
          nome: this.props.record.nome,
          sobrenome: this.props.record.sobrenome,
          cpfCnpj: this.props.record.cpf,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
        },
      });
    } else {
      const redeSelecionada = rede[0].key;
      this.setState({ redeSelecionada });
    }
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    const redeSelecionada = value;
    this.setState({ redeSelecionada });
  }

  onChangeSelectAtivo(value) {
    let { data } = this.state;
    data.ativo = value;
    this.setState({ data });
  }


  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <Tabs defaultActiveKey="geral">
          <TabPane tab="Geral" key="geral">
            <Form.Item
              ref="selectAdministrador"
              label={
                <span>
                  Rede&nbsp;
                  <Tooltip title="Escolha a rede do participante">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("selectConvenio", {
                initialValue: this.state.redeSelecionada,
                rules: [
                  { required: true, message: "Selecione uma rede" }
                ]
              })(
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Selecione uma rede"
                  onChange={this.selectHandleChange}

                  showSearch

                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.rede}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Ativo?&nbsp;
                  <Tooltip title="Esse participante está ativo para pontuar?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: 'Informe se o usuário está ativo para pontuar' }],
              })(

                <div>
                  <Select
                    style={{ width: 120 }}
                    value={this.state.data.ativo}
                    onChange={(value) => this.onChangeSelectAtivo(value)}
                  >
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                </div>
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome&nbsp;
                  <Tooltip title="Qual é o nome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("nome", {
                initialValue: this.state.data.nome,
                rules: [
                  { required: true, message: "Informe o apelido", whitespace: true }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item
              ref="txtSobrenome"
              label={
                <span>
                  Sobrenome&nbsp;
                  <Tooltip title="Qual é o sobrenome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("sobrenome", {
                initialValue: this.state.data.sobrenome,
                rules: [
                  { required: true, message: "Informe o sobrenome", whitespace: true }
                ]
              })(<Input />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CPF/CNPJ&nbsp;
                  <Tooltip title="Qual é o CPF/CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("cpfCnpj", {
                initialValue: this.state.data.cpfCnpj,
                rules: [
                  { required: false, message: "Informe o CPF/CNPJ", whitespace: false }
                ]
              })(<CPFInput />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Telefone&nbsp;
                  <Tooltip title="Qual é o Telefone?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("telefone", {
                initialValue: this.state.data.endereco.telefone,
                rules: [
                  { required: false, message: "Informe o Telefone", whitespace: false }
                ]
              })(<TelefoneInput />)}
            </Form.Item>


            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido"
                  },
                  {
                    required: false,
                    message: "Informe o email"
                  }
                ]
              })(<Input />)}
            </Form.Item>

          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Endereço">
              {getFieldDecorator("endereco", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.logradouro : '',
                rules: [{ required: false, message: "Informe o endereço" }]
              })(<Input style={{ width: "100%" }} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator("bairro", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.bairro : '',
                rules: [{ required: false, message: "Informe o bairro" }]
              })(<Input style={{ width: "50%" }} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator("cidade", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.cidade : '',
                rules: [{ required: false, message: "Informe a cidade" }]
              })(<Input style={{ width: "70%" }} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator("uf", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.uf : '',
                rules: [{ required: false, message: "Informe a UF" }]
              })(<Input style={{ width: "20%" }} />)}
            </Form.Item>
          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loadingButton}>
            Salvar
          </Button>

          <Divider type="vertical" />

          <Button onClick={this.cancelClick}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(ParticipanteCRUD);
