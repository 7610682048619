import React from 'react';
import './Configuracao.css';
import PageLayout from '../../components/layout/PageLayout';
import {
    Form,
    Input,
    Tooltip,
    Button,
    Divider,
    Select,
    Tabs,
    Icon,
    Checkbox,
    InputNumber,
    Spin,
    message,
} from 'antd';
import history from '../../history';
import { getOwnerId, isDebitPresent, isLoyaltyPresent } from '../../components/auth/auth-provider';
import mensagem from '../../components/messages/message';
import configuracaoDB from '../../dataManager/dtmConfiguracao';


const key = 'configuracaokey';
const { Option } = Select;
const { TabPane } = Tabs;
const DEFAULT_DESCRIPTION = 'Informe as chaves da Gerencianet para gerar os boletos de pedido';
class Configuracao extends React.Component {

    state = {
        loading: true,
        loadingButton: false,
        clientId: '',
        clientSecret: '',
        sandbox: 'nao',
        exigirSenha: 'sim',
        configKey: undefined,
        description: DEFAULT_DESCRIPTION,
        fidelidade: {
            fracionado: 'sim',
            _4horas: 'nao',
            expirar: false,
            diasExpirar: 0,
        },
        moub: {
            debitPresent: false,
            loyaltyPresent: false,
        }
    }

    constructor(props) {
        super(props);
        this.tabChange = this.tabChange.bind(this);
        this.expirarChange = this.expirarChange.bind(this);
        this.expirarNumberChange = this.expirarNumberChange.bind(this);
    }

    async componentDidMount() {
        const ownerId = await getOwnerId();

        const moub = {
            debitPresent: await isDebitPresent(),
            loyaltyPresent: await isLoyaltyPresent(),
        }

        const fidelidade = this.state.fidelidade;
        const configuracao = await configuracaoDB.getByOwnerId(ownerId);

        if (configuracao) {
            const {
                boleto,
                fidelidade,
                key
            } = configuracao[0];

            const sandbox = boleto.sandbox ? 'sim' : 'nao';

            let exigirSenha = 'sim'
            if (boleto.exigirSenha !== undefined) {
                exigirSenha = boleto.exigirSenha ? 'sim' : 'nao';
            }

            this.setState({
                configKey: key,
                clientId: boleto.clientId,
                clientSecret: boleto.clientSecret,
                moub,
                fidelidade: {
                    fracionado: fidelidade && fidelidade.fracionado ? 'sim' : 'nao',
                    _4horas: fidelidade && fidelidade._4horas ? 'sim' : 'nao',
                    expirar: fidelidade && fidelidade.expirar !== undefined ? fidelidade.expirar : false,
                    diasExpirar: fidelidade && fidelidade.diasExpirar !== undefined ? fidelidade.diasExpirar : 0,
                },
                sandbox,
                exigirSenha,
                loading: false
            });
        } else {
            this.setState({ moub, fidelidade, loading: false });
        }

        if (!moub.debitPresent) {
            this.tabChange('fidelidade');
        }
    }

    tabChange(item) {
        let description = DEFAULT_DESCRIPTION;
        if (item === 'fidelidade') {
            description = 'Informe as configurações para pontuação e resgate';
        }
        this.setState({ description })
    }

    expirarChange(obj) {
        const fidelidade = this.state.fidelidade;
        fidelidade.expirar = obj.target.checked;
        if (!fidelidade.expirar) {
            fidelidade.diasExpirar = 0;
        }
        this.setState({ fidelidade });
    }

    expirarNumberChange(value) {
        const fidelidade = this.state.fidelidade;

        if (typeof value !== "number") {
            value = 0;
        }

        fidelidade.diasExpirar = value;
        this.setState({ fidelidade });
    }

    cancelClick() {
        history.push('/dashboard');
        history.go();
    }

    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll(async (err, values) => {
            if (err) {
                return;
            }

            if (this.state.moub.debitPresent) {
                if (!values.clientId) {
                    message.warning({ content: 'Informe o Client ID', key });
                    return;
                }

                if (!values.clientSecret) {
                    message.warning({ content: 'Informe o Client Secret', key });
                    return;
                }
            }

            this.setState({ loadingButton: true });

            let sandbox = true;
            if (values.sandbox !== undefined) {
                sandbox = values.sandbox.toLocaleLowerCase() === 'sim' ? true : false;
            }

            let exigirSenha = true;
            if (values.exigirSenha !== undefined) {
                exigirSenha = values.exigirSenha.toLocaleLowerCase() === 'sim' ? true : false;
            }

            const ownerId = await getOwnerId();

            const item = {
                ownerId,
                boleto:
                {
                    tipo: 'Gerencianet',
                    clientId: values.clientId ? values.clientId : '',
                    clientSecret: values.clientSecret ? values.clientSecret : '',
                    sandbox,
                    exigirSenha
                },
            }

            if (this.state.moub.loyaltyPresent) {
                if (this.state.fidelidade.expirar === true && this.state.fidelidade.diasExpirar <= 0) {
                    message.warning({ content: 'Informe o número de dias exatos para expiração', key });
                    this.setState({ loadingButton: false });
                    return;
                }

                item.fidelidade = {
                    fracionado: values.fracionado.toLocaleLowerCase() === 'sim' ? true : false,
                    _4horas: values._4horas.toLocaleLowerCase() === 'sim' ? true : false,
                    expirar: this.state.fidelidade.expirar,
                    diasExpirar: this.state.fidelidade.diasExpirar,
                };
            }

            if (!this.state.configKey) {
                configuracaoDB.add(item);
            }
            else {
                configuracaoDB.update(this.state.configKey, item);
            }

            mensagem.openNotificationWithIcon('success', 'Configurações foram salvas com sucesso');

            setTimeout(() => {
                history.push('/dashboard');
                history.go();
            }, 1500);

        });
    }


    render() {
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };

        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        const { getFieldDecorator } = this.props.form;

        return (
            <div>
                <PageLayout selectItem={'configuracoes'}>

                    {this.state.loading ?
                        <div className="divConfigSpin">
                            <Spin size="large" />
                        </div>
                        : <div>

                            <label className="lblHeaderConfig">{this.state.description}</label>
                            <div className="div-form-profile">
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>

                                    <Tabs defaultActiveKey="debito" onChange={this.tabChange}>

                                        {this.state.moub.debitPresent ?
                                            <TabPane tab="Pagamentos" key="pagamentos">
                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Client ID&nbsp;
                                                            <Tooltip title="Informe o Client ID que se encontra na aba produção na api da Gerencia Net">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('clientId', {
                                                        initialValue: this.state.clientId,
                                                        rules: [{ required: false, message: 'Informe o Client ID que se encontra na aba produção na api da Gerencia Net', whitespace: true }],
                                                    })(<Input />)}
                                                </Form.Item>

                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Client Secret&nbsp;
                                                            <Tooltip title="Informe o Client Secret que se encontra na aba produção na api da Gerencia Net">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('clientSecret', {
                                                        initialValue: this.state.clientSecret,
                                                        rules: [{ required: false, message: 'Informe o Client Secret que se encontra na aba produção na api da Gerencia Net', whitespace: true }],
                                                    })(<Input.Password style={{ width: '95%' }} />)}
                                                </Form.Item>

                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Teste?
                                                            <Tooltip title="Caso esteja usando as chaves de informação, marque SIM">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('sandbox', {
                                                        initialValue: this.state.sandbox,
                                                        rules: [{ required: false, message: 'Caso esteja usando as chaves de informação, marque SIM' }],
                                                    })(

                                                        <Select style={{ width: 120, left: "-39px" }}>
                                                            <Option value="sim">Sim</Option>
                                                            <Option value="nao">Não</Option>
                                                        </Select>

                                                    )}
                                                </Form.Item>
                                            </TabPane>
                                            : ''}

                                        {this.state.moub.debitPresent ?
                                            <TabPane tab="Cartão" key="cartao">
                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Exigir Senha do Cartão
                                                            <Tooltip title='A senha do cartão deve ser exigida em uma compra nos seus convênios?'>
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('exigirSenha', {
                                                        initialValue: this.state.exigirSenha,
                                                        rules: [{ required: false, message: 'A senha do cartão deve ser exigida em uma compra nos seus convênios?' }],
                                                    })(
                                                        <Select style={{ width: 120 }}>
                                                            <Option value='sim'>Sim</Option>
                                                            <Option value='nao'>Não</Option>
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            </TabPane>
                                            : ''}

                                        {this.state.moub.loyaltyPresent ?

                                            <TabPane tab="Fidelidade" key="fidelidade">

                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Pontuar fracionado?&nbsp;
                                                            <Tooltip title="Caso SIM, mesmo que o cliente não atinja o valor mínimo para ganhar um ponto, ele pontua de forma fracionada. Exemplo, você configurou para o cliente pontuar a cada 1 Real. Se houver um gasto de 99 Centavos, ele ganhará 99% de 1 ponto.">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('fracionado', {
                                                        initialValue: this.state.fidelidade.fracionado,
                                                        rules: [{ required: false, message: 'Cliente pontua fracionado?' }],
                                                    })(

                                                        <Select style={{ width: 120 }}>
                                                            <Option value="sim">Sim</Option>
                                                            <Option value="nao">Não</Option>
                                                        </Select>

                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Pontuar em menos de 4h?
                                                            <Tooltip title="Caso SIM, compras feitas em prazo inferior a 4 horas serão aceitos e o cliente irá fidelizar. Essa configuração é útil, por exemplo, em restaurantes.">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('_4horas', {
                                                        initialValue: this.state.fidelidade._4horas,
                                                        rules: [{ required: false, message: 'Cliente pontua fracionado?' }],
                                                    })(

                                                        <Select style={{ width: 120 }}>
                                                            <Option value="sim">Sim</Option>
                                                            <Option value="nao">Não</Option>
                                                        </Select>

                                                    )}
                                                </Form.Item>

                                                <Form.Item
                                                    label={
                                                        <span>
                                                            Expirar pontos?
                                                            <Tooltip title="Caso Habilitado, os pontos dos clientes serão expiráveis.">
                                                                <Icon type="question-circle-o" />
                                                            </Tooltip>
                                                        </span>
                                                    }
                                                >
                                                    {getFieldDecorator('expirar', {
                                                        initialValue: this.state.fidelidade._4horas,
                                                        rules: [{ required: false, message: 'Cliente pontua fracionado?' }],
                                                    })(

                                                        <div className="inputExpirar">
                                                            <Checkbox
                                                                className="checkExpirar"
                                                                onChange={this.expirarChange}
                                                                checked={this.state.fidelidade.expirar}
                                                            ></Checkbox>
                                                            <InputNumber
                                                                style={{ width: '30%' }}
                                                                disabled={this.state.fidelidade.expirar === false}
                                                                onChange={this.expirarNumberChange}
                                                                value={this.state.fidelidade.diasExpirar}
                                                            />
                                                            <label className="labelExpirar">dias</label>
                                                        </div>


                                                    )}
                                                </Form.Item>

                                            </TabPane>
                                            : ''}
                                    </Tabs>

                                    <Divider></Divider>

                                    <Form.Item {...tailFormItemLayout}>
                                        <Button type="primary" htmlType="submit" loading={this.state.loadingButton}>
                                            Salvar
                                        </Button>
                                        <Divider type="vertical" />
                                        <Button onClick={this.cancelClick}>
                                            Cancelar
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    }
                </PageLayout>
            </div>
        );
    }
}

export default Form.create()(Configuracao);
