import React from 'react';
import { withRouter } from 'react-router'
import { Table, Button, Icon, Modal, Input, Tooltip } from 'antd';
import { getOwnerId, getUserType, currentUserCol } from '../../components/auth/auth-provider';

import GestorCRUD from '../../components/cruds/gestores/GestorCRUD';
import PageLayout from '../../components/layout/PageLayout';
import history from '../../history';
import mensagem from '../../components/messages/message';
import gestorDB from '../../dataManager/dtmGestor';
import listObj from '../../components/listSearch/listSearch';
import vertteStorage from '../../components/localstorage/localstorage';

import './Gestor.css';


const { Search } = Input;


class Gestor extends React.Component {
    state = {
        gestores: [],
        gestoresOriginal: [],
        isSearching: false,
        loading: true,
        userType: 'Gerente',
        convenioUnico: undefined,
        menu: '',
        permissoes: {
            naoCadastrar: false,
            naoEditar: true
        },
        css: {
            actions: 'actionsMoub',
        }
    }

    constructor(props) {
        super(props);

        this.headerUsuarioElement = React.createRef();

        this.getGestores = this.getGestores.bind(this);
        this.updateList = this.updateList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }

    UNSAFE_componentWillMount() {
        const convenio = this.props.location.state ? JSON.parse(this.props.location.state) : undefined;
        const menu = convenio ? 'convenios' : 'gestores';
        this.setState({ menu });
    }

    async componentDidMount() {
        this.getGestores();
    }

    async getGestores() {
        const ownerId = await getOwnerId();
        const userType = await getUserType();
        const userCol = currentUserCol();

        let naoCadastrar = false;
        let naoEditar = false;

        if (userCol || userType !== 'Gestor') {
            const isOk = userType === 'Gestor' && userCol.permissoes.some(function (v) { return v.indexOf("gestores") >= 0 });

            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }

            naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("gestores-cadastrar") >= 0 });
            if (naoCadastrar) {
                naoCadastrar = userCol.permissoes.indexOf('gestores') === -1;
            }
            naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("gestores-editar") >= 0 });
            if (naoEditar) {
                naoEditar = userCol.permissoes.indexOf('gestores') === -1;
            }
        }

        const gestores = await gestorDB.getByOwnerIdCustom(ownerId, true, true);

        this.setState({
            userType,
            gestores,
            gestoresOriginal: gestores,
            permissoes: { naoCadastrar, naoEditar },
            loading: false
        });
    }

    async applyFilter(text) {
        const { gestoresOriginal } = this.state;

        if (text === '') {
            this.setState({
                gestores: gestoresOriginal,
                isSearching: false
            });

            return;
        };

        const keys = [
            'nome',
            'sobrenome',
            'cpf',
            'email',
            'endereco.telefone',
            'ativoStr'
        ];

        const gestores = listObj.search(this.state.gestoresOriginal, text, keys);

        this.setState({
            gestores,
            isSearching: true
        });
    }

    updateList(record) {
        if (!record) {
            return;
        }

        record['ativo'] = record['ativo'] ? 'Sim' : 'Não';

        let gestores = this.state.gestores;
        let registroEncontrado = false;

        this.state.gestores.forEach((item, index) => {
            if (item.key === record.key) {
                gestores[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            gestores.push(record);
        }

        if (this.state.isSearching) {
            let gestoresOriginal = this.state.gestoresOriginal;
            registroEncontrado = false;

            this.state.gestoresOriginal.forEach((item, index) => {
                if (item.key === record.key) {
                    gestoresOriginal[index] = record;
                    registroEncontrado = true;
                }
            });

            if (!registroEncontrado) {
                gestoresOriginal.push(record);
            }

            this.setState({ gestores, gestoresOriginal });
        } else {
            this.setState({ gestores, gestoresOriginal: gestores });
        }

        vertteStorage.clear('@firebase-cache/update/gestorDB/getByOwnerIdCustom');
    }


    handleCancel = () => {
        this.setState({ visible: false });
    };

    editarUsuarioElement(record) {
        if (this.state.permissoes.naoEditar) return;
        record['convenios'] = [];
        this.headerUsuarioElement.current.editarUsuario(record);
    }

    render() {
        const columns = [
            {
                title: 'Nome',
                dataIndex: 'nome',
                key: 'nome',
                editable: true,
            },
            {
                title: 'Sobrenome',
                dataIndex: 'sobrenome',
                key: 'sobrenome',
            },
            {
                title: 'CPF',
                dataIndex: 'cpf',
                key: 'cpf',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Telefone',
                dataIndex: 'endereco.telefone',
                key: 'telefone',
            },
            {
                title: "Ativo?",
                dataIndex: "ativoStr",
                key: "ativoStr"
            },
        ];

        if (!this.state.permissoes.naoEditar) {

            columns.push(
                {
                    title: '',
                    key: 'action',
                    render: (text, record) => (
                        <span>
                            <Tooltip placement="topLeft" title="Editar Cadastro">
                                <Icon
                                    type="edit"
                                    onClick={() => this.editarUsuarioElement(record)}
                                />
                            </Tooltip>
                        </span>
                    ),
                },
            )
        }

        return (
            <div className="divTable">
                <PageLayout selectItem={this.state.menu}>
                    <Table
                        pagination={{ pageSize: 10, position: 'top' }}
                        title={() =>
                            <HeaderUsuario
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                ref={this.headerUsuarioElement}
                                userType={this.state.userType}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                                convenioUnico={this.state.convenioUnico}
                            />}
                        columns={columns}
                        dataSource={this.state.gestores}
                        loading={this.state.loading}
                        bordered
                    />
                </PageLayout>
            </div>
        );
    }
}

class HeaderUsuario extends React.Component {

    state = {
        visible: false,
    };

    constructor(props) {
        super(props);
        this.novoUsuarioElement = React.createRef();
        this.novoUsuario = this.novoUsuario.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    novoUsuario() {
        this.novoUsuarioElement.current.show(false);
    }

    editarUsuario(record) {
        this.novoUsuarioElement.current.show(true, record);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        const novoUsuarioText = this.props.convenioUnico ? 'Novo Usuário do Convênio' : 'Novo Gestor';
        return (
            <div>
                <NovoUsuario
                    ref={this.novoUsuarioElement}
                    updateList={this.props.updateList}
                    convenioUnico={this.props.convenioUnico}
                ></NovoUsuario>
                <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novoUsuario}><Icon className="icon" type="plus" /> {novoUsuarioText}</Button>

                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ marginLeft: "10px", width: 200 }}
                />

            </div>
        );
    }
}

class NovoUsuario extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        record: [],
        convenios: [],
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    show(editMode, record) {
        if (this.props.convenioUnico) {
            this.setState({ convenioUnico: this.props.convenioUnico });
        }

        if (!record) {
            record = [];
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record
        });
    }

    handleOk(record) {
        this.setState({ visible: false });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    render() {
        const titulo = window.location.href.includes('convenio-usuarios') ? 'Usuário' : 'Gestor';
        return (
            <Modal
                title={titulo}
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={true}
                onCancel={() => { this.setState({ visible: false }); }}
            >
                <GestorCRUD
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    editMode={this.state.editMode}
                    record={this.state.record}
                    convenios={this.state.convenios}
                    convenioUnico={this.state.convenioUnico}
                ></GestorCRUD>

            </Modal>
        );
    }
}

export default withRouter(Gestor);