import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import { Select, Button, Table, Checkbox, Icon, Tooltip, message } from 'antd';
import { getConvenios, getCards, getAVencer, getBeneficiarios } from './NovoCartaoF';
import './NovoCartao.css'
import { currentUserCol, getOwnerId, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import history from '../../../history';
import funcs from '../../../components/funcs/funcs';
import { genValidAt, getNewOne, unformatNumber } from '../../../components/cartao/card-provider';
import cartaoDB from '../../../dataManager/dtmCartao';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';

const { Option } = Select
const key = 'keymessagecard';


class NewCard extends React.Component {

    state = {
        loading: true,
        vencer: false,
        ativo: false,
        convenioId: [],
        beneficiario: {},
        columns: [
            {
                title: 'Número',
                dataIndex: 'numero',
                key: 'numero',
            },
            {
                title: 'Beneficiário',
                dataIndex: 'nome',
                key: 'nome',
            },
            {
                title: 'Validade',
                dataIndex: 'validade',
                key: 'validade',
            },
            {
                title: 'Convênio',
                dataIndex: 'convenio',
                key: 'convenio',
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Novo Cartão">
                            <Icon
                                type="credit-card"
                                onClick={() => this.newCard(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            },
        ],
        tableData: [],
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.vencerChange = this.vencerChange.bind(this);
        this.ativoChange = this.ativoChange.bind(this);
        this.newCard = this.newCard.bind(this);
        this.onSelectBeneficiarioChange = this.onSelectBeneficiarioChange.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirPagina(userCol, 'cards-to-print', 'tools');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        this.setState({ loading: false });
    }

    vencerChange(obj) {
        this.setState({ vencer: obj.target.checked });
    }

    ativoChange(obj) {
        this.setState({ ativo: obj.target.checked });
    }

    async newCard(record) {

        if (record.gerouNovo) {
            mensagem.avisar(`Cartão já foi gerado para ${record.nome}`);
            return;
        }

        const isOk = await mensagem.confirmar(`Deseja realmente gerar um novo cartão para ${record.nome}?`);
        if (!isOk) return;

        this.setState({ loading: true });
        // Timeout apenas para que o usuário veja o loading aparecer e ter impressão de
        // que houve algum processamento
        setTimeout(async () => {
            const array = this.state.tableData;
            for (let index = 0; index < array.length; index++) {
                const item = array[index];

                const ownerId = await getOwnerId();

                if (item.numero === record.numero) {
                    const cartao = getNewOne(true);
                    const validAt = genValidAt();
                    const card = {
                        beneficiarioId: record.beneficiarioId,
                        criadoEm: new Date(),
                        numero: unformatNumber(cartao.numero),
                        dv: cartao.dv,
                        password: cartao.password,
                        convenio: {
                            id: item.convenioId,
                            nome: item.convenio,
                        },
                        impressao: {
                            impresso: false,
                            ignorado: false,
                        },
                        status: 'Aguardando ativação',
                        validAt,
                        ownerId,
                    }
                    await cartaoDB.update(record.key, { status: 'Cancelado' });
                    await cartaoDB.add(card);
                    await beneficiarioDB.update(record.beneficiarioId, {
                        cartao: {
                            numero: unformatNumber(cartao.numero),
                            dv: cartao.dv,
                        }
                    });

                    item.numero = cartao.numero;
                    item.gerouNovo = true;
                }
            }
            this.setState({ tableData: array, loading: false });
            mensagem.openNotificationWithIcon('success', 'Perfeito!', `Novo cartão gerado para ${record.nome}`, 3);
        }, 1000);
    }

    async onSelectChange(convenioId) {

        this.beneficiariosOptions = [];

        const b = await getBeneficiarios(convenioId);
        let saldos = {};

        this.beneficiariosOptions.push(<Option key='todos' value='todos'>Todos</Option>)
        for (const item of b) {
            saldos[item.key] = item.saldo ? (item.saldo / 100) : item.operacao ? (item.operacao.saldo / 100) : 0;
            this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
        }

        this.setState({ convenioId, beneficiario: {} })
    }

    onSelectBeneficiarioChange(value, object) {
        const beneficiario = {
            id: value,
            nome: object.props.children
        }
        this.setState({ beneficiario });
    }


    async onFilterClick() {
        if (!this.state.vencer && this.state.convenioId === '') {
            message.warning({ content: 'Selecione um convênio', key });
            return;
        }

        if (!this.state.vencer && this.state.beneficiario.id === undefined) {
            message.warning({ content: 'Selecione um beneficiário', key });
            return;
        }

        this.setState({ loading: true });
        const tableData = this.state.vencer ? await getAVencer() : await getCards(this.state.convenioId, this.state.beneficiario.id, this.state.ativo);
        this.setState({ loading: false, tableData });
    }

    render() {
        return (
            <div>
                <PageLayout selectItem={"tools/cards/new"}>
                    <div className="containerCardsToPrint">

                        <Checkbox
                            className="checkVencer"
                            onChange={this.vencerChange}
                            checked={this.state.vencer}
                        >Cartões a vencer</Checkbox>

                        <Checkbox
                            className="checkVencer"
                            onChange={this.ativoChange}
                            checked={this.state.ativo}
                            style={{ marginRight: '1rem' }}
                        >Cartões não ativos</Checkbox>




                        <label className="labelConvenios">Convênio: </label>
                        <Select
                            mode="single"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.loading || this.state.vencer}
                            optionFilterProp="children"
                            style={{ width: '350px' }}
                            placeholder='Selecione um convênio'
                            onChange={this.onSelectChange}
                            value={this.state.convenioId}
                        >{this.options}</Select>

                        <label style={{ marginLeft: 10, marginRight: 10 }}>Beneficiário: </label>

                        <Select
                            ref={(el) => this.beneficiarioRef = el}
                            disabled={this.state.loading || this.state.vencer}
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '20%' }}
                            placeholder='Selecione um beneficiário'
                            onChange={this.onSelectBeneficiarioChange}
                            value={this.state.beneficiario.id}
                        >
                            {this.beneficiariosOptions}
                        </Select>


                        <Button
                            className="btnFiltrar"
                            disabled={this.state.loading}
                            onClick={this.onFilterClick}
                            type="primary"
                        >Filtrar</Button>

                    </div>

                    <label className='cardsToPrint'>{`Cartões para Impressão: ${this.state.tableData.length}`}</label>
                    <div className="tableCards">
                        <Table
                            pagination={{
                                defaultPageSize: 100,
                                position: 'both',
                                pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                                showSizeChanger: true,
                                locale: { items_per_page: "" }
                            }}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.loading}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(NewCard);

