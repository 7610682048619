import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class ConvenioDB extends DataManager {

    state = {
        collection: 'convenios',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByOwnerIdCustom(ownerId, fromCache, update, ativoStatus) {
        let query = fbDatabase
            .collection(this.state.collection);

            if (ativoStatus !== undefined) {
                query = query.where('ativo', '==', ativoStatus)
            }

            query = query.where('ownerId', '==', ownerId)
            .orderBy('nomeFantasia')
            .orderBy('razaoSocial');

        const updateEndpoint = update ? '/convenioDB/getByOwnerIdCustom' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            prazoStr: item.data().gerarCobranca ? item.data().prazo : '--',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAndAtivo(ownerId, ativo) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('ativo', '==', ativo)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAndAdminId(ownerId, administradorId, fromCache, update) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const updateEndpoint = update ? '/convenioDB/getByOwnerIdAndAdminId' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAdminIdAndAtivo(ownerId, administradorId, ativo) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('administrador.id', '==', administradorId)
            .where('ativo', '==', ativo)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAndCreditoTrue(gestorId) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .where('credito.ativo', '==', true)
            .orderBy('nomeFantasia')
            .orderBy('razaoSocial');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            prazoStr: item.data().gerarCobranca ? item.data().prazo : '--',
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            gerarCobrancaStr: item.data().gerarCobranca ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const convenioDB = new ConvenioDB();
export default convenioDB;