
import { getOwnerId } from "../../../components/auth/auth-provider";
import beneficiarioDB from "../../../dataManager/dtmBeneficiario";
import convenioDB from "../../../dataManager/dtmConvenio";
import pedidoDB from '../../../dataManager/dtmPedido';

export function getNextColor(color) {
  if (color === "#fff") {
    color = "#eee";
  } else {
    color = "#fff";
  }
  return color;
}

export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios;
}

export async function getData(filtros) {
  const ownerId = await getOwnerId();

  const status = (filtros.ativos && filtros.inativos) ? undefined : (filtros.ativos ? true : false);

  const data = await convenioDB.getByOwnerIdCustom(ownerId, true, true, status);

  let convenios = [];

  let truee = true;

  for (let index = 0; index < data.length; index++) {
    const convenio = data[index];

    let pagos = 0;
    let aguardando = 0;
    let cancelados = 0;
    let atrasados = 0;
    let pedidos = [];
    let totalBeneficiarios = 0;
    let dtVenctoMaisAntigoAberto = undefined;
    let dtVenctoMaisAntigoAtraso = undefined;
    let valorTotalEmAberto = 0;
    let valorTotalEmAtraso = 0;

    if (filtros.totalColaboradores) {
      totalBeneficiarios = await beneficiarioDB.getByConvenioIdAndAtivo(convenio.key, true);
    }


    if (possuiFiltrosEspeciais(filtros)) {

      pedidos = await pedidoDB.retornarPedidosPorConvenioId(convenio.key, filtros);

      pedidos.forEach(element => {


        if (truee) {
          debugger;
        }

        if (element.boleto) {
          if (filtros.pagos && element.boleto.status === 'paid') {
            pagos += 1;
          }
          if (filtros.pagamentosEmAberto && element.boleto.status === 'waiting') {
            aguardando += 1;
            if (!dtVenctoMaisAntigoAberto) {
              dtVenctoMaisAntigoAberto = converterDataPadraoBrasileiro(element.boleto.expire_at);              
            }
            valorTotalEmAberto += element.boleto.total;
          }
          if (filtros.cancelados && element.boleto.status === 'canceled') {
            cancelados += 1;
          }
          if (filtros.pagamentosEmAtraso && element.boleto.status === 'unpaid') {
            atrasados += 1;
            if (!dtVenctoMaisAntigoAtraso) {              
              dtVenctoMaisAntigoAtraso = converterDataPadraoBrasileiro(element.boleto.expire_at);              
            }
            debugger;
            valorTotalEmAtraso += element.boleto.total;
          }
        }
      });
    }

    if (possuiFiltrosEspeciais(filtros) && pedidos.length == 0) {
      continue;
    }

    if (truee) {
      debugger;
    }
    let conv = {
      razaoSocial: convenio.razaoSocial,
      nomeFantasia: convenio.nomeFantasia,
      ativoStr: convenio.ativoStr,
      totalPedidos: pedidos.length,
      totalPagos: pagos,
      totalAguardando: aguardando,
      dtVenctoMaisAntigoAberto,
      valorTotalEmAberto: pedidoDB.formatarMoeda(valorTotalEmAberto / 100),

      dtVenctoMaisAntigoAtraso,
      valorTotalEmAtraso: pedidoDB.formatarMoeda(valorTotalEmAtraso / 100),


      totalCancelados: cancelados,
      totalAtrasados: atrasados,
      totalColaboradores: totalBeneficiarios.length,
      ultimoPedido: pedidos && pedidos.length > 0 && pedidos[0].dataInicial ? new Date(pedidos[0].dataInicial).toLocaleDateString() : '--'
    };

    let add = true;
    if (filtros.valorGastoMensal) {
      const dataRef = retornarData(filtros.periodoMensal);
      add = pedidos && pedidos.length > 0 && pedidos[0].dataInicial ? new Date(pedidos[0].dataInicial) < dataRef : true;
    }

    if (add) {
      convenios.push(conv);
    }
  }

  convenios.sort((a, b) => (a.razaoSocial > b.razaoSocial ? 1 : -1));
  return convenios;
}

function possuiFiltrosEspeciais(filtros) {
  return filtros.totalPedidos || filtros.pagos || filtros.pagamentosEmAberto ||
  filtros.pagamentosEmAtraso || filtros.cancelados || filtros.valorGastoMensal ||
  filtros.ultimaCompra;
}

function converterDataPadraoBrasileiro(dataStr) {
  const data = dataStr.split('-');
  return data[2] + '/' + data[1] + '/' + data[0]
};

function retornarData(periodo) {
  let dias = 0;
  if (periodo === '3meses') {
    dias = 90;
  } else if (periodo === '6meses') {
    dias = 180;
  } else if (periodo === '9meses') {
    dias = 270;
  } else {
    dias = 365;
  }

  let d = new Date();
  d.setDate(d.getDate() - dias);
  return d;
}
