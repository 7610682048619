import React, { Component } from 'react';
import { Menu } from 'antd';
import { getUserType, isCashbackPresent } from '../auth/auth-provider';

const { SubMenu } = Menu;


class MenuCashBack extends Component {

    state = {
        userType: 'Gestor',
        cashBackPresent: undefined,
        permissoes: {
            gestores: true,

            // meus clientes: 
            administradores: true,
            usuarios: true,
            beneficiarios: true,
            pedidos: true,
            estornoDebito: true,
        }
    }

    async componentDidMount() {
        const userType = await getUserType();
        let { permissoes } = this.state;

        if (userType === 'Administrador') {
            permissoes = {
                gestores: false,
                administradores: true,
                usuarios: true,
                convenios: true,
                beneficiarios: true,
                pedidos: true,
                estabelecimentos: true,
                configuracoes: true,
                estornoDebito: true,
            };
        }

        const cashBackPresent = await isCashbackPresent();

        this.setState({
            userType,
            permissoes,
            cashBackPresent
        })
    }


    constructor(props) {
        super(props);
    }


    render() {
        const dashMenuIsPresent = document.getElementById('dashboardMenu');
        const minhaRedeMenu = document.getElementById('minhaRedeMenu');

        return (
            <div>
                {this.state.cashBackPresent ?
                    <Menu
                        defaultSelectedKeys={[this.props.selectItem]}
                        defaultOpenKeys={[this.props.openMenu]}
                        onClick={this.props.onMenuClick}
                        theme="dark"
                        mode="horizontal"
                        style={{
                            fontWeight: 600,
                            fontSize: '1rem',
                            backgroundColor: 'transparent',
                            lineHeight: '6.25rem'
                        }}>

                        {!dashMenuIsPresent ?
                            <Menu.Item key="dashboard" id="dashboardMenuCashBack">
                                <span>Dashboard</span>
                            </Menu.Item>
                            : ''
                        }

                        {!minhaRedeMenu ?
                            <SubMenu
                                id="minhaRedeMenuCashback"
                                key="meusClientes"
                                title={
                                    <span>
                                        <span>Minha Rede</span>
                                    </span>
                                }
                            >
                                <Menu.Item className="antSubMenu" key="administradores">
                                    <span>Administradores</span>
                                </Menu.Item>

                                <Menu.Item className="antSubMenu" key="convenios">
                                    <span>Convênios</span>
                                </Menu.Item>
                            </SubMenu>
                            : ''
                        }

                    </Menu>
                    : ''
                }
            </div>
        );
    }
}

export default MenuCashBack;
