import crypto from 'crypto';
import { CRIPT_ALGORITMO, CRIPT_IV_LENGTH, CRIPT_SEGREDO } from './constants';


export function isCPF(cpf) {
    cpf = cpf.replace(/[^\d]+/g, '');
    if (cpf === '') return false;
    // Elimina CPFs invalidos conhecidos	
    if (cpf.length !== 11 ||
        cpf === "00000000000" ||
        cpf === "11111111111" ||
        cpf === "22222222222" ||
        cpf === "33333333333" ||
        cpf === "44444444444" ||
        cpf === "55555555555" ||
        cpf === "66666666666" ||
        cpf === "77777777777" ||
        cpf === "88888888888" ||
        cpf === "99999999999")
        return false;
    // Valida 1o digito	
    let add = 0;
    for (let i = 0; i < 9; i++)
        add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(9)))
        return false;
    // Valida 2o digito	
    add = 0;
    for (let i = 0; i < 10; i++)
        add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev === 10 || rev === 11)
        rev = 0;
    if (rev !== parseInt(cpf.charAt(10)))
        return false;
    return true;
}

export function isCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '')

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14)
        return false

    // Elimina inválidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj))
        return false

    // Cáculo de validação
    let t = cnpj.length - 2,
        d = cnpj.substring(t),
        d1 = parseInt(d.charAt(0)),
        d2 = parseInt(d.charAt(1)),
        calc = x => {
            let n = cnpj.substring(0, x),
                y = x - 7,
                s = 0,
                r = 0

            for (let i = x; i >= 1; i--) {
                s += n.charAt(x - i) * y--;
                if (y < 2)
                    y = 9
            }

            r = 11 - s % 11
            return r > 9 ? 0 : r
        }

    return calc(t) === d1 && calc(t + 1) === d2
}

export function clearCpfFormat(cpf) {
    return cpf.replace(/[^\d]/g, "");
}

export function formatCPF(cpf) {
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function formatCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    // eslint-disable-next-line no-useless-escape
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
}

export function formatDateBrazilian(date) {
    if (typeof date === 'string') {
        date = new Date(date + ' 00:00:00')
    }
    const month = date.getMonth() + 1;
    return `${('0' + date.getDate()).slice(-2)}-${('0' + month).slice(-2)}-${date.getFullYear()}`;
}

export function formatDateAmerican(date) {
    if (typeof date === 'string') {
        date = new Date(date + ' 00:00:00')
    }
    const month = date.getMonth() + 1;
    return `${date.getFullYear()}-${('0' + month).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
}

export function dateDiffInDays(date1, date2) {
    if (!date1 || !date2) {
        return 0;
    }

    let MS_PER_SEC = 1000,
        SEC_PER_HR = 60 * 60,
        HR_PER_DAY = 24,
        MS_PER_DAY = MS_PER_SEC * SEC_PER_HR * HR_PER_DAY;

    return Math.round((date2.getTime() - date1.getTime()) / MS_PER_DAY);
}

export function encrypt(string) {
    debugger;
    if (!string) {
        return undefined;
    }
    const iv = crypto.randomBytes(CRIPT_IV_LENGTH);

    const cipher = crypto.createCipheriv(
        CRIPT_ALGORITMO,
        Buffer.from(CRIPT_SEGREDO, 'hex'),
        iv
    );

    let encrypted = cipher.update(string);
    encrypted = Buffer.concat([encrypted, cipher.final()]);

    return iv.toString('hex') + ':' + encrypted.toString('hex');
}

export function decrypt(string) {
    const texto = string.split(':');
    const iv = Buffer.from(texto.shift(), 'hex');
    const textoEncriptado = Buffer.from(texto.join(':'), 'hex');

    const decipher = crypto.createDecipheriv(
        CRIPT_ALGORITMO,
        Buffer.from(CRIPT_SEGREDO, 'hex'),
        iv
    );

    let decrypted = decipher.update(textoEncriptado);
    decrypted = Buffer.concat([decrypted, decipher.final()]);

    return decrypted.toString();
}