import { Component } from "react";
import auditoriaDB from "../../dataManager/dtmAuditoria";


function _compareValue(fieldName, fieldValue, obj) {
    let equal = false;
    for (let a in obj) {
        if (a === fieldName) {
            equal = fieldValue === obj[a];
        }
    }
    return equal;
}


class Auditoria extends Component {

    async post(ownerId, uid, userName, key, collection, descricao, tipo, originalData, newData) {
        const item = {
            descricao,
            ownerId,
            tipo,
            autor: {
                key: uid,
                nome: userName
            },
            colecao: {
                key,
                nome: collection
            },
            data: new Date()
        }

        if (originalData && newData) {
            delete newData.key;
            delete newData.ownerId;

            item['antes'] = originalData;
            item['depois'] = newData;
        }

        auditoriaDB.add(item);
    }

    isEqual(x, y) {
        let equal = true;
        for (let a in x) {
            if (typeof x[a] == "object") {
                if (equal) {
                    equal = this.isEqual(x[a], y[a]);
                }

            } else {
                if (equal) {
                    equal = _compareValue(a, x[a], y);
                    console.log(equal);
                }

            }
        }
        return equal;
    }
}

const auditoria = new Auditoria();
export default auditoria;