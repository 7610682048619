import { getOwnerId } from '../../components/auth/auth-provider';
import beneficiarioDB from '../../dataManager/dtmBeneficiario';
import convenioDB from '../../dataManager/dtmConvenio';
import estabelecimentoDB from '../../dataManager/dtmEstabelecimento';
import movimentoDB from '../../dataManager/dtmMovimentos';


export async function getEstabelecimentos() {
  const ownerId = await getOwnerId();
  const estabelecimentos = await estabelecimentoDB.getByOwnerIdCustom(ownerId);
  return estabelecimentos
}

export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios
}

export async function getBeneficiarios(convenioId) {
  const ownerId = await getOwnerId();
  const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId)
  return beneficiarios;
}

export async function getSaldo(beneficiarioId) {
  const saldo = await movimentoDB.getSaldo(beneficiarioId);
  return saldo;
}
