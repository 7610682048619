import React from "react";
import { withRouter } from "react-router";
import { Table, Button, Icon, Modal, Input, Divider, Tooltip } from "antd";
import { getOwnerId, currentUserCol, getUserType, isAdministrador, isGestor } from "../../components/auth/auth-provider";
import { formatNumber } from '../../components/cartao/card-provider';
import PageLayout from "../../components/layout/PageLayout";
import convenioDB from "../../dataManager/dtmConvenio";
import beneficiarioDB from "../../dataManager/dtmBeneficiario";
import mensagem from "../../components/messages/message";
import AdministradorBeneficiarioCRUD from "../../components/cruds/administradorBeneficiarios/AdministradorBeneficiarioCRUD";
import history from "../../history";
import listObj from "../../components/listSearch/listSearch";
import CartaoCRUD from "../../components/cruds/cartao/CartaoCRUD";
import vertteStorage from "../../components/localstorage/localstorage";
import "./AdministradorBeneficiario.css";

const { Search } = Input;
let interval;

class AdministradorBeneficiario extends React.Component {
  state = {
    convenios: [],
    beneficiarios: [],
    beneficiariosOriginal: [],
    canUpdate: false,
    permissoes: {
      naoCadastrar: false,
      naoEditar: false,
      senha: false,
    },
    loading: true,
    queryLimit: 50,
    isSearching: false,
    searchResult: [],
    searchWarning: '',
  };

  constructor(props) {
    super(props);
    this.headerElement = React.createRef();
    this.updateList = this.updateList.bind(this);
    this.applyFilter = this.applyFilter.bind(this);
    this.clearCache = this.clearCache.bind(this);
  }

  async componentDidMount() {
    const userType = await getUserType();
    const ownerId = await getOwnerId();

    let naoCadastrar = false;
    let naoEditar = false;
    let senha = false;

    const userCol = currentUserCol();

    // Se o tipo do usuário for administrador, não preciso verificar se ele tem
    // Permissão ou não para acessar a página. Ele tem!
    if (!await isAdministrador() && userCol) {
      const isOk = userCol.permissoes.some(function (v) { return v.indexOf("beneficiarios") >= 0 });
      if (!isOk) {
        this.setState({ loading: false });
        await mensagem.avisar('Você não tem permisão para acessar essa página.');
        history.push('/dashboard');
        history.go();
        return;
      }

      naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("beneficiarios-cadastrar") >= 0 });
      if (naoCadastrar) {
        naoCadastrar = userCol.permissoes.indexOf('beneficiarios') === -1;
      }
      naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("beneficiarios-editar") >= 0 });
      if (naoEditar) {
        naoEditar = userCol.permissoes.indexOf('beneficiarios') === -1;
      }

      senha = !userCol.permissoes.some(function (v) { return v.indexOf("beneficiarios-senha") >= 0 });
      if (senha) {
        senha = userCol.permissoes.indexOf('beneficiarios') === -1;
      }

    }

    let convenios = undefined;
    let beneficiarios = undefined;

    // Regra de Acesso para Administradores: 
    // 1 - Pego apenas o administrador que está logado;
    // 2 - Filtro apenas os usuários do administrador logado.

    if (await isGestor()) {
      convenios = await convenioDB.getByOwnerIdCustom(ownerId);
      beneficiarios = await beneficiarioDB.getByOwnerIdCustom(ownerId, this.state.queryLimit, true, true);
    } else if (await isAdministrador()) {
      convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.key);
      beneficiarios = await beneficiarioDB.getByOwnerIdAndAdminId(ownerId, userCol.key, this.state.queryLimit, true, true);
    } else {
      convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.administrador.id);
      beneficiarios = await beneficiarioDB.getByOwnerIdAndAdminId(ownerId, userCol.administrador.id, this.state.queryLimit, true, true);
    }

    this.setState({
      userType,
      convenios,
      beneficiarios,
      beneficiariosOriginal: beneficiarios,
      permissoes: { naoCadastrar, naoEditar, senha },
      loading: false
    });
  }

  async applyFilter(text) {
    const ownerId = await getOwnerId();

    const {
      beneficiariosOriginal,
    } = this.state;

    if (text === '') {
      this.setState({ beneficiarios: beneficiariosOriginal, isSearching: false });
      return;
    };

    const words = text.split(' ');
    let finalWords = [];
    const exceptions = ["", " ", "-", "/", "do", "da", "de", "*", "e"];

    words.forEach((word) => {
      if (!exceptions.includes(word)) {
        word = word.toLocaleLowerCase();
        word = word.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        finalWords.push(word);
      }
    })

    const keys = [
      'nome',
      'sobrenome',
      'convenio.nome',
    ];

    const tableResult = listObj.search(
      beneficiariosOriginal,
      text,
      keys
    );

    let alreadyFound = [];
    tableResult.forEach((beneficiario) => {
      alreadyFound.push(beneficiario.key)
    });

    let dbResult;
    if (await isAdministrador()) {
      const userCol = currentUserCol();

      dbResult = await beneficiarioDB.getByPalavraChaveLimitedAndAdminId(
        ownerId,
        userCol.key,
        finalWords,
        100,
        alreadyFound,
        true,
        true
      );
    } else {
      dbResult = await beneficiarioDB.getByPalavraChaveLimited(
        ownerId,
        finalWords,
        100,
        alreadyFound,
        true,
        true
      );
    }

    const beneficiarios = tableResult.concat(dbResult);

    this.setState({ beneficiarios, isSearching: true, searchResult: beneficiarios });

    if (beneficiarios.length === 0) {
      return "Sua busca não retornou nenhum resultado. Por favor, pesquise pelo nome e/ou sobrenome do beneficiário, ou pelo nome do convênio."
    } else {
      return "Caso não encontre o beneficiário desejado, por favor, refine sua busca."
    }
  }

  async updateList(record, merge = true) {
    if (merge) {
      record['ativoStr'] = record['ativo'] ? 'Sim' : 'Não';
      record['cartaoFormatado'] = record.cartao ? formatNumber(record.cartao.numero) : '';

      let beneficiarios = this.state.beneficiarios;
      let registroEncontrado = false;

      this.state.beneficiarios.forEach((item, index) => {
        if (item.key === record.key) {
          beneficiarios[index] = record;
          registroEncontrado = true;
        }
      });

      if (!registroEncontrado) {
        beneficiarios.push(record);
      }

      beneficiarios.sort((a, b) => a.nome > b.nome ? 1 : -1);

      if (this.state.isSearching) {
        let beneficiarioOriginal = this.state.beneficiariosOriginal;
        registroEncontrado = false;

        this.state.beneficiariosOriginal.forEach((item, index) => {
          if (item.key === record.key) {
            beneficiarioOriginal[index] = record;
            registroEncontrado = true;
          }
        });

        if (!registroEncontrado) {
          beneficiarioOriginal.push(record);
        }

        beneficiarioOriginal.sort((a, b) => a.nome > b.nome ? 1 : -1);

        this.setState({ beneficiarios, beneficiarioOriginal });
      } else {
        this.setState({ beneficiarios, beneficiariosOriginal: beneficiarios });
      }

      this.clearCache();
    } else {
      this.setState({ beneficiarios: record });
    }
  }

  clearCache() {
    const endpoints = [
      'getByOwnerIdCustom',
      'getByPalavraChaveLimited',
      'getByPalavraChaveLimitedAndAdminId',
      'getProductsByLetterLimited',
      'getProductsByLetterLimitedAndAdminId',
      'getByOwnerIdAndAdminId'
    ];

    for (let i = 0; i < endpoints.length; i++) {
      vertteStorage.clear(`@firebase-cache/update/beneficiarioDB${endpoints[i]}`);
    }
  }

  editarUsuarioElement(record) {
    if (this.state.permissoes.naoEditar) return;
    record["administradores"] = this.state.administradores;
    this.headerElement.current.editar(record);
  }

  senhaCartaoElement(record) {
    if (this.state.permissoes.senha) return;

    if (!record.cartao) {
      Modal.warning({
        title: 'Ops!',
        content: 'Beneficiário não possui cartão vinculado',
      });
      return;
    }
    this.headerElement.current.mostrarCartao(record);
  }

  render() {
    const columns = [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome"
      },
      {
        title: "Sobrenome",
        dataIndex: "sobrenome",
        key: "sobrenome"
      },
      {
        title: "Convênio",
        dataIndex: "convenio.nome",
        key: "convenio.nome"
      },
      {
        title: 'Cartão',
        dataIndex: 'cartaoFormatado',
        key: 'cartaoFormatado',
      },
      {
        title: "Pessoa Ativa?",
        dataIndex: "ativoStr",
        key: "ativoStr"
      },
    ];


    if (!this.state.permissoes.naoEditar || !this.state.permissoes.senha) {

      columns.push({
        title: '',
        width: '100px',
        key: 'action',
        render: (text, record) => (
          <span>
            <Tooltip placement="topLeft" title="Editar Cadastro">
              <Icon
                type="edit"
                onClick={() => this.editarUsuarioElement(record)}
              />
            </Tooltip>
            <Divider type="vertical"></Divider>

            <Tooltip placement="topLeft" title="Resetar senha do Cartão">
              <Icon
                type="retweet"
                onClick={() => this.senhaCartaoElement(record)}
              />
            </Tooltip>
          </span>
        ),
      });

    }

    return (
      <div>
        <PageLayout selectItem={"administradores-beneficiarios"}>
          <Table
            pagination={{
              defaultPageSize: 100,
              position: 'both',
              pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
              showSizeChanger: true,
              locale: { items_per_page: "" }
            }}
            title={() => (
              <Header
                ref={this.headerElement}

                naoCadastrar={this.state.permissoes.naoCadastrar}
                convenios={this.state.convenios}
                isSearching={this.state.isSearching}
                searchResult={this.state.searchResult}
                beneficiariosOriginal={this.state.beneficiariosOriginal}

                applyFilter={this.applyFilter}
                updateList={this.updateList}
              />
            )}
            columns={columns}
            dataSource={this.state.beneficiarios}
            bordered
            loading={this.state.loading}
          />
        </PageLayout>
      </div>
    );
  }
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.novoUsuarioElement = React.createRef();
    this.cartaoElement = React.createRef();
    this.filterTable = this.filterTable.bind(this);
    this.resetTime = this.resetTime.bind(this);
    this.onAlphabetClick = this.onAlphabetClick.bind(this);
    this.novo = this.novo.bind(this);

    this.state = {
      visible: false,
      searchWarning: "",
      selectedKey: "todos"
    };
  }

  novo() {
    const record = {
      convenios: this.props.convenios,
    }
    this.novoUsuarioElement.current.show(false, record);
  }

  editar(record) {
    record["convenios"] = this.props.convenios;
    this.novoUsuarioElement.current.show(true, record);
  }

  mostrarCartao(record) {
    this.cartaoElement.current.showCartao(record);
  }

  async resetTime(obj) {
    clearInterval(interval);
    interval = setInterval(this.filterTable, 3000, obj.target.value);
  }

  async filterTable(value) {
    clearInterval(interval);
    const text = typeof value === "object" ? value.target.value : value;

    if (text === '') {
      this.setState({ searchWarning: '' });
    }

    const searchWarning = await this.props.applyFilter(text, this.state.selectedKey);

    if (searchWarning) {
      this.setState({ searchWarning });
    }
  }

  prepareAlphabets = () => {
    let result = [];
    result.push(
      <Button ref={this.todosBtn} type="button" key="todos" onClick={this.onAlphabetClick} value={"todos"} >{"Todos"}</Button>
    )

    for (let i = 65; i < 91; i++) {
      result.push(
        <Button type="outline" key={i} onClick={this.onAlphabetClick} value={String.fromCharCode(i)} >{String.fromCharCode(i)}</Button>
      )
    }

    return result;
  }

  async onAlphabetClick(e) {
    e.persist();

    const {
      isSearching,
      searchResult,
      beneficiariosOriginal
    } = this.props;

    const ownerId = await getOwnerId();
    const key = e.target.value.toLowerCase();
    let beneficiarios = [];

    if (!isSearching) {
      if (key === 'todos') {
        beneficiarios = beneficiariosOriginal;
      } else {
        if (await isAdministrador()) {
          const userCol = currentUserCol();

          beneficiarios = await beneficiarioDB.getProductsByLetterLimitedAndAdminId(
            ownerId,
            userCol.key,
            key.toUpperCase(),
            50,
            true,
            true
          );
        } else {
          beneficiarios = await beneficiarioDB.getProductsByLetterLimited(
            ownerId,
            key.toUpperCase(),
            50,
            true,
            true
          );
        }
      }
    } else {
      if (key === 'todos') {
        beneficiarios = searchResult;
      } else {
        searchResult.forEach((p) => {
          if (p.nome.charAt(0).toLowerCase() === key) {
            beneficiarios.push(p)
          }
        });
      }
    }

    this.props.updateList(beneficiarios, false);
    this.setState({ selectedKey: key });
  }

  render() {
    return (
      <div>
        <NovoUsuario
          ref={this.novoUsuarioElement}
          updateList={this.props.updateList}
          record={[]}
        ></NovoUsuario>

        <Cartao
          ref={this.cartaoElement}
        ></Cartao>


        <Button type="primary" disabled={this.props.naoCadastrar} onClick={this.novo}>
          <Icon className="icon" type="plus" /> Novo Beneficiário
        </Button>

        <div className="searchContainer">
          <Search
            placeholder="Procurar"
            onSearch={this.filterTable}
            allowClear
            onChange={this.resetTime}
            style={{ marginLeft: "10px", width: 200 }}
          />
          <span
            className="textError"
            style={{ display: this.state.searchWarning === '' ? 'none' : 'block' }}
          >{this.state.searchWarning}</span>
        </div>

        <div className="alphabet">
          {this.prepareAlphabets()}
        </div>

      </div>
    );
  }
}

class Cartao extends React.Component {
  state = {
    visible: false,
    record: [],
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  showCartao(record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      record: record
    });
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record
    });
  }

  handleOk(record) {
    this.setState({ visible: false });
    if (record) {
      this.props.updateList(record);
    }
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title="Dados do Cartão"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={true}
        onCancel={() => { this.setState({ visible: false }); }}

      >
        <CartaoCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          record={this.state.record}
        ></CartaoCRUD>
      </Modal>
    );
  }
}

class NovoUsuario extends React.Component {
  state = {
    visible: false,
    confirmLoading: false,
    editMode: false,
    record: [],
    empresas: []
  };

  constructor(props) {
    super(props);
    this.handleOk = this.handleOk.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  show(editMode, record) {
    if (!record) {
      record = [];
    }

    this.setState({
      visible: true,
      confirmLoading: false,
      editMode: editMode,
      record: record
    });
  }

  handleOk(record) {
    this.setState({ visible: false });
    this.props.updateList(record);
  }

  handleCancel() {
    this.setState({ visible: false });
  }

  render() {
    return (
      <Modal
        title="Beneficiário"
        visible={this.state.visible}
        destroyOnClose={true}
        confirmLoading={this.state.confirmLoading}
        centered={true}
        onOk={this.handleOk}
        footer={null}
        closable={true}
        onCancel={() => { this.setState({ visible: false }); }}
      >
        <AdministradorBeneficiarioCRUD
          handleOk={this.handleOk}
          handleCancel={this.handleCancel}
          editMode={this.state.editMode}
          record={this.state.record}
          empresas={this.state.empresas}
        ></AdministradorBeneficiarioCRUD>
      </Modal>
    );
  }
}

export default withRouter(AdministradorBeneficiario);
