import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class EstabelecimentoDB extends DataManager {

    state = {
        collection: 'estabelecimentos',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId, fromCache, update) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const updateEndpoint = update ? '/estabelecimentoDB/getByOwnerIdCustom' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByOwnerIdAndAtivo(ownerId, ativo) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', ownerId)
            .where('ativo', '==', ativo)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const snapshot = await this.getQueryData(query, false);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const estabelecimentoDB = new EstabelecimentoDB();
export default estabelecimentoDB;