import React, { Component } from "react";
import { Menu } from "antd";
import {
  currentUserCol,
  getUserType,
  isDebitPresent,
} from "../auth/auth-provider";
import funcs from "../funcs/funcs";
import ReleaseNotes from "./ReleaseNotes";

const { SubMenu } = Menu;

class MenuDebito extends Component {
  state = {
    userType: "Gestor",
    debitPresent: undefined,
    permissoes: {
      gestores: true,

      // meus clientes:
      administradores: true,
      usuarios: true,
      beneficiarios: true,
      pedidos: true,
      estornoDebito: true,
      capturaDebito: true,
      showReleaseNotes: false,
    },
  };

  constructor(props) {
    super(props);
    this.showReleaseNotesElement = React.createRef();
    this.showReleaseNotes = this.showReleaseNotes.bind(this);
  }

  async componentDidMount() {
    const userType = await getUserType();
    let userCol = currentUserCol();

    if (userType === "Gestor" || userType === "AdministradorUsuario") {
      // Configuração dos relatórios:
      let relatorios = userCol
        ? userCol.permissoes.some(function (v) {
          return v.indexOf("relatorios") >= 0;
        })
        : true;

      let relatoriosClientes = userCol
        ? await funcs.podeAbrirRelatorio(userCol, "relatorios-clientes")
        : true;


      let relatoriosMovimentacao = userCol
        ? await funcs.podeAbrirRelatorio(userCol, "relatorios-movimentacao")
        : true;

      let relatoriosHistoricoMovimentacao = userCol
        ? await funcs.podeAbrirRelatorio(
          userCol,
          "relatorios-historico-movimentacao"
        )
        : true;

      let relatoriosSaldo = userCol
        ? await funcs.podeAbrirRelatorio(userCol, "relatorios-saldos")
        : true;

      let extrato = userCol
        ? await funcs.podeAbrirRelatorio(userCol, "relatorios-extrato")
        : true;

      let descontoFolha = userCol
        ? await funcs.podeAbrirRelatorio(userCol, "relatorios-descfolha")
        : true;

      let saldoConvenios = userCol
        ? await funcs.podeAbrirRelatorio(userCol, 'relatorios-saldoConvenios')
        : true;


      let vencimentoCartoes = userCol
        ? await funcs.podeAbrirRelatorio(userCol, 'relatorios-vencimento-cartao')
        : true;


      // Quando o usuário tem acesso a todos os relatórios, as opções filhos não são gravados.
      // Por isso, faço o ajuste aqui nos filhos

      if (
        relatorios &&
        !relatoriosClientes &&
        !relatoriosMovimentacao &&
        !relatoriosHistoricoMovimentacao &&
        !relatoriosSaldo
      ) {
        relatoriosClientes = true;
        relatoriosMovimentacao = true;
        relatoriosHistoricoMovimentacao = true;
        relatoriosSaldo = true;
        extrato = true;
        descontoFolha = true;
        saldoConvenios = true;
        vencimentoCartoes = true;
      }

      this.setState({
        permissoes: {
          gestores: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("gestores") >= 0;
            })
            : true,
          administradores: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("administradores") >= 0;
            })
            : true,
          usuarios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("usuarios") >= 0;
            })
            : true,
          convenios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("convenios") >= 0;
            })
            : true,
          beneficiarios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("beneficiarios") >= 0;
            })
            : true,
          pedidos: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("pedidos") >= 0;
            })
            : true,
          estabelecimentos: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("estabelecimentos") >= 0;
            })
            : true,
          estornoDebito: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("estornoDebito") >= 0;
            })
            : true,

          capturaDebito: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("capturaDebito") >= 0;
            })
            : true,

          // Relatórios
          relatorios,
          relatoriosClientes,
          relatoriosMovimentacao,
          relatoriosHistoricoMovimentacao,
          relatoriosSaldo,
          extrato,
          descontoFolha,
          saldoConvenios,
          vencimentoCartoes,
          configuracoes: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("configuracoes") >= 0;
            })
            : true,
        },
      });
    }

    if (userType === "Administrador") {
      this.setState({
        permissoes: {
          gestores: false,
          administradores: false,
          usuarios: true,
          convenios: true,
          beneficiarios: true,
          pedidos: true,
          estabelecimentos: true,
          configuracoes: true,
          estornoDebito: true,
        },
      });
    }

    const debitPresent = await isDebitPresent();

    this.setState({
      userType,
      debitPresent
    });
  }

  showReleaseNotes(showReleaseNotes) {
    this.setState({ showReleaseNotes });
  }

  render() {
    return (
      <div>
        <ReleaseNotes
          ref={this.showReleaseNotesElement}
          visible={this.state.showReleaseNotes}
          onCancel={this.showReleaseNotes}
        ></ReleaseNotes>

        {this.state.debitPresent ? (
          <Menu
            defaultSelectedKeys={[this.props.selectItem]}
            defaultOpenKeys={[this.props.openMenu]}
            onClick={this.props.onMenuClick}
            theme="dark"
            mode="horizontal"
            style={{
              fontWeight: 600,
              fontSize: "1rem",
              backgroundColor: "transparent",
              lineHeight: "6.25rem",
              width: "120%",
            }}
          >
            <Menu.Item key="dashboard" id="dashboardMenu">
              <span>Dashboard</span>
            </Menu.Item>

            {/* ========================================== */}
            {/* ============= OWNER E GESTOR ============= */}
            {/* ===== DONO DA CONTA E SEUS GESTORES ====== */}
            {/* ========================================== */}

            {this.state.userType === "Gestor" &&
              this.state.permissoes.gestores ? (
              <Menu.Item key="gestores">
                <span>Gestores</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" ? (
              <SubMenu
                id="minhaRedeMenu"
                key="meusClientes"
                title={
                  <span>
                    <span>Minha Rede</span>
                  </span>
                }
              >
                {this.state.permissoes.administradores === true ? (
                  <Menu.Item className="antSubMenu" key="administradores">
                    <span>Administradores</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.usuarios ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="administradores-usuarios"
                  >
                    <span>Usuários</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.convenios ? (
                  <Menu.Item className="antSubMenu" key="convenios">
                    <span>Convênios</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.beneficiarios ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="administradores-beneficiarios"
                  >
                    <span>Beneficiários</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.pedidos ? (
                  <Menu.Item className="antSubMenu" key="convenio-pedidos">
                    <span>Pedidos</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.estornoDebito ? (
                  <Menu.Item className="antSubMenu" key="estorno-debito">
                    <span>Estornos</span>
                  </Menu.Item>
                ) : (
                  ""
                )}
              </SubMenu>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" &&
              this.state.permissoes.estabelecimentos ? (
              <Menu.Item key="estabelecimentos">
                <span>Estabelecimentos</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" &&
              this.state.permissoes.relatorios ? (
              <SubMenu
                key="relatorios"
                title={
                  <span>
                    <span>Relatórios</span>
                  </span>
                }
              >
                {this.state.permissoes.relatoriosClientes ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/convenios"
                  >
                    <span>Clientes (Convênios)</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.relatoriosMovimentacao ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/movimentacao"
                  >
                    <span>Movimentação</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.relatoriosHistoricoMovimentacao ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/historico-movimentacao"
                  >
                    <span>Histórico Mov</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.relatoriosSaldo ? (
                  <Menu.Item className="antSubMenu" key="relatorios/saldos">
                    <span>Saldos</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.extrato ? (
                  <Menu.Item className="antSubMenu" key="relatorios/extrato">
                    <span>Extrato</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.extrato ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/desconto-folha"
                  >
                    <span>Desc. Folha</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.saldoConvenios ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/saldo-convenios"
                  >
                    <span>Saldo Convênios</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.saldoConvenios ? (
                  <Menu.Item
                    className="antSubMenu"
                    key="relatorios/vencimento-cartao"
                  >
                    <span>Vencimento Cartões</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

              </SubMenu>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" ? (
              <SubMenu
                id="ferramentas"
                key="ferramentas"
                title={
                  <span>
                    <span>Ferramentas</span>
                  </span>
                }
              >
                {this.state.permissoes.administradores ? (
                  <Menu.Item className="antSubMenu" key="tools/cards/print">
                    <span>Impressão</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.administradores ? (
                  <Menu.Item className="antSubMenu" key="tools/cards/new">
                    <span>2ª Via</span>
                  </Menu.Item>
                ) : (
                  ""
                )}

                {this.state.permissoes.capturaDebito ? (
                  <Menu.Item className="antSubMenu" key="tools/captura">
                    <span>Captura</span>
                  </Menu.Item>
                ) : (
                  ""
                )}


              </SubMenu>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" &&
              this.state.permissoes.configuracoes ? (
              <Menu.Item key="configuracoes" id="configuracoesMenu">
                <span>Configurações</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Gestor" &&
              this.state.permissoes.configuracoes ? (
              <SubMenu
                key="helpMenu"
                title={
                  <span>
                    <span>Ajuda</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/releasenotes"
                  onClick={(e) => this.showReleaseNotes(true)}
                >
                  Notas da Versão
                </Menu.Item>
              </SubMenu>
            ) : (
              ""
            )}

            {/* ========================================== */}
            {/* ============= ADMINISTRADOR ============== */}
            {/* ============= É O CONVENIADO ============= */}
            {/* ========================================== */}

            {this.state.userType === "Administrador" ? (
              <Menu.Item key="administradores-usuarios">
                <span>Usuários</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Administrador" ? (
              <Menu.Item key="convenios">
                <span>Convênios</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Administrador" ? (
              <Menu.Item key="administradores-beneficiarios">
                <span>Beneficiários</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "Administrador" ? (
              <Menu.Item key="convenio-pedidos">
                <span>Pedidos</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {/* ========================================== */}
            {/* ========== AdministradorUsuario ========== */}
            {/* ========================================== */}

            {this.state.userType === "AdministradorUsuario" &&
              this.state.permissoes.administradores ? (
              <Menu.Item key="administradores">
                <span>Administradores</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "AdministradorUsuario" &&
              this.state.permissoes.usuarios ? (
              <Menu.Item key="administradores-usuarios">
                <span>Usuários</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "AdministradorUsuario" &&
              this.state.permissoes.convenios ? (
              <Menu.Item key="convenios">
                <span>Convênios</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "AdministradorUsuario" &&
              this.state.permissoes.beneficiarios ? (
              <Menu.Item key="administradores-beneficiarios">
                <span>Beneficiários</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {this.state.userType === "AdministradorUsuario" &&
              this.state.permissoes.pedidos ? (
              <Menu.Item key="convenio-pedidos">
                <span>Pedidos</span>
              </Menu.Item>
            ) : (
              ""
            )}
          </Menu>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MenuDebito;
