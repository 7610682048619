import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Select,
  message,
  Tabs,
  Modal,
  Switch
} from "antd";
import { isCPF, formatCPF, clearCpfFormat, isCNPJ, formatCNPJ } from '../../funcs/utils';
import { getOwnerId } from '../../auth/auth-provider';
import { getNewOne, formatNumber, unformatNumber } from '../../cartao/card-provider';
import { salvarAuditoriaAtivo, salvarAuditoriaBloqueado, salvarAuditoriaCreditoZerado } from './AdministradorBeneficiarioCRUDF';

import mensagem from '../../messages/message';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import cartaoDB from '../../../dataManager/dtmCartao';
import usuarioDB from '../../../dataManager/dtmUsuario';
import userAdmin from '../../userAdmin/userAdmin';
import CurrencyInput from 'react-currency-input';
import CPFInput from '../../masks/cpf';
import API from '../../api/api';

const { Option } = Select;
const { TabPane } = Tabs;
const api = new API();

class AdministradorBeneficiarioCRUD extends React.Component {
  state = {
    confirmDirty: false,
    autoCompleteResult: [],
    convenios: [],
    selecteds: [],
    loading: false,
    loadingButton: false,
    modalZerarCredito: false,
    modalBloquearBeneficiario: false,
    edited: false,
    zerarCredito: false,
    fileList: [],
    data: {
      key: this.props.record.key,
      ativo: 'Sim',
      apelido: '',
      bloqueado: false,
      nome: '',
      cpfCnpj: '',
      email: '',
      endereco: {
        logradouro: '',
        bairro: '',
        cidade: '',
        uf: '',
        telefone: ''
      },
      cartao: {
        numero: '',
        dv: '',
      },
      exibirOpcaoCredito: false,
      credito: {
        ativo: 'não',
        valor: 0,
      }
    },
  };

  constructor(props) {
    super(props);

    this.cancelClick = this.cancelClick.bind(this);
    this.convenioPossuiCreditoAtivado = this.convenioPossuiCreditoAtivado.bind(this);
    this.convenioValorCredito = this.convenioValorCredito.bind(this);

    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.selectCreditoChange = this.selectCreditoChange.bind(this);
    this.valueCreditoChange = this.valueCreditoChange.bind(this);

    this.onChangeSelectAtivo = this.onChangeSelectAtivo.bind(this);
    this.onChangeBloquear = this.onChangeBloquear.bind(this);

    this.handleConfirmacao = this.handleConfirmacao.bind(this);
    this.handleBloquear = this.handleBloquear.bind(this);
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {

      if (err) {
        return;
      }

      if (this.state.selecteds.length === 0) {
        mensagem.openNotificationWithIcon(
          "error",
          "Cadastro incompleto",
          "Selecione a empresa"
        );
        return;
      }

      let { selecteds } = this.state;

      // Verificação de concessão de crédito
      const convenioPossuiCreditoAtivado = this.convenioPossuiCreditoAtivado(selecteds[0]);
      let valorEmCentavos = 0;

      if (convenioPossuiCreditoAtivado && values.operaCredito === 'sim') {
        // Verifico se o valor é maior que o valor concedido é maior que o valor fixado no convênio
        valorEmCentavos = Number(values.valorCredito.replace('R$ ', '').split('.').join('').split(',').join('.'));
        valorEmCentavos = valorEmCentavos * 100;

        const valorConvenioEmCentavos = this.convenioValorCredito(selecteds[0], true);
        const valorConvenio = this.convenioValorCredito(selecteds[0]);

        if (valorEmCentavos > valorConvenioEmCentavos) {
          mensagem.openNotificationWithIcon(
            "error",
            "Valor do crédito",
            `O valor do crédito a ser concedido não pode ser maior que ${valorConvenio}`,
          );

          return;
        }
      }

      this.setState({ loadingButton: true });

      const gravarConvenios = [];

      if (!Array.isArray(selecteds)) {
        selecteds = [selecteds];
      }

      selecteds.forEach(obj => {
        const convenio = this.state.convenios.filter(item => {
          return item.key === obj;
        })[0];

        gravarConvenios.push({
          id: convenio.key,
          nome: convenio.props.children
        });
      });


      const administradorConvenio = this.props.record.convenios.filter(item => {
        return item.key === gravarConvenios[0].id;
      })[0].administrador;

      let snapshot = undefined;

      if (!isCPF(values.cpfCnpj) && !isCNPJ(values.cpfCnpj)) {
        mensagem.openNotificationWithIcon('error', 'CPF/CNPJ inválido', 'O CPF/CNPJ informado não está correto.');
        this.setState({ loadingButton: false });
        return;
      }

      values.cpfCnpj = isCPF(values.cpfCnpj) ? formatCPF(values.cpfCnpj) : formatCNPJ(values.cpfCnpj);

      // procuro pelo CPF para checar se está repetido
      snapshot = await beneficiarioDB.getByCPF(values.cpfCnpj);

      // Verifico se está havendo uma duplicação de CPF
      if (snapshot !== undefined) {
        if (
          (this.props.editMode &&
            snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O CPF/CNPJ informado já foi cadastrado."
          );
          return;
        }
      }

      // procuro pelo email para checar se está repetido
      snapshot = values.email ? await beneficiarioDB.getByEmail(values.email) : undefined;

      if (snapshot !== undefined) {
        if (
          (this.props.editMode &&
            snapshot[0].key !== this.props.record.key) ||
          (this.props.editMode && snapshot.length > 1) ||
          !this.props.editMode
        ) {
          this.setState({ loadingButton: false });
          mensagem.openNotificationWithIcon(
            "error",
            "Duplicação",
            "O email informado já foi cadastrado."
          );
          return;
        }
      }

      const ownerId = await getOwnerId();

      const item = {
        ativo: values.ativo.toLocaleLowerCase() === 'sim' ? true : false,
        bloqueado: values.bloqueado ?? false,
        nome: values.nome,
        sobrenome: values.sobrenome,
        cpf: values.cpfCnpj,
        administrador: {
          id: administradorConvenio.id,
          nome: administradorConvenio.nome,
        },
        convenio: {
          ...gravarConvenios[0]
        },
        endereco: {
          logradouro: values.endereco,
          bairro: values.bairro,
          cidade: values.cidade,
          uf: values.uf,
          telefone: values.telefone
        },
        ownerId,
        cartao: {
          numero: unformatNumber(this.state.data.cartao.numero),
          dv: this.state.data.cartao.dv,
          // password: this.state.data.cartao.password,
        },
        credito: {
          ativo: (convenioPossuiCreditoAtivado && values.operaCredito === 'sim') ? true : false,
          valor: (convenioPossuiCreditoAtivado && values.operaCredito === 'sim') ? values.valorCredito : '',
          valorEmCentavos: (convenioPossuiCreditoAtivado && values.operaCredito && values.valorCredito) ? valorEmCentavos : 0,
        }
      };

      if (values.email) {
        item['email'] = values.email;
      }

      let novoId = '';
      if (!this.props.editMode) {
        const password = clearCpfFormat(values.cpfCnpj);

        // Antes de mais nada, crio um novo login com o email
        // e os seis dígitos do cpf ou cnpj como senha
        if (values.email) {
          const obj = await userAdmin.createEmail(values.email, password.substr(0, 6));
          if (!obj) {
            return;
          }
        }

        item.cartao['password'] = password.substr(0, 4);

        const rec = await beneficiarioDB.add(item);
        novoId = rec.id;

        // Salvo o cartão do usuário
        const d = new Date();
        const validAt = new Date(d.getFullYear() + 6, d.getMonth(), d.getDate());

        const cartao = {
          ownerId,
          beneficiarioId: novoId,
          criadoEm: new Date(),
          numero: unformatNumber(this.state.data.cartao.numero),
          dv: this.state.data.cartao.dv,
          password: password.substr(0, 4),
          convenio: {
            ...gravarConvenios[0]
          },
          impressao: {
            impresso: false,
            ignorado: false,
          },
          status: 'Aguardando ativação',
          validAt,
        };

        await cartaoDB.add(cartao);

        const usuario = {
          uid: '',
          key: novoId,
          tipo: 'Beneficiario',
          ownerId,
        }
        // Salvo o usuário como gestor
        await usuarioDB.add(usuario);

      } else {
        if (values.email && !this.state.data.email) {
          const password = clearCpfFormat(values.cpfCnpj);
          const obj = await userAdmin.createEmail(values.email, password.substr(0, 6));
          if (!obj) {
            return;
          }
        }

        let isChanged = this.state.data.email !== null && this.state.data.email !== undefined && this.state.data.email !== '';
        if (isChanged) {
          isChanged = (this.props.record.email !== values.email);
        }

        const podeSalvar = !isChanged || await mensagem.confirmar('Alteração de email detectada. Confirma?');

        if (!podeSalvar) {
          this.setState({ loading: false });
          message.warning('Verifique o email do beneficiário');
          return;
        }

        if (isChanged) {
          message.info('Aguarde, estamos alterando o email...');
          await userAdmin.changeUserMail(this.props.record.email, values.email);
        }

        if (this.state.zerarCredito || values.bloqueado) {
          item.credito = {
            ativo: false,
            valor: '',
            valorEmCentavos: 0
          }

          const getToken = await api.getToken(item.convenio.id);
          await api.resetCredit(getToken.token, this.props.record.key);

          salvarAuditoriaCreditoZerado(item, this.props.record, ownerId);
        }

        if (this.props.record.ativo !== item.ativo) salvarAuditoriaAtivo(item, this.props.record, ownerId);

        if (this.props.record.bloqueado !== item.bloqueado) salvarAuditoriaBloqueado(item, this.props.record, ownerId);

        await beneficiarioDB.update(this.props.record.key, item);

        if (item.email) {
          const active = !item.bloqueado;
          userAdmin.activeOrDeactiveUser(item.email, active);
        }
      }

      const tipoAtualizacao = this.props.editMode ? 'atualizado' : 'cadastrado';
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `O beneficiário ${values.nome} foi ${tipoAtualizacao} com sucesso`, 3);

      item['key'] = novoId ? novoId : this.props.record.key;
      item['ativoStr'] = values.ativo.toLocaleLowerCase() === 'sim' ? 'Sim' : 'Não';

      this.props.handleOk(item);
      this.setState({ loadingButton: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  async componentDidMount() {
    const { Option } = Select;
    const convenios = [];
    this.props.record.convenios.forEach(item => {
      convenios.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    });

    this.setState({ convenios });

    const selecteds = [];

    if (this.props.editMode && this.props.record) {
      selecteds.push(this.props.record.convenio.id);

      const exibirOpcaoCredito = this.convenioPossuiCreditoAtivado(this.props.record.convenio.id);
      let possuiCreditoAtivado = exibirOpcaoCredito;


      let valorCredito = 0;
      if (possuiCreditoAtivado) {
        // Se o convênio tem o crédito ativado no convênio mas o beneficiário 
        // não tem o crédito configurado, o padrão é sim
        if (!this.props.record.credito) {
          possuiCreditoAtivado = 'sim'
        }
        else {
          possuiCreditoAtivado = this.props.record.credito && this.props.record.credito.ativo ? 'sim' : 'nao';
        }

        if (possuiCreditoAtivado === 'sim') {
          valorCredito = this.props.record.credito && this.props.record.credito.valor ? this.props.record.credito.valor : this.convenioValorCredito(this.props.record.convenio.id);
        }
      }

      this.setState({
        selecteds,
        data: {
          key: this.props.record.key,
          ativo: this.props.record.ativo ? 'sim' : 'nao',
          bloqueado: this.props.record.bloqueado ?? false,
          nome: this.props.record.nome,
          sobrenome: this.props.record.sobrenome,
          cpfCnpj: this.props.record.cpf,
          email: this.props.record.email,
          endereco: this.props.record.endereco,
          cartao: {
            numero: this.props.record.cartao ? formatNumber(this.props.record.cartao.numero) : '',
            dv: this.props.record.cartao ? this.props.record.cartao.dv : '',
          },
          exibirOpcaoCredito,
          credito: {
            ativo: possuiCreditoAtivado,
            valor: valorCredito,
          },
        },
      });
    }
    else {
      // crio um novo cartão
      const cartao = getNewOne(true);
      const data = this.state.data;
      data.cartao = cartao;

      // Só seleciono automaticamente se o length for igual a 1 
      // para evitar erros de cadastramento
      if (this.props.record.convenios.length === 1) {
        selecteds.push(this.props.record.convenios[0].key);
        this.setState({ selecteds });
      }

      this.setState({ data });
    }
  }

  convenioPossuiCreditoAtivado(convenioId) {
    let possuiCreditoAtivado = false;
    this.props.record.convenios.forEach(item => {
      if (item.key === convenioId) {
        possuiCreditoAtivado = item.credito && item.credito.ativo === true;
      }
    });
    return possuiCreditoAtivado;
  }

  convenioValorCredito(convenioId, emCentavos) {
    let valorCredito = 0;
    this.props.record.convenios.forEach(item => {
      if (item.key === convenioId) {
        if (item.credito) {
          valorCredito = emCentavos ? item.credito.valorEmCentavos : item.credito.valor;
        }
      }
    });
    return valorCredito;
  }

  selectCreditoChange(value) {
    const data = this.state.data;
    data.credito.ativo = value;

    if (data.credito.valor === 0) {
      data.credito.valor = this.convenioValorCredito(this.state.selecteds[0]);
    }

    this.setState({ data });
  }

  valueCreditoChange(value) {
    const data = this.state.data;
    data.credito.valor = value;
    this.setState({ data });
  }

  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    const convenioPossuiCredito = this.convenioPossuiCreditoAtivado(value);
    if (convenioPossuiCredito) {
      const creditoOriginal = this.state.creditoOriginal;
      let valor = 0;
      if (this.props.editMode) {
        this.selectCreditoChange(creditoOriginal ? creditoOriginal.ativo : this.state.data.credito.ativo);
        valor = creditoOriginal ? creditoOriginal.valor : this.state.data.credito.valor ? this.state.data.credito.valor : this.convenioValorCredito(value);
      } else {
        this.selectCreditoChange('sim');
        valor = this.convenioValorCredito(value);
      }
      this.valueCreditoChange(valor);
    }
    else {
      const creditoOriginal = this.state.data.credito ? {
        ativo: this.state.data.credito.ativo,
        valor: this.state.data.credito.valor,
      } : {
        ativo: false,
        valor: this.convenioValorCredito(value),
      }
      this.setState({ creditoOriginal });


      this.selectCreditoChange('nao');
      this.valueCreditoChange('');
    }

    const data = this.state.data;
    data.exibirOpcaoCredito = convenioPossuiCredito ? true : false;
    this.setState({ selecteds: [value], data });
  }

  onChangeSelectAtivo(value) {
    let { data } = this.state;
    data.ativo = value;

    let modalZerarCredito = false;

    if (
      value === 'nao' &&
      this.props.editMode &&
      this.props.record.credito &&
      this.props.record.credito.ativo
    ) {
      modalZerarCredito = true;
    };

    this.setState({ data, modalZerarCredito });
  }

  onChangeBloquear(value) {
    const { data } = this.state;
    data.bloqueado = value;

    let modalBloquearBeneficiario = false;

    if (value) {
      modalBloquearBeneficiario = true;
    }

    this.setState({ data, modalBloquearBeneficiario });
  }

  handleConfirmacao(strConfirmacao) {
    let zerarCredito = undefined;

    switch (strConfirmacao.trim()) {
      case 'ZERAR CRÉDITO':
        zerarCredito = true;
        break;

      case 'NÃO ZERAR CRÉDITO':
        zerarCredito = false;
        break;
    }

    if (zerarCredito !== undefined) {
      const message = zerarCredito ?
        'O crédito pré-pago será zerado assim que você salvar as alterações.' :
        'O crédito pré-pago NÃO será zerado ao inativar esse beneficiário.';

      mensagem.openNotificationWithIcon(
        'warning',
        'Atenção',
        message
      );

      this.setState({
        zerarCredito,
        modalZerarCredito: false
      })
    } else {
      mensagem.openNotificationWithIcon(
        'error',
        'Erro',
        'Frase incorreta. Digite novamente.'
      );
    }
  }

  handleBloquear(strConfirmacao) {
    if (strConfirmacao.trim() === 'BLOQUEAR BENEFICIÁRIO') {
      mensagem.openNotificationWithIcon(
        'warning',
        'Atenção',
        'Usuário bloqueado. Salve para não perder essa alteração.'
      );

      const { data } = this.state;
      data.ativo = 'nao';

      this.setState({ data, modalBloquearBeneficiario: false });
    } else {
      mensagem.openNotificationWithIcon(
        'error',
        'Erro',
        'Frase incorreta. Digite novamente.'
      );
    }
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    const requerCredito = this.state.data.credito && this.state.data.credito.ativo === 'sim' ? true : false;

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>
        <ZerarCredito
          visible={this.state.modalZerarCredito}
          handleOk={this.handleConfirmacao}
          handleCancel={() => this.onChangeSelectAtivo('sim')}
        />

        <BloquearBeneficiario
          visible={this.state.modalBloquearBeneficiario}
          handleOk={this.handleBloquear}
          handleCancel={() => this.onChangeBloquear(false)}
        />

        <Tabs defaultActiveKey="geral">
          <TabPane tab="Geral" key="geral">
            <Form.Item
              ref="selectAdministrador"
              label={
                <span>
                  Convênio&nbsp;
                  <Tooltip title="Escolha o convênio do beneficiário">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("selectConvenio", {
                initialValue: this.state.selecteds,
                rules: [
                  { required: true, message: "Selecione um convênio" }
                ]
              })(
                <Select
                  mode="single"
                  style={{ width: "100%" }}
                  placeholder="Selecione um convênio"
                  onChange={this.selectHandleChange}

                  showSearch

                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }

                  disabled={this.state.data.bloqueado}
                >
                  {this.state.convenios}
                </Select>
              )}
            </Form.Item>

            <Form.Item
              style={{ disabled: true }}
              label={
                <span>
                  Cartão&nbsp;
                  <Tooltip title={`Cartão do beneficiário`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('cartao', {
                initialValue: this.state.data.cartao.numero,
                rules: [{ disabled: true, required: true, message: `Número atual do cartão do beneficiário` }],
              })(

                <Input disabled={true} style={{ width: '200px' }} />

              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Ativo&nbsp;
                  <Tooltip title="Esse usuário está ativo?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator('ativo', {
                initialValue: this.state.data.ativo,
                rules: [{ required: true, message: 'Informe se o usuário está ativo' }],
              })(

                <div>
                  <Select
                    style={{ width: 120 }}
                    value={this.state.data.ativo}
                    onChange={(value) => this.onChangeSelectAtivo(value)}
                    disabled={this.state.data.bloqueado}
                  >
                    <Option value="sim">Sim</Option>
                    <Option value="nao">Não</Option>
                  </Select>
                </div>
              )}
            </Form.Item>


            <Form.Item
              label={
                <span>
                  Opera crédito?&nbsp;
                  <Tooltip title={`Esse beneficiário pode ter crédito?`}>
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
              style={{ display: this.state.data.exibirOpcaoCredito ? 'block' : 'none' }}
            >
              {getFieldDecorator('operaCredito', {
                initialValue: this.state.data.credito.ativo,
                onChange: this.selectCreditoChange,
                rules: [{ required: this.state.data.exibirOpcaoCredito, message: `Informe se esse beneficiário pode ter crédito` }],
              })(
                <Select
                  style={{ width: 120 }}
                  disabled={this.state.data.bloqueado}
                >
                  <Option value="sim">Sim</Option>
                  <Option value="nao">Não</Option>
                </Select>
              )}
            </Form.Item>

            <Form.Item label="Valor crédito" style={{ display: this.state.data.credito.ativo === 'sim' ? 'block' : 'none' }}>
              {getFieldDecorator('valorCredito', {
                initialValue: this.state.data.credito.valor,
                rules: [{ required: requerCredito, message: 'Informe o valor do crédito' }],
              })(

                <CurrencyInput
                  className="wrapInputValorCredito"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  // value={this.state.data.credito.valor}
                  onChange={this.valueCreditoChange}
                  disabled={this.state.data.bloqueado}
                />
              )}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  Nome&nbsp;
                  <Tooltip title="Qual é o nome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("nome", {
                initialValue: this.state.data.nome,
                rules: [
                  { required: true, message: "Informe o apelido", whitespace: true }
                ]
              })(<Input disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item
              ref="txtSobrenome"
              label={
                <span>
                  Sobrenome&nbsp;
                  <Tooltip title="Qual é o sobrenome?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("sobrenome", {
                initialValue: this.state.data.sobrenome,
                rules: [
                  { required: true, message: "Informe o sobrenome", whitespace: true }
                ]
              })(<Input disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item
              label={
                <span>
                  CPF/CNPJ&nbsp;
                  <Tooltip title="Qual é o CPF/CNPJ?">
                    <Icon type="question-circle-o" />
                  </Tooltip>
                </span>
              }
            >
              {getFieldDecorator("cpfCnpj", {
                initialValue: this.state.data.cpfCnpj,
                rules: [
                  { required: true, message: "Informe o CPF/CNPJ", whitespace: false }
                ]
              })(<CPFInput disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item label="E-mail" ref="txtEmail">
              {getFieldDecorator("email", {
                initialValue: this.state.data.email,
                rules: [
                  {
                    type: "email",
                    message: "Email não é válido"
                  },
                  {
                    required: false,
                    message: "Informe o email"
                  }
                ]
              })(<Input disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            {this.props.editMode && (
              <Form.Item
                label={
                  <span>
                    Bloquear&nbsp;
                    <Tooltip title='Bloquear o usuário de acessar o aplicativo'>
                      <Icon type='question-circle-o' />
                    </Tooltip>
                  </span>
                }
              >
                {getFieldDecorator('bloqueado', {
                  initialValue: this.state.data.bloqueado,
                  rules: [{ required: false }],
                })(
                  <Switch
                    size='small'
                    checked={this.state.data.bloqueado}
                    onChange={this.onChangeBloquear}
                  />
                )}
              </Form.Item>
            )}
          </TabPane>

          <TabPane tab="Endereço" key="endereco">

            <Form.Item label="Telefone">
              {getFieldDecorator("telefone", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.telefone : '',
                rules: [{ required: false, message: "Informe o telefone" }]
              })(<Input style={{ width: "100%" }} disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item label="Endereço">
              {getFieldDecorator("endereco", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.logradouro : '',
                rules: [{ required: false, message: "Informe o endereço" }]
              })(<Input style={{ width: "100%" }} disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item label="Bairro">
              {getFieldDecorator("bairro", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.bairro : '',
                rules: [{ required: false, message: "Informe o bairro" }]
              })(<Input style={{ width: "50%" }} disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item label="Cidade">
              {getFieldDecorator("cidade", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.cidade : '',
                rules: [{ required: false, message: "Informe a cidade" }]
              })(<Input style={{ width: "70%" }} disabled={this.state.data.bloqueado} />)}
            </Form.Item>

            <Form.Item label="UF">
              {getFieldDecorator("uf", {
                initialValue: this.state.data.endereco ? this.state.data.endereco.uf : '',
                rules: [{ required: false, message: "Informe a UF" }]
              })(<Input style={{ width: "20%" }} disabled={this.state.data.bloqueado} />)}
            </Form.Item>
          </TabPane>
        </Tabs>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loadingButton}>
            Salvar
          </Button>

          <Divider type="vertical" />

          <Button onClick={this.cancelClick}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

class ZerarCredito extends React.Component {
  state = {
    strConfirmacao: ''
  }

  render() {
    return (
      <Modal
        title='Deseja Zerar Crédito Pré-Pago?'
        okText='Confirmar'

        visible={this.props.visible}
        centered={true}
        closable={false}
        destroyOnClose={true}

        onOk={() => this.props.handleOk(this.state.strConfirmacao)}
        onCancel={this.props.handleCancel}
      >
        <div style={{ textAlign: 'center' }}>
          <p>Caso você deseje zerar o crédito pré-pago ao inativar esse beneficiário, digite 'ZERAR CRÉDITO'. Caso o contrário, digite 'NÃO ZERAR CRÉDITO'.</p>
          <p>Não é possível reverter essa ação após confirmar e salvar as alterações.</p>

          <Input
            type='text'
            style={{ width: '50%', textAlign: 'center', textTransform: 'uppercase' }}
            onChange={(e) => this.setState({ strConfirmacao: e.target.value })}
          />
        </div>
      </Modal>
    )
  }
}

class BloquearBeneficiario extends React.Component {
  state = {
    strConfirmacao: ''
  }

  render() {
    return (
      <Modal
        title='Bloquear Beneficiário'
        okText='Confirmar'

        visible={this.props.visible}
        centered={true}
        closable={false}
        destroyOnClose={true}

        onOk={() => this.props.handleOk(this.state.strConfirmacao)}
        onCancel={this.props.handleCancel}
      >
        <div style={{ textAlign: 'center' }}>
          <p>Ao bloquear o beneficiário, ele é inativado, tem seu crédito pré-pago zerado (caso opere cartão) e é impedido de entrar no aplicativo do Regina+.</p>
          <p>Digite 'BLOQUEAR BENEFICIÁRIO' para confirmar essa ação.</p>

          <Input
            type='text'
            style={{ width: '50%', textAlign: 'center', textTransform: 'uppercase' }}
            onChange={(e) => this.setState({ strConfirmacao: e.target.value })}
          />
        </div>
      </Modal>
    )
  }
}

export default Form.create()(AdministradorBeneficiarioCRUD);
