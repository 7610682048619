import React, { Component } from "react";
import { withRouter } from 'react-router'
import { Line } from "react-chartjs-2";
import { buildChart } from "../relatorios/movimentos/Funcs-Movimentos";
import { currentUserCol, getOwnerId, isAdministrador, isDebitPresent, isLoyaltyPresent } from "../../components/auth/auth-provider";
import { Spin, Modal, Tabs, message } from 'antd';
import { currentUser } from "../../components/auth/auth-provider";
import { updatePassword } from "../perfil/Funcs-Perfil";
import { buildRecurrency } from "./HomeF";
import PageLayout from '../../components/layout/PageLayout';
import moment from "moment";
import administradorDB from "../../dataManager/dtmAdministrador";
import usuarioDB from "../../dataManager/dtmUsuario";
import convenioDB from "../../dataManager/dtmConvenio";
import AlterarSenhaCRUD from '../../components/cruds/alterarSenha/AlterarSenhaCRUD';
import BarChart from '../../components/charts/barChart';

import "chartjs-plugin-datalabels";
import './Home.css';
import GraficoFidelidade from "../../components/DashBoard/GraficoFidelidade";



const { TabPane } = Tabs;

const VERDE = '#32a852';
const LARANJA = '#ebb134';
const ROXO = '#a234eb';

class Home extends Component {

	state = {
		modules: {
			hasDebit: false,
			hasLoyalty: false,
			hasCashback: false,
		},
		loading: true,
		data: {
			labels: [],
			datasets: []
		},
		dataLoyalty: {
			title: 'Carregando...',
			labels: [],
			datasets: []
		},
		valores: {
			debito: 'Calculando...',
			credito: 'Calculando...',
			creditoPre: 'Calculando...',
		}
	}

	constructor(props) {
		super(props);
		this.buildChart = this.buildPaymentsChart.bind(this);
	}

	async componentDidMount() {
		const modules = this.state.modules;

		modules.hasDebit = await isDebitPresent();
		modules.hasLoyalty = await isLoyaltyPresent();

		this.setState({ modules });

		if (modules.hasDebit) {
			this.buildPaymentsChart();
		}

		if (modules.hasLoyalty) {
			const dataLoyalty = await buildRecurrency();
			this.setState({ dataLoyalty });
		}
	}

	async buildPaymentsChart() {
		const conveniosIds = [];
		const isAdm = await isAdministrador();

		if (isAdm) {
			const ownerId = await getOwnerId();
			const userCol = currentUserCol();
			const convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.key);

			for (let index = 0; index < convenios.length; index++) {
				const convenio = convenios[index];
				conveniosIds.push({ key: convenio.key });
			}
		}

		let iniDate = new Date();
		iniDate.setDate(iniDate.getDate() - 15);
		iniDate = new Date(iniDate.getFullYear(), iniDate.getMonth(), iniDate.getDate(), 0, 0, 0);

		let endDate = new Date();
		endDate.setDate(endDate.getDate() - 1);

		iniDate = moment(iniDate);
		endDate = moment(endDate);

		const dataSet = await buildChart(
			conveniosIds,
			iniDate,
			endDate,
			'todos',
			true
		);

		const data = {
			// labels: ["01-03", "02-03", "03-03", "04-03", "05-03", "06-03"],
			labels: [],
			datasets: [
				{
					label: "Débito",
					data: [],
					fill: true,
					backgroundColor: "#f0e3c4",
					borderColor: LARANJA,
				},
				{
					label: "Crédito",
					data: [],
					fill: false,
					hidden: true,
					borderColor: VERDE,
					backgroundColor: "#99b3a0",
				},
				{
					label: "Crédito Pré",
					data: [],
					fill: false,
					hidden: true,
					borderColor: ROXO,
					backgroundColor: "#cba9e2",
				}
			]
		};

		let valores = {
			debito: 0,
			credito: 0,
			creditoPre: 0,
		}


		dataSet.chartData.forEach((item) => {
			valores.debito += item.debito;
			valores.credito += item.credito;
			valores.creditoPre += item.creditoPre;
			data.datasets[0].data.push(item.debito);
			data.datasets[1].data.push(item.credito);
			data.datasets[2].data.push(item.creditoPre);
			const date = new Date(item.data);
			data.labels.push(date.getDate() + "/" + (date.getMonth() + 1))
		});

		valores.debito = administradorDB.formatarMoeda(valores.debito);
		valores.credito = administradorDB.formatarMoeda(valores.credito);
		valores.creditoPre = administradorDB.formatarMoeda(valores.creditoPre);

		this.setState({ data, valores, loading: false });
	}




	render() {
		const dataChart = {
			labels: this.state.dataLoyalty.labels,
			datasets: [{
				label: this.state.dataLoyalty.title,
				data: this.state.dataLoyalty.datasets,
				backgroundColor: this.state.dataLoyalty.backgroundColor,
				borderColor: this.state.dataLoyalty.borderColor,
				borderWidth: 1
			}],
		};

		return (
			<div>
				<AlterarSenha />

				<PageLayout selectItem={'dashboard'}>


					{this.state.modules.hasLoyalty && dataChart.datasets ? (
						<GraficoFidelidade></GraficoFidelidade>						

					) : ('')}






					{/* <Tabs defaultActiveKey="tabPayments" tabPosition="left">

						{this.state.modules.hasDebit ?
							<TabPane tab="Pagamentos" key="tabPayments">
								<div className="divDashLayout">
									{(this.state.loading === true) && (
										<div className="divDashSpin">
											<Spin size="large" />
										</div>
									)}

									<label className='chartTitle'>Sua rede nos últimos 15 dias</label>

									<div className="row divCardsValores">

										<div className="col-lg-3 col-sm-6">
											<div className="card-box bg-orange">
												<div className="inner">
													<h3> {this.state.valores.debito} </h3>
													<p> Débito </p>
												</div>
												<div className="icon">
													<i className="fa fa-arrow-down " aria-hidden="true"></i>
												</div>
											</div>
										</div>

										<div className="col-lg-3 col-sm-6">
											<div className="card-box bg-green">
												<div className="inner">
													<h3> {this.state.valores.credito} </h3>
													<p> Crédito </p>
												</div>
												<div className="icon">
													<i className="fa fa-arrow-up " aria-hidden="true"></i>
												</div>
											</div>
										</div>

										<div className="col-lg-3 col-sm-6">
											<div className="card-box bg-purple">
												<div className="inner">
													<h3> {this.state.valores.creditoPre} </h3>
													<p> Crédito Pré </p>
												</div>
												<div className="icon">
													<i className="fa fa-calendar" aria-hidden="true"></i>
												</div>
											</div>
										</div>
									</div>

									<div style={{
										position: "relative",
										margin: "auto",
										width: "100%",
										height: '21rem'
									}}>
										<Line className="chartDebitoCredito"
											data={this.state.data}
											//height="5px"
											options={{
												maintainAspectRatio: false,
												responsive: true,

												legend: {
													display: true,
													position: 'bottom',
												},

												tooltips: {
													callbacks: {
														label: function (tooltipItem, data) {

															let label = data.datasets[tooltipItem.datasetIndex].label;
															let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

															value = administradorDB.formatarMoeda(value);
															return ' ' + label + ': ' + value
														}
													}
												},
												scales: {
													yAxes: [
														{
															ticks: {
																beginAtZero: true,
															}

														}
													]
												},
												layout: {
													padding: {
														top: 20,
														right: 40,
														left: 40,
														bottom: 20,
													}
												},
												plugins: {
													datalabels: {
														display: true,
														anchor: "end",
														align: "top",
														formatter: function (value, context) {
															if (value === undefined) {
																return 0
															} else if (value === 0) {
																return value;
															} else {
																return value = administradorDB.formatarMoeda(value);
															}
														}
													},
												},
											}}
										/>
									</div>
								</div>
							</TabPane>
							: ''}

						{this.state.modules.hasLoyalty ?
							<TabPane tab="Fidelidade" key="tabLoyolaty">
								<BarChart
									className="chartMovimento"
									data={dataChart}

									options={{

										tooltips: {
											callbacks: {
												label: function (tooltipItem, data) {
													let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
													let fidelizacoes = 'fidelizações';
													if (parseInt(value) <= 1) {
														fidelizacoes = 'fidelização';
													};

													return ' ' + value + ' ' + fidelizacoes;
												}
											}
										},


										scales: {
											yAxes: [
												{
													ticks: {
														beginAtZero: true,
													}

												}
											]
										},
										layout: {
											padding: {
												top: 20,
												right: 40,
												left: 40,
												bottom: 20,
											}
										},
										plugins: {
											datalabels: {
												display: true,
												anchor: "end",
												align: "top",
												formatter: function (value, context) {
													if (value <= 1) {
														return ' ' + value + ' fidelização';
													} else {
														return ' ' + value + ' fidelizações';
													}
												}
											},
										}
									}
									}
								>
								</BarChart>
							</TabPane>
							: ''}

						{this.state.modules.hasCashback ?
							<TabPane tab="Cashback" key="tabCashback">
								<label className='chartTitle'>Nada aqui ainda por aqui</label>
							</TabPane>
							: ''}
					</Tabs> */}



				</PageLayout>
			</div >
		);
	}
}

export default withRouter(Home);

class AlterarSenha extends Component {
	state = {
		visible: false,
		confirmLoading: false,
		usuario: undefined
	}

	constructor(props) {
		super(props);
		this.handleOk = this.handleOk.bind(this);
	}

	async componentDidMount() {
		let visible = false;
		let usuario = undefined;

		const user = currentUser();
		usuario = await usuarioDB.getByUserId(user.uid);
		visible = !usuario || !usuario.lastPasswordUpdate;

		this.setState({ visible, usuario });
	}

	async handleOk(password) {
		const failMessage = 'Erro ao tentar salvar a nova senha. Tente novamente.'
		const isOk = await updatePassword(password);

		if (!isOk) {
			message.error(failMessage);
			return;
		}

		const usuario = this.state.usuario;
		usuario.lastPasswordUpdate = new Date();
		const res = await usuarioDB.update(usuario.key, usuario);

		if (!res.updated) {
			message.error(failMessage);
			return;
		}

		this.setState({ visible: false });
	}

	render() {
		return (
			<Modal
				title='Nova senha necessária para continuar'
				visible={this.state.visible}
				destroyOnClose={true}
				confirmLoading={this.state.confirmLoading}
				centered={true}
				onOk={this.handleOk}
				footer={null}
				closable={false}>

				<AlterarSenhaCRUD handleOk={this.handleOk}></AlterarSenhaCRUD>
			</Modal>
		)
	}
}