import React from 'react';
import { withRouter } from 'react-router'
import {
    Table,
    Button,
    Modal,
    Radio,
    Icon,
    DatePicker,
    Tooltip,
    Divider,
    message
} from 'antd';
import { currentUserCol, getOwnerId, isAdministrador, isGestor, currentUser } from '../../../components/auth/auth-provider';
import './Pedido.css';
import PageLayout from '../../../components/layout/PageLayout';
import PedidoCRUD from '../../../components/cruds/pedidos/conveniado/motivos/PedidoCRUD';
import moment from 'moment';
import IconFont from "../../../components/iconFont/IconFont";
import copy from 'copy-to-clipboard';
import OpenNewPage from 'open-new-page';
import GerenciaNet from '../../../components/gerenciaNet/gerencia-net-provider';
import history from '../../../history';
import mensagem from '../../../components/messages/message'
import convenioDB from '../../../dataManager/dtmConvenio';
import pedidoDB from '../../../dataManager/dtmPedido';
import vertteStorage from '../../../components/localstorage/localstorage';
import configuracaoDB from '../../../dataManager/dtmConfiguracao';
import API from '../../../components/api/api';
import jsPDF from "jspdf";
import Pusher from 'pusher-js';
import "jspdf-autotable";

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;
const MARGIN_LEFT = 40;
const api = new API();
const user = currentUser();
const pusher = new Pusher('779a79971a1347f6f8e0', { cluster: 'us2' });
const channel = pusher.subscribe(user ? user.uid : '');

class Pedido extends React.Component {
    state = {
        isGestor: false,
        convenios: [],
        pedidos: [],
        loading: true,
        dataMine: [],
        showBoleto: false,
        readOnly: false,
        boleto: {},
        permissoes: {
            naoCadastrar: false,
            naoCancelar: false
        }
    }

    constructor(props) {
        super(props);
        this.headerPedidoElement = React.createRef();
        this.updateList = this.updateList.bind(this);
        this.cancelarPedidoElement = this.cancelarPedidoElement.bind(this);
        this.imprimirPedidoElement = this.imprimirPedidoElement.bind(this);
        this.showBoletoElement = this.showBoletoElement.bind(this);
        this.copyDigLine = this.copyDigLine.bind(this);
        this.openBillet = this.openBillet.bind(this);
        this.verPedido = this.verPedido.bind(this);
        this.cancelarBoleto = this.cancelarBoleto.bind(this);
        this.reprocessarPedido = this.reprocessarPedido.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();

        let naoCadastrar = false;
        let naoCancelar = false;

        // Se o tipo do usuário for administrador, não preciso verificar se ele tem
        // Permissão ou não para acessar a página. Ele tem!
        if (!await isAdministrador() && userCol) {
            const isOk = userCol.permissoes.some(function (v) { return v.indexOf("pedidos") >= 0 });
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }

            naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("pedidos-cadastrar") >= 0 });
            if (naoCadastrar) {
                naoCadastrar = userCol.permissoes.indexOf('pedidos') === -1;
            }
            naoCancelar = !userCol.permissoes.some(function (v) { return v.indexOf("pedidos-cancelar") >= 0 });
            if (naoCancelar) {
                naoCancelar = userCol.permissoes.indexOf('pedidos') === -1;
            }
        }

        const ownerId = await getOwnerId();

        // Verifico se o usuário já cadastrou as chaves da Gerencianet:
        const gerenciaNetOk = vertteStorage.get('gerenciaNetOk');

        if (!gerenciaNetOk) {
            const configuracao = await configuracaoDB.getByOwnerId(ownerId);
            if (!configuracao) {
                await mensagem.avisar(`Antes de fazer seus pedidos, você deverá 
                informar as chaves da Gerencianet na página 
                de configurações.`);
                history.push('/configuracoes');
                history.go();
                return;
            }
            vertteStorage.set('gerenciaNetOk', true);
        }

        let convenios = undefined;
        if (await isAdministrador()) {
            convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.key);
        } else if (await isGestor()) {
            convenios = await convenioDB.getByOwnerIdAndAtivo(ownerId, true);
        } else {
            convenios = await convenioDB.getByOwnerIdAndAdminId(ownerId, userCol.administrador.id);
        }

        this.setState({
            convenios,
            isGestor: await isGestor(),
            permissoes: { naoCadastrar, naoCancelar },
            loading: false
        });

        channel.bind('reprocess-charge-concluded', (data) => {
            let { pedidos } = this.state;
            const key = data.key;
            const dataCode = data.code === 201;

            pedidos = pedidos.map((item) => {
                if (item.key === key) {
                    item.reprocess = dataCode;
                    item.creditado = dataCode;
                }
                return item;
            })

            this.setState({ pedidos });
        })
    }

    updateList(record) {
        let lista = this.state.pedidos;
        let registroEncontrado = false;
        this.state.pedidos.forEach((item, index) => {
            if (item.key === record.key) {
                const dataIni = new Date(record.dataInicial).toLocaleDateString().replaceAll('/', '-');
                const dataFim = new Date(record.dataFinal).toLocaleDateString().replaceAll('/', '-');
                record.periodo = `${dataIni} até ${dataFim}`;
                record.podeCancelar = item.podeCancelar;
                record.dataCreditoStr = new Date(record.dataCredito).toLocaleDateString().replaceAll('/', '-');
                lista[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            const dataIni = new Date(record.dataInicial).toLocaleDateString().replaceAll('/', '-');
            const dataFim = new Date(record.dataFinal).toLocaleDateString().replaceAll('/', '-');
            record.periodo = `${dataIni} até ${dataFim}`;
            record.podeCancelar = record.status !== 'Pago';
            record.dataCreditoStr = new Date(record.dataCredito).toLocaleDateString().replaceAll('/', '-');
            lista.push(record);
        }
        this.setState({ pedidos: lista });
    }

    formatDate(date) {
        date = new Date(date);
        return (("0" + (date.getDate())).slice(-2) + "-" + ("0" + (date.getMonth() + 1)).slice(-2)) + "-" + date.getFullYear();
    }

    async getPedidos(status, dataInicial, dataFinal) {
        this.setState({ loading: true });

        const dataIni = dataInicial.toISOString().slice(0, 10) + ' 00:00:00';
        const dataFim = dataFinal.toISOString().slice(0, 10) + ' 23:59:59';

        let pedidos = undefined;
        const ownerId = await getOwnerId();
        const userCol = currentUserCol();

        if (await isAdministrador()) {
            console.clear();
            pedidos = await pedidoDB.getByOwnerIdAndAdministradorIdAndPeriodo(ownerId, userCol.key, dataIni, dataFim, status);
        } else if (await isGestor()) {
            pedidos = await pedidoDB.getByOwnerIdAndPeriodo(ownerId, dataIni, dataFim, status);
        } else {
            pedidos = await pedidoDB.getByOwnerIdAndAdministradorIdAndPeriodo(ownerId, userCol.administrador.id, dataIni, dataFim, status);
        }

        this.setState({ pedidos, loading: false });
    }

    handleCancel = () => {
        this.setState({ visible: false, showBoleto: false });
    };

    async cancelarPedidoElement(record) {
        if (((record.status !== 'Pendente' && record.status !== 'Rascunho') || !record.podeCancelar) || this.state.permissoes.naoCancelar) return;

        const convenio = await convenioDB.getById(record.convenio.id);
        if (record.boleto) {
            if (record.boleto.status === 'unpaid') {
                message.error('Você não tem permissão para cancelar um pedido em atraso. Entre em contato com seu conveniador.');
                return;
            }

            if (record.boleto.status !== 'waiting') {
                // Quando pedido for diferente de waiting, verifico se
                // o convênio é do tipo que pode gerar pedido sem cobranças.
                // Caso sim, rejeito o pedido de cancelamento, senão, sigo em frente
                // neste ponto            
                if (convenio.gerarCobranca === true) {
                    message.error('Pedido já foi processado e não pode mais ser cancelado.');
                    return;
                }
            }
        }
        const isOk = await mensagem.confirmar('Deseja realmente cancelar este pedido? Essa operação não poderá ser desfeita.');
        if (!isOk) {
            return;
        }

        this.setState({ loading: true });

        if (!convenio.gerarCobranca) {
            let boleto = record.boleto;
            if (!boleto) {
                boleto = {

                }
            }

            boleto.status = 'canceled';
            const pedido = await pedidoDB.getById(record.key);
            pedido.boleto = boleto;
            pedido.podeCancelar = false;
            pedidoDB.update(record.key, pedido);
            record.status = 'Cancelado';
            record.podeCancelar = false;
            mensagem.openNotificationWithIcon('success', 'Pedido Cancelado', 'Seu pedido foi cancelado com sucesso');
            this.setState({ loading: false });
        }
        else {
            if (record.boleto) {
                const isOk = await this.cancelarBoleto(record);
                if (!isOk) {
                    return;
                }
            }

            let boleto = record.boleto;
            if (!boleto) {
                boleto = {

                }
            }

            boleto.status = 'canceled';
            const pedido = await pedidoDB.getById(record.key);
            pedido.boleto = boleto;
            pedidoDB.update(record.key, pedido);
            record.status = 'Cancelado';
            record.podeCancelar = false;
            mensagem.openNotificationWithIcon('success', `${record.rascunho ? 'Rascunho Cancelado' : 'Pedido Cancelado'}`, `Seu ${record.rascunho ? 'rascunho' : 'pedido'} foi cancelado com sucesso`);
            this.setState({ loading: false });
        }

        this.headerPedidoElement.current.onFilterClick();
    }

    async reprocessarPedido(record) {
        const isOk = await mensagem.confirmar('Deseja reprocessar esse pedido?');
        if (isOk) {
            mensagem.openNotificationWithIcon('success', 'Reprocessando', `Reprocessando Pedido ${record.boleto.charge_id}`);

            let { pedidos } = this.state;
            pedidos = pedidos.map((item) => {
                if (item.key === record.key) {
                    item.reprocess = false;
                }
                return item;
            })

            this.setState({ pedidos });

            const { token } = await api.getToken(record.convenio.id);
            const { uid } = currentUser();
            await api.reprocessCharge(token, uid, record.boleto.charge_id)
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    printText(doc, text, space) {
        doc.text(text, MARGIN_LEFT, space);
        return space + 20;
    }

    async imprimirPedidoElement(record) {
        console.log(record);

        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "landscape"; // portrait or landscape


        const doc = new jsPDF(orientation, unit, size);

        doc.setFontSize(15);

        const title = `Período do Pedido: ${record.periodo}`;
        let space = 50;
        space = this.printText(doc, title, space);
        space = this.printText(doc, `Convênio: ${record.convenio.nome}`, space);

        space = this.printText(doc, `Crédito em: ${record.dataCredito.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).replace(' 00:00:00', '')}`, space);

        space = this.printText(doc, `Número: ${record.boleto ? record.boleto.charge_id : '--'}`, space);

        space = this.printText(doc, `Vencimento: ${record.boleto && record.boleto.expire_at ? new Date(record.boleto.expire_at).toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).split(' ')[0] : '--'}`, space);

        const dataCompetencia = record.boleto && record.boleto.expire_at ? new Date(record.boleto.expire_at) : record.dataCredito;
        const competencia = dataCompetencia.toLocaleString("pt-BR", {
            timeZone: "America/Sao_Paulo"
        }).split(' ')[0].split('/');

        space = this.printText(doc, `Competência: ${competencia[1]}/${competencia[2]}`, space)

        space = this.printText(doc, `Beneficiados: ${record.totalBeneficiarios}`, space);
        space = this.printText(doc, `Valor Total: ${record.valorTotal}`, space);
        space = this.printText(doc, `Status: ${record.status}`, space);

        const source = [];
        const headers = [['Beneficiário', 'Valor']];

        const beneficiarios = await pedidoDB.getBeneficiariosDoPedido(record.key);

        for (let index = 0; index < beneficiarios.length; index++) {
            const beneficiario = beneficiarios[index];
            const item = {
                nome: `${beneficiario.nome.trim()} ${beneficiario.sobrenome.trim()}`,
                valor: (typeof beneficiario.valor === 'string') ? `R$ ${beneficiario.valor}` : pedidoDB.formatarMoeda(beneficiario.valor)
            }
            source.push(item);
        }

        const data = source.map(elt => [elt.nome, elt.valor]);

        let content = {
            startY: space,
            head: headers,
            body: data
        };

        doc.autoTable(content);
        doc.save("pedido.pdf")
    }

    async showBoletoElement(record) {
        if (record.status !== 'Pendente') return;
        this.setState({ showBoleto: true, boleto: record.boleto, readOnly: false });
    }

    async verPedido(record) {
        if (this.state.permissoes.naoCancelar) return;
        this.headerPedidoElement.current.editarPedido(record);
    }

    copyDigLine() {
        copy(this.state.boleto.barcode);
        message.success('Linha digitável copiada com sucesso');
    }

    openBillet() {
        OpenNewPage({
            url: this.state.boleto.billet_link,
            method: 'GET'
        });
    }

    async cancelarBoleto(record) {
        const gerencia = new GerenciaNet();
        let data = await gerencia.getToken(record.convenio.id);

        if (!data.token) {
            mensagem.openNotificationWithIcon('error', 'Erro', 'Houve um problema ao tentar cancelar o boleto. Tente novamente');
            this.setState({ loading: false });
            return false;
        }

        try {
            gerencia.cancelCharge(record.boleto.charge_id, data.token);
            return true;
        } catch (error) {
            debugger;
            console.log(error);
            this.setState({ loading: false });
            mensagem.openNotificationWithIcon('error', 'Erro', 'Houve um problema ao tentar cancelar o boleto. Tente novamente');
            return false;
        }
    }

    render() {
        const columns = [
            {
                title: 'Período',
                dataIndex: 'periodo',
                key: 'periodo',
                //width: 150,
            },
            {
                title: 'Crédito',
                dataIndex: 'dataCreditoStr',
                key: 'dataCreditoStr',
                //width: 150,
            },
            {
                title: 'Número',
                dataIndex: 'boleto.charge_id',
                key: 'boleto.charge_id',
                align: 'right'
            },
            {
                title: 'Convênio',
                dataIndex: 'convenio.nome',
                key: 'convenio.nome',
            },
            {
                title: 'Vencimento',
                dataIndex: 'boleto.expire_at',
                key: 'expire_at',
            },
            {
                title: 'Valor',
                dataIndex: 'valorTotal',
                key: 'valorTotal',
                align: 'right'
            },
            {
                title: 'Total benef',
                dataIndex: 'totalBeneficiarios',
                key: 'totalBeneficiarios',
                align: 'right'
            },
            {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
            },
            {
                title: '',
                width: 180,
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Ver Pedido">
                            <Icon
                                type="edit"
                                onClick={() => this.verPedido(record)}
                            />
                        </Tooltip>

                        <Divider type="vertical"></Divider>

                        <Tooltip placement="topLeft" title="Ver Boleto">
                            <Icon
                                type="eye"
                                onClick={() => this.showBoletoElement(record)} />
                        </Tooltip>

                        <Divider type="vertical"></Divider>

                        <Tooltip placement="topLeft" title="Cancelar Pedido">
                            <Icon
                                type="stop"
                                onClick={() => this.cancelarPedidoElement(record)} />
                        </Tooltip>

                        <Divider type="vertical"></Divider>

                        {this.state.isGestor && !record.creditado && !record.rascunho && (
                            <Tooltip
                                placement='topLeft'
                                title='Reprocessar Pedido'
                            >
                                <Icon
                                    type='sync'
                                    onClick={() => this.reprocessarPedido(record)}
                                    className={record.reprocess !== undefined && !record.reprocess ? 'rotate' : ''}
                                />
                            </Tooltip>
                        )}

                        {this.state.isGestor && !record.creditado && !record.rascunho && (
                            <Divider type="vertical"></Divider>
                        )}

                        <Tooltip placement="topLeft" title="Salvar como PDF">
                            <Icon
                                type="file-pdf"
                                onClick={() => this.imprimirPedidoElement(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            },
        ];

        return (
            <div className="divTable">
                <Modal
                    title="Seu boleto"
                    visible={this.state.showBoleto}
                    onCancel={this.handleCancel}
                    footer={null}>
                    <p><strong>Linha digitável</strong></p>
                    <p>{this.state.boleto.barcode}
                        <Button
                            className="btnCopyDigLine"
                            type="primary"
                            onClick={this.copyDigLine}>
                            <IconFont type={"copy"} size={14}></IconFont>
                        </Button>

                        <Button
                            className="btnOpenBillet"
                            onClick={this.openBillet}>
                            <IconFont type={"external-link"} size={14}></IconFont>
                        </Button>
                    </p>
                </Modal>

                <PageLayout selectItem={'convenio-pedidos'}>
                    <Table
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        title={() =>
                            <HeaderPedido
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                naoCancelar={this.state.permissoes.naoCancelar}
                                convenios={this.state.convenios}
                                ref={this.headerPedidoElement}
                                updateList={this.updateList}
                                thisParent={this}
                            />
                        }
                        columns={columns}
                        dataSource={this.state.pedidos}
                        loading={this.state.loading}
                        bordered
                    />
                </PageLayout>
            </div>
        );
    }
}

class HeaderPedido extends React.Component {

    state = {
        visible: false,
        status: 'waiting',
        periodo: {
            inicial: moment().subtract(7, 'days'),
            final: moment(),
        },
    }
    constructor(props) {
        super(props);
        this.novoPedidoElement = React.createRef();
        this.novoPedido = this.novoPedido.bind(this);
        this.editarPedido = this.editarPedido.bind(this);
        this.onFiltroChange = this.onFiltroChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
    }

    novoPedido() {
        const record = [];
        record['convenios'] = this.props.convenios;
        this.novoPedidoElement.current.show(false, record);
    }

    editarPedido(record) {
        record['convenios'] = this.props.convenios;
        this.novoPedidoElement.current.show(true, record);
    }

    onFiltroChange(e) {
        this.setState({ status: e.target.value });
    }

    onFilterClick() {
        this.props.thisParent.getPedidos(
            this.state.status,
            this.state.periodo.inicial._d,
            this.state.periodo.final._d
        );
    }

    onDateChange(value) {
        this.setState({
            periodo: {
                inicial: value[0],
                final: value[1],
            }
        });
    }

    render() {
        return (
            <div>
                <NovoPedido
                    ref={this.novoPedidoElement}
                    updateList={this.props.updateList}
                    dataMine={this.props.thisParent.state.dataMine}
                />

                <Button className="btnNewRecord" type="primary" onClick={this.novoPedido}><Icon className="icon" type="plus" /> Novo Pedido</Button>
                <div className="filtroPedidosConveniado">
                    <Radio.Group onChange={this.onFiltroChange} defaultValue={this.state.status}>
                        <Radio.Button value="waiting">Pendentes</Radio.Button>
                        <Radio.Button value="draft">Rascunhos</Radio.Button>
                        <Radio.Button value="paid">Pagos</Radio.Button>
                        <Radio.Button value="rejeitado">Rejeitados</Radio.Button>
                        <Radio.Button value="canceled">Cancelados</Radio.Button>
                        <Radio.Button value="todos">Todos</Radio.Button>
                    </Radio.Group>
                    &nbsp;
                    <RangePicker
                        className="dateTimePedidosConveniado"
                        format={dateFormat}
                        placeholder={['Data Inicial', 'Data Final']}
                        onChange={this.onDateChange}
                        defaultValue={[this.state.periodo.inicial, this.state.periodo.final]}
                    />
                    &nbsp;
                    <Button type='primary' onClick={this.onFilterClick}>Filtrar</Button>
                </div>
            </div>
        );
    }
}

class NovoPedido extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        canClose: true,
        record: [],
        dataMine: [],
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleCanClose = this.handleCanClose.bind(this);
    }

    show(editMode, record) {
        if (!record) {
            record = [];
        }

        let statusPedido = '';
        if (record.boleto) {
            if (record.boleto.status === 'waiting') {
                statusPedido = 'Aguardando Pagamento';
            } else if (record.boleto.status === 'paid') {
                statusPedido = 'Pago';
            } else if (record.boleto.status === 'canceled') {
                statusPedido = 'Cancelado';
            }
        }

        let tipoCadastro = 'Novo Pedido';
        if (editMode && record.rascunho && !record.boleto) {
            tipoCadastro = `Editando Rascunho`;
        } else if (editMode && record.rascunho && record.boleto) {
            tipoCadastro = `Visualizando Rascunho - ${statusPedido}`;
        } else if (editMode && !record.rascunho) {
            tipoCadastro = `Visualizando Pedido - ${statusPedido}`;
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record,
            tipoCadastro,
        });
    }

    handleOk(record) {
        this.setState({ visible: false, canClose: true });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false, canClose: true });
    }

    handleCanClose(canClose) {
        this.setState({ canClose });
    }

    render() {
        return (
            <Modal
                title={this.state.tipoCadastro}
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}
                closable={this.state.canClose}
                onCancel={this.handleCancel}
                maskClosable={false}
                width={650}
            >
                <PedidoCRUD
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    handleCanClose={this.handleCanClose}
                    editMode={this.state.editMode}
                    record={this.state.record}
                    dataMine={this.props.dataMine}
                />

            </Modal>
        );
    }
}

export default withRouter(Pedido);