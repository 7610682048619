import { Component } from 'react';
import { fbDatabase } from '../firebase/firebase';

import {
  FIREBASE_CACHE_UPDATE,
  H_01_HORA
} from '../components/funcs/constants';

import vertteStorage from '../components/localstorage/localstorage';


class PrivateMethods extends Component {
  async getByParamValue(collection, param, value) {
    const snapshot = await fbDatabase
      .collection(collection)
      .where(param, '==', value)
      .get();

    if (snapshot.empty === true) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }
}

class UpdateCache extends Component {
  state = {}

  execute(ENDPOINT) {
    const CACHE_ADDRESS = FIREBASE_CACHE_UPDATE + ENDPOINT;

    if (!vertteStorage.getWithExpiry(CACHE_ADDRESS)) {
      vertteStorage.setWithExpiry(
        CACHE_ADDRESS,
        true,
        H_01_HORA * 3
      );

      this.state[ENDPOINT] = 'server';
    }

    return this.state[ENDPOINT] ?? 'cache';
  }
}

const updateCache = new UpdateCache();

export default class DataManager extends Component {
  state = {
    collection: '',
    orderBy: [],
  };

  formatarMoeda(valor) {
    valor = parseFloat(valor).toLocaleString('pt-br', {
      style: 'currency',
      currency: 'BRL',
    });
    return valor;
  }

  async getById(id, fromCache, update) {
    const query = fbDatabase
      .collection(this.state.collection)
      .doc(id);

    const snapshot = await this.getQueryData(query, fromCache, update);

    if (!snapshot.exists) {
      return undefined;
    }

    return {
      key: snapshot.id,
      ...snapshot.data(),
    };
  }

  async getByOwnerId(id, fromCache, update) {
    let query = fbDatabase
      .collection(this.state.collection)
      .where('ownerId', '==', id);

    if (this.state.orderBy.length > 0) {
      for (let index = 0; index < this.state.orderBy.length; index++) {
        const order = this.state.orderBy[index];
        query = query.orderBy(order);
      }
    }

    const snapshot = await this.getQueryData(query, fromCache, update);

    if (snapshot.empty) {
      return undefined;
    }

    const data = snapshot.docs.map((item) => ({
      key: item.id,
      ...item.data(),
    }));
    return data;
  }

  async add(item) {
    return new Promise(async (resolve, reject) => {
      await fbDatabase
        .collection(this.state.collection)
        .add({
          ...item,
        })
        .then((res) => {
          resolve({
            recorded: true,
            id: res.id,
          });
        })
        .catch((err) => {
          debugger;
          console.log(err);
          reject({
            recorded: false,
            err,
          });
        });
    });
  }

  async update(key, item) {
    return new Promise((resolve, reject) => {
      fbDatabase
        .collection(this.state.collection)
        .doc(key)
        .update({
          ...item,
        })
        .then((res) => {
          resolve({
            updated: true,
            message: 'Ok',
          });
        })
        .catch((err) => {
          console.log(err);
          reject({
            updated: false,
            message: 'Erro',
            err,
          });
        });
    });
  }

  async getByParamValue(param, value) {
    const privateMethods = new PrivateMethods();
    const data = await privateMethods.getByParamValue(
      this.state.collection,
      param,
      value
    );
    return data;
  }

  async getQueryData(query, fromCache, update) {
    let source = fromCache ? 'cache' : 'server';

    if (fromCache && update) {
      source = updateCache.execute(update);
    }

    let snapshot = await query.get({ source });

    if (snapshot.empty && fromCache) {
      snapshot = await query.get();
    }

    return snapshot;
  }
}
