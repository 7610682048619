import { getOwnerId } from '../../components/auth/auth-provider';
import beneficiarioDB from '../../dataManager/dtmBeneficiario';
import convenioDB from '../../dataManager/dtmConvenio';
import movimentoDB from '../../dataManager/dtmMovimentos';

export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId, true, true);
  return convenios
}

export async function getBeneficiarios(convenioId) {
  const ownerId = await getOwnerId();
  const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, convenioId, true, true)
  return beneficiarios;
}

export async function getLastPurchase(beneficiarioId) {
  const lastPurchase = await movimentoDB.getLastPurchase(beneficiarioId);
  return lastPurchase;
}