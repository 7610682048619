import React from 'react';
import { Bar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";


class BarChart extends React.Component{

    state = {
        options: this.props.options,
        data: this.props.data,
    }

    componentDidUpdate(){
        if(this.state.options !== this.props.options || this.state.data !== this.props.data){
            this.setState({
                options: this.props.options,
                data: this.props.data,
            })
        }
    }

    render(){
        return(
            <div>
                <Bar
                    data={this.props.data}
                    options={this.props.options}
                >
                </Bar>
            </div>
        )
    }

}

export default BarChart;