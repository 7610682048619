import {
  Component
} from 'react';
import {
  API_ROUTER,
} from '../../firebase/firebase';


async function _getToken(key) {

  return new Promise((resolve, reject) => {
    const url = `${API_ROUTER}token/convenio/byid/get/${key}`;
    fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => response.json())
      .then((responseData) => {
        if (responseData.code === 200) {
          resolve(responseData);
        } else {
          reject(responseData);
        }
      })
      .catch(err => {
        console.log(err);
        reject({});
      })
  });
}

export default class API extends Component {

  async getToken(convenioId) {
    const token = await _getToken(convenioId);
    return token;
  }

  paymentGenerate(token, key, pdvId, userName, valueInCents) {
    return new Promise((resolve, reject) => {
      const url = `${API_ROUTER}payment/generate`;
      fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          key,
          'pdvid': pdvId,
          'username': userName,
          'valorEmCentavos': valueInCents
        },
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  pay(token, authorization, beneficiarioId, valueInCents, date) {
    return new Promise((resolve, reject) => {
      const url = `${API_ROUTER}debit/capture`;

      const body = JSON.stringify({
        valorEmCentavos: valueInCents,
        autorizacaoPagamento: authorization,
        beneficiarioId,
        data: date.toLocaleString(),
      });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`,
          'viasite': true,
        },
        body,
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  reprocessCharge(token, userId, chargeId) {
    return new Promise((resolve, reject) => {
      const url = `${API_ROUTER}reprocess/${userId}/${chargeId}`;

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`
        }
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 201) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }

  resetCredit(token, beneficiarioId) {
    return new Promise((resolve, reject) => {
      const url = `${API_ROUTER}resetCredit`;

      const body = JSON.stringify({ beneficiarioId });

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `bearer ${token}`
        },
        body
      }).then((response) => response.json())
        .then((responseData) => {
          if (responseData.code === 200) {
            resolve(responseData);
          } else {
            reject(responseData);
          }
        })
        .catch(err => {
          console.log(err);
          reject({});
        });
    });
  }
}