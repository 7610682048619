import React from 'react';
import {
  Form,
  Input,
  Tooltip,
  Icon,
  Button,
  Divider,
  Tag,
} from "antd";
import mensagem from '../../messages/message';
import { getOwnerId } from '../../auth/auth-provider';
import cartaoDB from '../../../dataManager/dtmCartao';



class CartaoCRUD extends React.Component {
  state = {
    loading: true,
    cartao: {
      numero: '',
      password: '',
      status: '',
      validAt: '',
    }
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
  }

  async componentDidMount() {
    const ownerId = await getOwnerId();
    let cartao = {};

    if (this.props.record.cartao) {
      cartao = await cartaoDB.getCartaoByNumeroAndOwnerId(this.props.record.cartao.numero, ownerId);
    }

    if (cartao && cartao.length > 0) {
      cartao = cartao[0];
    }

    this.setState({ cartao, loading: false });
  }

  handleSubmit = async e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll(async (err, values) => {
      if (err) {
        return;
      }


      let isOk = await mensagem.confirmar('Confirma resetar a senha para este cartão?');
      if (!isOk) {
        return;
      }
      debugger;

      await cartaoDB.update(this.state.cartao.key, { senhaPinPad: '' });
      mensagem.openNotificationWithIcon('success', 'Perfeito!', `A senha do cartão foi alterada com sucesso`, 3);
      this.props.handleOk();
      this.setState({ loading: false });
    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };


  cancelClick() {
    this.props.handleCancel();
  }

  selectHandleChange(value) {
    this.setState({ selecteds: value });
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      }
    };

    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 16,
          offset: 8
        }
      }
    };

    return (
      <Form {...formItemLayout} onSubmit={this.handleSubmit}>

        <Form.Item
          style={{ disabled: true }}
          label={
            <span>
              Cartão&nbsp;
              <Tooltip title={`Cartão do beneficiário`}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('cartao', {
            initialValue: this.state.cartao.numero,
            rules: [{ disabled: true, required: false, message: `Número atual do cartão do beneficiário` }],
          })(

            <Input disabled={true} style={{ width: '52%' }} />

          )}
        </Form.Item>

        <Form.Item
          label={
            <span>
              Válido até&nbsp;
              <Tooltip title={`Validade do cartão do beneficiário`}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('validAt', {
            initialValue: this.state.cartao.validoAteBr,
            rules: [{ disabled: true, required: false, message: `Validade do cartão do beneficiário` }],
          })(

            <Input disabled={true} style={{ width: '52%' }} />

          )}
        </Form.Item>

        <Form.Item
          label={
            <span>
              Status&nbsp;
              <Tooltip title={`Status do cartão do beneficiário`}>
                <Icon type="question-circle-o" />
              </Tooltip>
            </span>
          }
        >
          {getFieldDecorator('status', {
            initialValue: this.state.cartao.status,
            rules: [{ disabled: true, required: false, message: `Status do cartão do beneficiário` }],
          })(

            <Tag color="red">{this.state.cartao.status}</Tag>
            // <Label disabled={true} style={{ width: '52%' }}></Label>

          )}
        </Form.Item>

        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={this.state.loading}>
            Salvar
          </Button>
          <Divider type="vertical" />
          <Button onClick={this.cancelClick}>Cancelar</Button>
        </Form.Item>
      </Form>
    );
  }
}

export default Form.create()(CartaoCRUD);
