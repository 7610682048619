import DataManager from './dtm';


class ConfiguracaoDB extends DataManager {

    state = {
        collection: 'configuracoes',
        orderBy: [], // usado na classe pai
    }

}

const configuracaoDB = new ConfiguracaoDB();
export default configuracaoDB;