import React from 'react';
import { withRouter } from 'react-router'
import { Table, Button, Icon, Modal, Input, Tooltip } from 'antd';
import {
    getOwnerId,
    getUserType,
    currentUserCol,
    isAdministrador,
    isGestor,
    currentUser,
    isCashbackPresent,
    isLoyaltyPresent
} from '../../components/auth/auth-provider';

import AdministradorCRUD from '../../components/cruds/administradores/AdministradorCRUD';
import PageLayout from '../../components/layout/PageLayout';
import history from '../../history';
import mensagem from '../../components/messages/message';
import administradorDB from '../../dataManager/dtmAdministrador';
import administradorUsuarioDB from '../../dataManager/dtmAdministradorUsuario';
import listObj from '../../components/listSearch/listSearch';
import vertteStorage from '../../components/localstorage/localstorage';

import './Administrador.css';

const { Search } = Input;


class Administrador extends React.Component {
    state = {
        administradores: [],
        administradoresOriginal: [],
        isSearching: false,
        loading: true,
        userType: 'Gestor',
        cashbackPresent: undefined,
        loyaltyPresent: undefined,
        menu: '',
        permissoes: {
            naoCadastrar: true,
            naoEditar: true
        }
    }

    constructor(props) {
        super(props);
        this.headerUsuarioElement = React.createRef();
        this.updateList = this.updateList.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
    }

    async UNSAFE_componentWillMount() {
        const convenio = this.props.location.state ? JSON.parse(this.props.location.state) : undefined;
        let menu = convenio ? 'convenios' : 'administradores';
        this.setState({ menu });
    }

    async componentDidMount() {
        const userType = await getUserType();
        const gestorId = await getOwnerId();

        // Se o usuário for do tipo administrador, as duas variáveis têm que ficar 
        // positivas, pois eles só poderão visualizar seus dados
        let naoCadastrar = this.state.userType.indexOf('Administrador') !== -1;
        let naoEditar = this.state.userType.indexOf('Administrador') !== -1;

        // Se o tipo do usuário for administrador, não preciso verificar se ele tem
        // Permissão ou não para acessar a página. Ele tem!
        const userCol = this.state.userType !== 'Administrador' ? currentUserCol() : undefined;
        if (userCol) {
            const isOk = userCol.permissoes && userCol.permissoes.some(function (v) { return v.indexOf("administradores") >= 0 });
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }

            // O teste não vale para usuário do tipo AdministradorUsuario
            // Pois estes não podem nunca editar ou cadastrar novos administradores
            if (this.state.userType !== 'AdministradorUsuario') {
                naoCadastrar = !userCol.permissoes.some(function (v) { return v.indexOf("administradores-cadastrar") >= 0 });
                if (naoCadastrar) {
                    naoCadastrar = userCol.permissoes.indexOf('administradores') === -1;
                }
                naoEditar = !userCol.permissoes.some(function (v) { return v.indexOf("administradores-editar") >= 0 });
                if (naoEditar) {
                    naoEditar = userCol.permissoes.indexOf('administradores') === -1;
                }
            }
        }

        let administradores = undefined;

        if (await isGestor()) {
            administradores = await administradorDB.getByOwnerIdCustom(gestorId, true, true);
        } else if (await isAdministrador()) {
            const user = await currentUser();
            administradores = await administradorDB.getByEmail(user.email);
        } else {
            const user = await currentUser();
            let admUser = await administradorUsuarioDB.getByEmail(user.email);

            admUser = await administradorDB.getById(admUser[0].administrador.id);
            administradores = [];
            administradores.push(admUser);
        }

        const cashbackPresent = await isCashbackPresent();
        const loyaltyPresent = await isLoyaltyPresent();

        this.setState({
            userType,
            administradores,
            cashbackPresent,
            loyaltyPresent,
            administradoresOriginal: administradores,
            permissoes: { naoCadastrar, naoEditar },
            loading: false
        });
    }

    async applyFilter(text) {
        const { administradoresOriginal } = this.state;

        if (text === '') {
            this.setState({
                administradores: this.state.administradoresOriginal,
                isSearching: false
            });

            return;
        };

        const keys = [
            'cnpj',
            'razaoSocial',
            'nomeFantasia',
            'email',
            'endereco.telefone',
            'ativoStr'
        ];

        const administradores = listObj.search(administradoresOriginal, text, keys);

        this.setState({
            administradores,
            isSearching: true
        });
    }

    updateList(record) {
        record['ativo'] = record['ativo'] ? 'Sim' : 'Não';
        record['cashbackStr'] = record.premiacao && record.premiacao.cashback ? `${record.premiacao.cashback.valor}%` : '';
        record['fidelidadeStr'] = record.premiacao && record.premiacao.fidelidade ? `${record.premiacao.fidelidade.premios[0].pontos} pts` : '';

        let administradores = this.state.administradores;
        let registroEncontrado = false;

        this.state.administradores.forEach((item, index) => {
            if (item.key === record.key) {
                administradores[index] = record;
                registroEncontrado = true;
            }
        });

        if (!registroEncontrado) {
            administradores.push(record);
        }

        if (this.state.isSearching) {
            let administradoresOriginal = this.state.administradoresOriginal;
            registroEncontrado = false;

            this.state.administradoresOriginal.forEach((item, index) => {
                if (item.key === record.key) {
                    administradoresOriginal[index] = record;
                    registroEncontrado = true;
                }
            });

            if (!registroEncontrado) {
                administradoresOriginal.push(record);
            }

            this.setState({ administradores, administradoresOriginal });
        } else {
            this.setState({ administradores, administradoresOriginal: administradores });
        }

        vertteStorage.clear('@firebase-cache/update/administradorDB/getByOwnerIdCustom');
    }


    handleCancel = () => {
        this.setState({ visible: false });
    };

    editarUsuarioElement(record) {
        if (this.state.permissoes.naoEditar) return;
        this.headerUsuarioElement.current.editarUsuario(record);
    }

    render() {
        let columns = [
            {
                title: 'Razão Social',
                dataIndex: 'razaoSocial',
                key: 'razaoSocial',
                editable: false,
            },
            {
                title: 'Nome Fantasia',
                dataIndex: 'nomeFantasia',
                key: 'nomeFantasia',
            },
            {
                title: 'CNPJ',
                dataIndex: 'cnpj',
                key: 'cnpj',
            },
            {
                title: 'E-mail',
                dataIndex: 'email',
                key: 'email',
            },
            {
                title: 'Telefone',
                dataIndex: 'endereco.telefone',
                key: 'telefone',
            },
            {
                title: "Ativo?",
                dataIndex: "ativoStr",
                key: "ativoStr"
            },
        ];

        if (this.state.cashbackPresent) {
            columns.push({
                title: 'Cashback',
                dataIndex: 'cashbackStr',
                key: 'cashbackStr',
                align: 'right'
            });
        }

        if (this.state.loyaltyPresent) {
            columns.push({
                title: 'Fidelidade (min)',
                dataIndex: 'fidelidadeStr',
                key: 'fidelidadeStr',
                align: 'right'
            });
        }


        if (!this.state.permissoes.naoEditar) {
            columns.push({
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Editar Cadastro">
                            <Icon
                                type="edit"
                                onClick={() => this.editarUsuarioElement(record)}
                            />
                        </Tooltip>
                    </span>
                ),
            },
            );
        }

        return (
            <div>
                <PageLayout selectItem={this.state.menu}>
                    <Table
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        title={() =>
                            <HeaderUsuario
                                naoCadastrar={this.state.permissoes.naoCadastrar}
                                ref={this.headerUsuarioElement}
                                userType={this.state.userType}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                            />} columns={columns} dataSource={this.state.administradores} bordered loading={this.state.loading} />
                </PageLayout>
            </div>
        );
    }
}

class HeaderUsuario extends React.Component {
    constructor(props) {
        super(props);
        this.novoUsuarioElement = React.createRef();
        this.state = {
            visible: false,
        };
        this.novoUsuario = this.novoUsuario.bind(this);
        this.filterTable = this.filterTable.bind(this);
    }

    novoUsuario() {
        this.novoUsuarioElement.current.show(false);
    }

    editarUsuario(record) {
        this.novoUsuarioElement.current.show(true, record);
    }
    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }


    render() {
        const novoUsuarioText = window.location.pathname === '/rede' ? 'Nova Rede' : 'Novo Administrador';
        return (
            <div>
                <NovoUsuario
                    ref={this.novoUsuarioElement}
                    updateList={this.props.updateList}
                ></NovoUsuario>

                {this.props.naoCadastrar ? '' :
                    <Button disabled={this.props.naoCadastrar} type="primary" onClick={this.novoUsuario}><Icon className="icon" type="plus" /> {novoUsuarioText}</Button>
                }


                <Search
                    placeholder="Procurar"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ marginLeft: "10px", width: 200 }}
                />

            </div>
        );
    }
}

class NovoUsuario extends React.Component {
    state = {
        visible: false,
        confirmLoading: false,
        editMode: false,
        record: [],
        convenios: [],
    }

    constructor(props) {
        super(props);
        this.handleOk = this.handleOk.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
    }

    show(editMode, record) {
        if (!record) {
            record = [];
        }

        this.setState({
            visible: true,
            confirmLoading: false,
            editMode: editMode,
            record: record
        });
    }

    handleOk(record) {
        this.setState({ visible: false });
        this.props.updateList(record);
    }

    handleCancel() {
        this.setState({ visible: false });
    }

    render() {
        const titulo = window.location.pathname === '/rede' ? 'Rede' : 'Administrador';
        return (
            <Modal
                title={titulo}
                visible={this.state.visible}
                destroyOnClose={true}
                confirmLoading={this.state.confirmLoading}
                centered={true}
                onOk={this.handleOk}
                footer={null}

                closable={true}
                width="650px"
                onCancel={this.handleCancel}


            >
                <AdministradorCRUD
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    editMode={this.state.editMode}
                    record={this.state.record}
                    convenios={this.state.convenios}
                ></AdministradorCRUD>

            </Modal>
        );
    }
}

export default withRouter(Administrador);