import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class GestorDB extends DataManager {

    state = {
        collection: 'gestores',
        orderBy: ['nome', 'sobrenome'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId, fromCache, update) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('nome')
            .orderBy('sobrenome');

        const updateEndpoint = update ? '/gestorDB/getByOwnerIdCustom' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
        }));

        return data;
    }

    async getByCPF(cpf) {
        const data = await this.getByParamValue('cpf', cpf);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        return data;
    }
}

const gestorDB = new GestorDB();
export default gestorDB;