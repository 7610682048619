import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import BarChart from '../../../components/charts/barChart';
import { Select, DatePicker, Button, message, Card, Table } from 'antd';
import { getConvenios, getBeneficiarios, buildChart } from './Funcs-Movimentos';
import locale from 'antd/es/date-picker/locale/pt_BR';
import vertteStorage from '../../../components/localstorage/localstorage';
import moment from 'moment';
import './Movimentos.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import history from '../../../history';
import funcs from '../../../components/funcs/funcs';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';


const { Option } = Select
const { RangePicker } = DatePicker;
const dateFormat = 'DD/MM/YYYY'

// const CINZA = "#9d9e9d";
const CREDITO_NORMAL = '#32a852';
const DEBITO_NORMAL = '#eb3a34';
const CREDITO_PRE = '#a234eb';
const DEBITO_PRE = '#ebb134';
class Movimentos extends React.Component {

    state = {
        loading: true,
        convenioId: '',
        beneficiario: {},
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        options: {},
        data: {
            labels: [],
            datasets: []
        },
        showBeneficiarios: false,
        cardDebito: '',
        cardDebitoPre: '',
        cardCredito: '',
        cardCreditoPre: '',
        cardSaldo: '',
        beneficiarioSaldos: {},
        saldoBeneficiario: '',
        columns: [],
        tableData: []
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.onSelectBeneficiarioChange = this.onSelectBeneficiarioChange.bind(this);
        this.mountChart = this.mountChart.bind(this);
        this.buildColums = this.buildColums.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: false });

        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-movimentacao');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        const storage = JSON.parse(vertteStorage.get('@reports/movimentos'));

        if (storage) {

            const columns = [];
            storage.columns.forEach(column => {
                if (column.title === 'Débito') {
                    column.render = (text, record) => (
                        <label className="red">{text}</label>
                    )
                }
                columns.push(column);
            });

            this.setState({
                convenioId: storage.convenioId,
                beneficiario: storage.beneficiario,
                periodo: {
                    inicial: moment(storage.periodo.inicial),
                    final: moment(storage.periodo.final),
                },
                tableData: storage.tableData,
                cardCredito: storage.cardCredito,
                cardCreditoPre: storage.cardCreditoPre,
                cardDebito: storage.cardDebito,
                cardDebitoPre: storage.cardDebitoPre,
                cardSaldo: storage.cardSaldo,
                beneficiarioSaldos: storage.beneficiarioSaldos,
                columns: columns,
            })


            for (let b of storage.beneficiariosOptions) {
                this.beneficiariosOptions.push(<Option key={b.key} value={b.key}>{b.props.children}</Option>)
            }

            this.mountChart(storage.chartData)
        }

    }

    async onSelectChange(value) {
        this.beneficiariosOptions = [];

        const b = await getBeneficiarios(value);
        let saldos = {};

        this.beneficiariosOptions.push(<Option key='todos' value='todos'>Todos</Option>)
        for (const item of b) {
            saldos[item.key] = item.saldo ? (item.saldo / 100) : item.operacao ? (item.operacao.saldo / 100) : 0;
            this.beneficiariosOptions.push(<Option key={item.key} value={item.key}>{item.nome + ' ' + item.sobrenome} </Option>)
        }

        this.setState({ convenioId: value, alterouConfiguracaoRelatorio: true, beneficiario: {}, beneficiarioSaldos: saldos })
    }

    onSelectBeneficiarioChange(value, object) {
        const beneficiario = {
            id: value,
            nome: object.props.children
        }
        this.setState({
            beneficiario,
            data: [],
            cardCredito: '',
            cardCreditoPre: '',
            cardDebito: '',
            cardDebitoPre: '',
            cardSaldo: '',
        })
    }

    onDateChange(value) {
        const periodo = {
            inicial: value[0],
            final: value[1]
        }
        this.setState({ periodo, alterouConfiguracaoRelatorio: true });
    }

    async onFilterClick() {

        this.buildColums();

        if (this.state.convenioId === '') {
            message.warning("Selecione um convênio");
            return;
        }

        if (!this.state.periodo.inicial || !this.state.periodo.final) {
            message.warning("Selecione um período");
            return;
        }

        if (!this.state.beneficiario.id) {
            message.warning("Selecione um beneficiário");
            return;
        }

        this.setState({ loading: true })

        const data = await buildChart(
            this.state.convenioId,
            this.state.periodo.inicial,
            this.state.periodo.final,
            this.state.beneficiario.id,
            false
        );

        this.mountChart(data.chartData);

        const tableData = data.tableData;

        const storageElement = {
            convenioId: this.state.convenioId,
            beneficiario: this.state.beneficiario,
            periodo: this.state.periodo,
            chartData: data.chartData,
            tableData: tableData,
            cardCredito: this.state.cardCredito,
            cardCreditoPre: this.state.cardCreditoPre,
            cardDebito: this.state.cardDebito,
            cardDebitoPre: this.state.cardDebitoPre,
            cardSaldo: this.state.cardSaldo,
            beneficiarioSaldos: this.state.beneficiarioSaldos,
            columns: this.state.columns,
            beneficiariosOptions: this.beneficiariosOptions,
        }

        vertteStorage.set('@reports/movimentos', JSON.stringify(storageElement));

        this.setState({ tableData })

        this.setState({ loading: false, showBeneficiarios: true })

    }

    async mountChart(chartData) {
        let dataSet = [
            {
                label: 'CRÉDITO',
                data: [],
                backgroundColor: CREDITO_NORMAL,
            },
            {
                label: 'CRÉDITO PRÉ',
                data: [],
                backgroundColor: CREDITO_PRE,
            },
            {
                label: "DÉBITO",
                data: [],
                backgroundColor: DEBITO_NORMAL,
            },
            {
                label: "DÉBITO PRÉ",
                data: [],
                backgroundColor: DEBITO_PRE,
            },
        ]

        let labels = [];

        let debitoTotal = 0;
        let debitoPreTotal = 0;
        let creditoTotal = 0;
        let creditoPreTotal = 0;

        chartData.forEach((item) => {
            debitoTotal += item.debito;
            debitoPreTotal += item.debitoPre;

            creditoTotal += item.credito;
            creditoPreTotal += item.creditoPre;
            dataSet[0].data.push(item.credito);
            dataSet[1].data.push(item.creditoPre);
            dataSet[2].data.push(item.debito);
            dataSet[3].data.push(item.debitoPre);

            const date = new Date(item.data);
            labels.push(date.getDate() + "/" + (date.getMonth() + 1))
        });

        const data = {
            labels: labels,
            datasets: dataSet
        }

        debitoTotal = beneficiarioDB.formatarMoeda(debitoTotal);
        debitoPreTotal = beneficiarioDB.formatarMoeda(debitoPreTotal);
        creditoTotal = beneficiarioDB.formatarMoeda(creditoTotal);
        creditoPreTotal = beneficiarioDB.formatarMoeda(creditoPreTotal);


        const saldo = this.state.beneficiario.id === 'todos' ?
            0 : (!this.state.beneficiarioSaldos[this.state.beneficiario.id]) ?
                0 : this.state.beneficiarioSaldos[this.state.beneficiario.id];

        const formatedSaldo = beneficiarioDB.formatarMoeda(saldo);

        this.setState({
            cardDebito: debitoTotal,
            cardDebitoPre: debitoPreTotal,
            cardCredito: creditoTotal,
            cardCreditoPre: creditoPreTotal,
            cardSaldo: formatedSaldo,
            data
        });
    }

    buildColums() {
        const columns = [
            {
                title: 'Estabelecimento',
                dataIndex: 'estabelecimento.nome',
                key: 'estabelecimento.nome'
            },
            {
                title: 'Débito',
                dataIndex: 'debito',
                key: 'debito',
                align: 'right',
                render: (text, record) => (
                    <label className="red">{text}</label>
                ),
            },
            {
                title: 'Forma pagto',
                dataIndex: 'tipoDebito',
                key: 'tipoDebito'
            }
        ]

        this.setState({ columns })
    }


    render() {
        return (
            <div>
                <PageLayout selectItem={"relatorios/movimentacao"}>
                    <div className="containerMovimentacao">
                        <label>Convênio: </label>
                        <Select

                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.loading}
                            optionFilterProp="children"
                            style={{ width: '500px' }}
                            placeholder='Selecione um convênio'
                            onChange={this.onSelectChange}
                            value={this.state.convenioId}
                        >{this.options}</Select>

                        <label style={{ marginLeft: 10, marginRight: 10 }}>Beneficiário: </label>
                        <Select
                            ref={(el) => this.beneficiarioRef = el}
                            disabled={this.state.loading}
                            showSearch
                            optionFilterProp="children"
                            style={{ width: '20%' }}
                            placeholder='Selecione um beneficiário'
                            onChange={this.onSelectBeneficiarioChange}
                            value={this.state.beneficiario.id}
                        >
                            {this.beneficiariosOptions}
                        </Select>

                        <label>Período: </label>
                        <RangePicker
                            style={{ width: '25%' }}
                            locale={locale}
                            disabled={this.state.loading}
                            format={dateFormat}
                            placeholder={['Data Inicial', 'Data Final']}
                            onChange={this.onDateChange}
                            value={[this.state.periodo.inicial, this.state.periodo.final]}
                        />
                        <Button loading={this.state.loading} onClick={this.onFilterClick} type="primary">Filtrar</Button>
                    </div>

                    <div className="labelNomeBeneficiario">
                        <label>{this.state.beneficiario.nome ? this.state.beneficiario.nome : ''}</label>
                    </div>

                    {this.state.beneficiario.id && this.state.beneficiario.id !== 'todos' ?
                        <div className='Cards'>
                            <Card
                                title="Crédito Total"
                                style={{ width: 300 }}
                            >
                                {this.state.cardCredito}
                            </Card>

                            <Card
                                title="Crédito Pré Total"
                                style={{ width: 300 }}
                            >
                                {this.state.cardCreditoPre}
                            </Card>

                            <Card
                                title="Débito Total"
                                style={{ width: 300 }}
                            >
                                {this.state.cardDebito}
                            </Card>

                            <Card
                                title="Débito (Pré) Total"
                                style={{ width: 300 }}
                            >
                                {this.state.cardDebitoPre}
                            </Card>

                            <Card
                                title="Saldo Atual"
                                style={{ width: 300 }}
                            >
                                {this.state.cardSaldo}
                            </Card>
                        </div>
                        :
                        this.state.beneficiario.id ?
                            <div className='Cards'>
                                <Card
                                    title="Débito Total no Período"
                                    style={{ width: 460 }}
                                >
                                    {this.state.cardDebito}
                                </Card>

                                <Card
                                    title="Crédito Total no Período"
                                    style={{ width: 460 }}
                                >
                                    {this.state.cardCredito}
                                </Card>
                            </div>

                            : null
                    }

                    <label className='subtituloMovimentos'>Débito/Crédito por Dia: </label>
                    <BarChart className="chartMovimento"
                        data={this.state.data}

                        options={{
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {

                                        let label = data.datasets[tooltipItem.datasetIndex].label;
                                        let value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];

                                        value = 'R$ ' + value.toFixed(2);

                                        return ' ' + label + ': ' + value
                                    }
                                }
                            },
                            scales: {
                                yAxes: [
                                    {
                                        ticks: {
                                            beginAtZero: true,
                                        }

                                    }
                                ]
                            },
                            layout: {
                                padding: {
                                    top: 20,
                                    right: 40,
                                    left: 40,
                                    bottom: 20,
                                }
                            },
                            plugins: {
                                datalabels: {
                                    display: true,
                                    anchor: "end",
                                    align: "top",
                                    formatter: function (value, context) {
                                        if (value === undefined) {
                                            return 0
                                        } else if (value === 0) {
                                            return value;
                                        } else {
                                            return 'R$ ' + (value.toFixed(2))
                                        }
                                    }
                                },
                            },
                        }}
                    />

                    <label className='subtituloMovimentos'>Débito por Estabelecimento: </label>
                    <div className="tableEstabelecimentos">
                        <Table
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(Movimentos);

