import DataManager from './dtm';
import { fbDatabase } from '../firebase/firebase';


class AdministradorDB extends DataManager {

    state = {
        collection: 'administradores',
        orderBy: ['razaoSocial', 'nomeFantasia'], // usado na classe pai
    }

    async getByOwnerIdCustom(gestorId, fromCache, update) {
        const query = fbDatabase
            .collection(this.state.collection)
            .where('ownerId', '==', gestorId)
            .orderBy('razaoSocial')
            .orderBy('nomeFantasia');

        const updateEndpoint = update ? '/administradorDB/getByOwnerIdCustom' : undefined;

        const snapshot = await this.getQueryData(query, fromCache, updateEndpoint);

        const data = snapshot.docs.map((item) => ({
            key: item.id,
            ...item.data(),
            ativoStr: item.data().ativo ? 'Sim' : 'Não',
            cashbackStr: item.data().premiacao && item.data().premiacao.cashback ? `${item.data().premiacao.cashback.valor}%` : '',
            fidelidadeStr: item.data().premiacao && item.data().premiacao.fidelidade && item.data().premiacao.fidelidade.premios ? `${item.data().premiacao.fidelidade.premios[0].pontos} pts` : '--',
        }));

        return data;
    }

    async getByCNPJ(cnpj) {
        const data = await this.getByParamValue('cnpj', cnpj);
        return data;
    }

    async getByEmail(email) {
        const data = await this.getByParamValue('email', email);
        if (data) {
            data[0].ativoStr = data[0].ativo ? 'Sim' : 'Não';
        }
        return data;
    }
}

const administradorDB = new AdministradorDB();
export default administradorDB;