import React from 'react';
import { Form, Input, Button, Icon, message } from 'antd';
import './Saldo.css';
import { unformatNumber } from "../../components/cartao/card-provider";
import history from '../../history';
import {
    API_ROUTER,
} from '../../firebase/firebase';
import { currentUser } from '../../components/auth/auth-provider';



class Saldo extends React.Component {

    state = {
        loading: false,
        buttonText: 'Verificar',
    }

    constructor(props) {
        super(props);
        this.checkSaldo = this.checkSaldo.bind(this);
    };

    async componentDidMount() {
        const user = currentUser();

        if (user) {
            message.warning('A consulta de SALDO pelo número do cartão não está disponível para usuários logados');
            this.props.history.push('/dashboard');
        }
    }

    checkSaldo(cardNumber) {
        return new Promise((resolve, reject) => {
            const url = API_ROUTER + 'balance-by-cardnumber';
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `moub-ou-vertte`,
                    'cardnumber': cardNumber,
                },
            }).then((response) => response.json())
                .then((responseData) => {
                    if (responseData.code === 200) {
                        resolve(responseData);
                    } else {
                        reject(responseData);
                    }
                })
                .catch(err => {
                    reject(err);
                });
        });
    }

    handleSubmit = e => {
        e.preventDefault();

        this.props.form.validateFieldsAndScroll(async (err, values) => {
            console.log(values)
            if (err) {
                return;
            }

            const cardNumber = unformatNumber(values.cardnumber);
            if (cardNumber.length !== 16) {
                message.error('Cartão precisa ter 16 dígitos');
                return;
            }

            this.setState({ loading: true, buttonText: 'Verificando...' });

            this.checkSaldo(cardNumber)
                .then(data => {
                    history.push({
                        pathname: 'saldo-cartao',
                        state: data
                    });
                    history.go();
                })
                .catch(err => {
                    if (err.code !== 500) {
                        message.warning(err.message);
                    }
                    else {
                        message.error(err.message);
                    }
                    this.setState({ loading: false, buttonText: 'Verificar Saldo' });
                });
        });
    }

    handleChange = e => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
    }

    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <div className="bodyLoginSaldo">
                <div className="centerLogin">

                    <div className="logo">
                        <div className="logoRegina">
                            <img alt="Moub"></img>
                        </div>
                    </div>

                    <h1 className="titleLoginH1">Consulta de Saldo</h1>

                    <Form onSubmit={this.handleSubmit} className="login-form" autoComplete="off">
                        <Form.Item>
                            {getFieldDecorator('cardnumber', {
                                rules: [{ required: true, message: 'Digite o número do cartão' }],
                            })(
                                <Input
                                    prefix={<Icon type="credit-card" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                    placeholder="Digite o número do cartão"
                                    onChange={this.handleChange}
                                    autoFocus={true}
                                />,
                            )}
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" loading={this.state.loading} htmlType="submit" className="login-form-button">
                                {this.state.buttonText}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default Form.create()(Saldo);
