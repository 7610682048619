import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout'
import { Table, Button, message, Input, Switch, Radio } from 'antd';
import { getData } from './ClientesF';
import vertteStorage from '../../../components/localstorage/localstorage';
import listObj from "../../../components/listSearch/listSearch";
import history from '../../../history';
import './Clientes.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import funcs from '../../../components/funcs/funcs';
import moment from 'moment';
import "jspdf-autotable";
import * as XLSX from 'xlsx';


const { Search } = Input;
const key = 'updatemessage';


class Clientes extends React.Component {

    state = {
        loading: false,
        columns: [],
        tableData: [],
        sortedInfo: null,
        tableDataOriginal: [],
        alterouConfiguracaoRelatorio: false,
        textFilter: 'Filtrar',
        filtrosRelatorio: {
            totalPedidos: false,
            pagos: false,
            pagamentosEmAberto: false,
            pagamentosEmAtraso: false,
            cancelados: false,
            valorGastoMensal: false,
            totalColaboradores: false,
            ultimaCompra: false,
            periodoMensal: '3meses',
            ativos: true,
            inativos: false,
        },
    }

    constructor(props) {
        super(props);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.buildColumns = this.buildColumns.bind(this);
        this.applyFilter = this.applyFilter.bind(this);
        this.updateList = this.updateList.bind(this);
        this.exportExcell = this.exportExcell.bind(this);

        this.contarPedidosChange = this.contarPedidosChange.bind(this);
        this.pagamentosEfetuadosChange = this.pagamentosEfetuadosChange.bind(this);
        this.pagamentosEmAbertoChange = this.pagamentosEmAbertoChange.bind(this);
        this.pagamentosEmAtrasoChange = this.pagamentosEmAtrasoChange.bind(this);
        this.canceladosChange = this.canceladosChange.bind(this);
        this.totalColaboradoresChange = this.totalColaboradoresChange.bind(this);
        this.gastoMensalChange = this.gastoMensalChange.bind(this);
        this.ultimaCompraChange = this.ultimaCompraChange.bind(this);
        this.ativosChange = this.ativosChange.bind(this);
        this.inativosChange = this.inativosChange.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: true });
        const userCol = currentUserCol();
        const userType = await getUserType();

        debugger;
        if (userCol || userType !== 'Gestor') {
            debugger;
            const isOk = await funcs.podeAbrirRelatorio(userCol, 'relatorios-convenios');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const storage = JSON.parse(vertteStorage.get('@reports/clientes-convenios'));

        if (storage) {

            if (storage.filtrosRelatorio.ativos === undefined) {
                storage.filtrosRelatorio.ativos = true;
            }

            if (storage.filtrosRelatorio.inativos === undefined) {
                storage.filtrosRelatorio.inativos = false;
            }

            this.setState({
                tableData: storage.tableData,
                tableDataOriginal: storage.tableData,
                convenio: storage.convenio,
                filtrosRelatorio: storage.filtrosRelatorio,
                periodo: {
                    inicial: moment(storage.periodo ? storage.periodo.inicial : new Date()),
                    final: moment(storage.periodo ? storage.periodo.final : new Date()),
                },
            });

            this.buildColumns();
        }
        this.setState({ loading: false });
    }

    contarPedidosChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.totalPedidos = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    pagamentosEfetuadosChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.pagos = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    pagamentosEmAbertoChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.pagamentosEmAberto = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    pagamentosEmAtrasoChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.pagamentosEmAtraso = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    canceladosChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.cancelados = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    totalColaboradoresChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.totalColaboradores = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    gastoMensalChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.valorGastoMensal = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    ultimaCompraChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        filtrosRelatorio.ultimaCompra = value
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    ativosChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        if (!value && (filtrosRelatorio.inativos === value || filtrosRelatorio.inativos == undefined)) {
            mensagem.avisar('As opções "Inativos" e "Ativos" não podem ficar desmarcadas ao mesmo tempo.');
            return;
        }

        filtrosRelatorio.ativos = value;
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }

    inativosChange(value) {
        const filtrosRelatorio = this.state.filtrosRelatorio;
        
        if (!value && (filtrosRelatorio.ativos === value || filtrosRelatorio.ativos == undefined)) {
            mensagem.avisar('As opções "Ativos" e "Inativos" não podem ficar desmarcadas ao mesmo tempo.');
            return;
        }


        filtrosRelatorio.inativos = value;
        this.setState({ filtrosRelatorio, tableData: [] });
        setTimeout(() => {
            this.buildColumns();
        }, 300);
    }


    exportExcell() {
        const worksheet = XLSX.utils.json_to_sheet(this.state.tableData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Clientes.xlsx");
    }

    async onFilterClick() {
        message.warn({ content: 'Este relatório pode demorar um pouco mais que o normal para ser compilado. Aguarde.', key });

        this.setState({ loading: true, textFilter: 'Aguarde...', tableData: [] });

        this.buildColumns();

        const data = await getData(this.state.filtrosRelatorio);
        const storageElement = {
            tableData: data,
            convenio: this.state.convenio,
            periodo: this.state.periodo,
            filtrosRelatorio: this.state.filtrosRelatorio,
        }

        vertteStorage.set('@reports/clientes-convenios', JSON.stringify(storageElement));
        this.setState({ tableData: data, tableDataOriginal: data, loading: false, textFilter: 'Filtrar' });
    }

    handleChange = (pagination, filters, sorter) => {

        function convertDate(dateString) {
            if (dateString === '--') {
                dateString = '04/12/1977';
            }
            const parts = dateString.split('/');
            return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
        };


        if (!sorter.order) {
            return;
        }
        const tableData = this.state.tableData;
        if (sorter.order == 'ascend') {

            if (sorter.columnKey === 'razaoSocial') {
                tableData.sort(function (a, b) {
                    return a.razaoSocial < b.razaoSocial ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'nomeFantasia') {
                tableData.sort(function (a, b) {
                    return a.nomeFantasia < b.nomeFantasia ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalPedidos') {
                tableData.sort(function (a, b) {
                    return a.totalPedidos < b.totalPedidos ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalPagos') {
                tableData.sort(function (a, b) {
                    return a.totalPagos < b.totalPagos ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalAguardando') {
                tableData.sort(function (a, b) {
                    return a.totalAguardando < b.totalAguardando ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalAtrasados') {
                tableData.sort(function (a, b) {
                    return a.totalAtrasados < b.totalAtrasados ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalCancelados') {
                tableData.sort(function (a, b) {
                    return a.totalCancelados < b.totalCancelados ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'totalColaboradores') {
                tableData.sort(function (a, b) {
                    return a.totalColaboradores < b.totalColaboradores ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'ultimoPedido') {
                tableData.sort(function (a, b) {
                    const dateA = convertDate(a.ultimoPedido).getTime();
                    const dateB = convertDate(b.ultimoPedido).getTime();
                    return dateA < dateB ? -1 : 1;
                });
            }

            if (sorter.columnKey === 'ativoStr') {
                tableData.sort(function (a, b) {
                    return a.ativoStr < b.ativoStr ? -1 : 1;
                });
            }
        } else {
            if (sorter.columnKey === 'razaoSocial') {
                tableData.sort(function (a, b) {
                    return a.razaoSocial < b.razaoSocial ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'nomeFantasia') {
                tableData.sort(function (a, b) {
                    return a.nomeFantasia < b.nomeFantasia ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalPedidos') {
                tableData.sort(function (a, b) {
                    return a.totalPedidos < b.totalPedidos ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalPagos') {
                tableData.sort(function (a, b) {
                    return a.totalPagos < b.totalPagos ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalAguardando') {
                tableData.sort(function (a, b) {
                    return a.totalAguardando < b.totalAguardando ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalAtrasados') {
                tableData.sort(function (a, b) {
                    return a.totalAtrasados < b.totalAtrasados ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalCancelados') {
                tableData.sort(function (a, b) {
                    return a.totalCancelados < b.totalCancelados ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'totalColaboradores') {
                tableData.sort(function (a, b) {
                    return a.totalColaboradores < b.totalColaboradores ? 1 : -1;
                });
            }

            if (sorter.columnKey === 'ultimoPedido') {

                tableData.sort(function (a, b) {
                    const dateA = convertDate(a.ultimoPedido).getTime();
                    const dateB = convertDate(b.ultimoPedido).getTime();
                    return dateA < dateB ? 1 : -1;

                });
            }
            if (sorter.columnKey === 'ativoStr') {
                tableData.sort(function (a, b) {
                    return a.ativoStr < b.ativoStr ? 1 : -1;
                });
            }

        }
        this.setState({ tableData });
    };

    buildColumns() {
        let { sortedInfo } = this.state;
        sortedInfo = sortedInfo || {};

        const columns = [
            {
                title: 'Razão Social',
                dataIndex: 'razaoSocial',
                key: 'razaoSocial',

                sorter: true,
                sortDirections: ["descend", "ascend"],

            },

            {
                title: 'Nome Fantasia',
                dataIndex: 'nomeFantasia',
                key: 'nomeFantasia',
                width: '150px',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            }
        ];


        if (this.state.filtrosRelatorio.totalPedidos) {
            columns.push({
                title: 'Pedidos Total',
                dataIndex: 'totalPedidos',
                key: 'totalPedidos',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagos) {
            columns.push({
                title: 'Pagos',
                dataIndex: 'totalPagos',
                key: 'totalPagos',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAberto) {
            columns.push({
                title: 'Aguardando Pgto',
                dataIndex: 'totalAguardando',
                key: 'totalAguardando',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAberto) {
            columns.push({
                title: 'Venc+Antigo (aberto)',
                dataIndex: 'dtVenctoMaisAntigoAberto',
                key: 'dtVenctoMaisAntigoAberto',
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAberto) {
            columns.push({
                title: 'Total em aberto',
                dataIndex: 'valorTotalEmAberto',
                key: 'valorTotalEmAberto',
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAtraso) {
            columns.push({
                title: 'Atrasados',
                dataIndex: 'totalAtrasados',
                key: 'totalAtrasados',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAtraso) {
            columns.push({
                title: 'Venc+Antigo (atraso)',
                dataIndex: 'dtVenctoMaisAntigoAtraso',
                key: 'dtVenctoMaisAntigoAtraso',
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.pagamentosEmAtraso) {
            columns.push({
                title: 'Total em atraso',
                dataIndex: 'valorTotalEmAtraso',
                key: 'valorTotalEmAtraso',
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.cancelados) {
            columns.push({
                title: 'Cancelados',
                dataIndex: 'totalCancelados',
                key: 'totalCancelados',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.totalColaboradores) {
            columns.push({
                title: 'Total Colaboradores',
                dataIndex: 'totalColaboradores',
                key: 'totalColaboradores',
                sorter: true,
                sortDirections: ["descend", "ascend"],
                align: 'right',
            });
        }

        if (this.state.filtrosRelatorio.ultimaCompra || this.state.filtrosRelatorio.valorGastoMensal) {
            columns.push({
                title: 'Último Pedido',
                dataIndex: 'ultimoPedido',
                key: 'ultimoPedido',
                sorter: true,
                sortDirections: ["descend", "ascend"],
            });
        }

        columns.push({
            title: 'Ativo',
            dataIndex: 'ativoStr',
            key: 'ativoStr',
            sorter: true,
            sortDirections: ["descend", "ascend"],
        });

        this.setState({ columns })
    }

    async applyFilter(text) {
        console.log(this.state.tableDataOriginal);
        if (text === '') {
            this.setState({ tableData: this.state.tableDataOriginal });
            return;
        };

        const keys = [
            'razaoSocial',
            'nomeFantasia',
            'ativoStr'
        ];

        const data = listObj.search(this.state.tableDataOriginal, text, keys);
        this.setState({ tableData: data });
    }

    updateList(record) {
        let data = this.state.tableData;
        let registroEncontrado = false;

        if (data) {
            this.state.tableData.forEach((item, index) => {
                if (item.key === record.key) {
                    data[index] = record;
                    registroEncontrado = true;
                }
            });
        } else {
            data = []
        }

        if (!registroEncontrado) {
            data.push(record);
        }
        this.setState({ dataTable: data, dataTableOriginal: data });
    }


    render() {
        let totalStr = this.state.tableData.length > 1 ? ' registros' : this.state.tableData.length === 1 ? ' registro' : '';
        totalStr = `${this.state.tableData.length} ${totalStr}`;

        const a_this = this;

        const onFiltroChange = (e, i) => {
            const filtrosRelatorio = a_this.state.filtrosRelatorio;
            filtrosRelatorio.periodoMensal = e.target.value;
            a_this.setState({ filtrosRelatorio });
        };

        return (


            <PageLayout selectItem="relatorios/convenios">
                <div className="containerClientesConvenio">
                    <Switch
                        checkedChildren="Total Pedidos"
                        unCheckedChildren="Total de Pedidos"
                        checked={this.state.filtrosRelatorio.totalPedidos}
                        onChange={this.contarPedidosChange}
                    />

                    <Switch
                        checkedChildren="pagos"
                        unCheckedChildren="Pagos"
                        checked={this.state.filtrosRelatorio.pagos}
                        onChange={this.pagamentosEfetuadosChange}
                    />

                    <Switch
                        checkedChildren="Em aberto"
                        unCheckedChildren="Em aberto"
                        checked={this.state.filtrosRelatorio.pagamentosEmAberto}
                        onChange={this.pagamentosEmAbertoChange}
                    />

                    <Switch
                        checkedChildren="Em atraso"
                        unCheckedChildren="Em atraso"
                        checked={this.state.filtrosRelatorio.pagamentosEmAtraso}
                        onChange={this.pagamentosEmAtrasoChange}
                    />

                    <Switch
                        checkedChildren="Cancelados"
                        unCheckedChildren="Cancelados"
                        checked={this.state.filtrosRelatorio.cancelados}
                        onChange={this.canceladosChange}
                    />

                    <Switch
                        checkedChildren="Total colaboradores"
                        unCheckedChildren="Total colaboradores"
                        checked={this.state.filtrosRelatorio.totalColaboradores}
                        onChange={this.totalColaboradoresChange}
                    />
                    <Switch
                        checkedChildren="Último pedido"
                        unCheckedChildren="Último pedido"
                        checked={this.state.filtrosRelatorio.ultimaCompra}
                        onChange={this.ultimaCompraChange}
                    />

                    <Switch
                        checkedChildren="Último pedido há mais de"
                        unCheckedChildren="Último pedido há mais de"
                        checked={this.state.filtrosRelatorio.valorGastoMensal}
                        onChange={this.gastoMensalChange}
                    />

                    <Switch
                        checkedChildren="Ativos"
                        unCheckedChildren="Ativos"
                        checked={this.state.filtrosRelatorio.ativos}
                        onChange={this.ativosChange}
                    />

                    <Switch
                        checkedChildren="Inativos"
                        unCheckedChildren="Inativos"
                        checked={this.state.filtrosRelatorio.inativos}
                        onChange={this.inativosChange}
                    />

                </div>

                <div className="containerClientesConvenio">
                    <Radio.Group onChange={e => onFiltroChange(e)} defaultValue={this.state.filtrosRelatorio.periodoMensal} disabled={!this.state.filtrosRelatorio.valorGastoMensal}>
                        <Radio.Button value="3meses">3 meses</Radio.Button>
                        <Radio.Button value="6meses">6 meses</Radio.Button>
                        <Radio.Button value="9meses">9 meses</Radio.Button>
                        <Radio.Button value="12meses">12 meses</Radio.Button>
                    </Radio.Group>


                    <Button type="primary" disabled={this.state.loading} onClick={this.onFilterClick}>{this.state.textFilter}</Button>
                </div>

                <div className="tableClienteConvenio">
                    <Button onClick={() => this.exportExcell()}>Exportar para Excell</Button>
                    <Table
                        title={() => (
                            <Header
                                ref={this.headerElement}
                                empresas={this.state.empresas}
                                applyFilter={this.applyFilter}
                                updateList={this.updateList}
                            />
                        )}
                        rowExpandable={true}
                        expandRowByClick={true}
                        loading={this.state.loading}
                        dataSource={this.state.tableData}
                        columns={this.state.columns}
                        rowKey={(record) => record.key}
                        pagination={{
                            defaultPageSize: 100,
                            position: 'both',
                            pageSizeOptions: ["25", "50", "100", "200"],
                            showSizeChanger: true,
                            locale: { items_per_page: "" }
                        }}
                        footer={() => <div>
                            <p><strong>Total:</strong>{` ${totalStr}`}</p>
                        </div>}

                        onChange={this.handleChange}
                    >

                    </Table>
                </div>

            </PageLayout >
        )
    }

}

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };
        this.filterTable = this.filterTable.bind(this);
    }

    filterTable(value) {
        const text = typeof value === "object" ? value.target.value : value;
        this.props.applyFilter(text);
    }

    render() {
        return (
            <div>
                <Search
                    placeholder="Procurar (Razão Social, Nome Fantasia ou Ativo)"
                    onSearch={this.filterTable}
                    onChange={this.filterTable}
                    style={{ width: 400 }}
                />

            </div>
        );
    }
}

export default withRouter(Clientes);