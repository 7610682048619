import React from 'react';
import { withRouter } from 'react-router';
import { Button, message, Card, Table } from 'antd';
import history from '../../history';
import './SaldoCartao.css'
import { currentUser } from '../../components/auth/auth-provider';


const CARD_SALDOPRE_SHOW = { width: '18rem', flexGrow: 1, marginRight: '1.25rem' };
const CARD_SALDOPRE_HIDE = { display: 'none' };


class SaldoCartao extends React.Component {
    state = {
        button: false,
        loading: false,
        monitor: 'Clique aqui para encerrar',
        convenioId: '',
        beneficiario: {},
        cartao: {},
        periodo: {},
        alterouConfiguracaoRelatorio: false,
        options: {},
        data: {
            labels: [],
            datasets: []
        },
        showBeneficiarios: false,
        cardDebito: '',
        cardDebitoPre: '',
        cardCredito: '',
        cardCreditoPre: '',
        cardSaldo: '',
        cardSaldoPre: '',
        beneficiarioSaldos: {},
        saldoBeneficiario: '',
        tableData: [],
        columns: [
            {
                title: 'Estabelecimento',
                dataIndex: 'estabelecimento',
                key: 'estabelecimento'
            },
            {
                title: "Débito/Crédito",
                dataIndex: "valorParaImprimir",
                align: 'right',
                render(text, record) {
                    return {
                        props: {
                            style: { color: !record.isCredit ? "red" : "green" }
                        },
                        children: <div>{text}</div>
                    };
                }
            },
            {
                title: 'Data',
                dataIndex: 'data',
                key: 'data',
                align: 'center',
            },
            {
                title: 'Saldo',
                dataIndex: 'saldo',
                key: 'saldo',
                align: 'right',
            },
        ],

        cssConfigs: {
            cardSaldoPre: CARD_SALDOPRE_HIDE,
        }
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.formatarMoeda = this.formatarMoeda.bind(this);
        this.startTimer = this.startTimer.bind(this);
        this.onClick = this.onClick.bind(this);
        this.checkCardPre = this.checkCardPre.bind(this);

        const data = this.props.location.state;

        if (!data) {
            history.goBack();
        }

        this.state.beneficiario = this.props.location.state.cartao.beneficiario;
        this.state.cartao.numeroFormatado = this.props.location.state.cartao.cartaoFormatado;
        this.state.cartao.status = this.props.location.state.cartao.status;
        this.state.cartao.validoAteBr = this.props.location.state.cartao.validoAteBr;

        this.state.cardSaldo = this.formatarMoeda(this.props.location.state.cartao.saldoEmCentavos / 100);
        this.state.cardSaldoPre = this.formatarMoeda(this.props.location.state.cartao.saldoPreEmCentavos / 100);
        const cssConfigs = {};
        cssConfigs.cardSaldoPre = this.props.location.state.cartao.saldoPreEmCentavos ? CARD_SALDOPRE_SHOW : CARD_SALDOPRE_HIDE;
        this.state.cssConfigs = cssConfigs;

        let credito = 0;
        let creditoPre = 0;
        let debito = 0;
        let debitoPre = 0;
        let estorno = 0;

        for (let index = 0; index < this.props.location.state.history.length; index++) {
            const movimento = this.props.location.state.history[index];
            this.props.location.state.history[index].key = `key-${index}`;
            if (movimento.isCredit && !movimento.isEstorno) {
                credito += movimento.creditoEmCentavos;
                this.props.location.state.history[index].valorParaImprimir = this.formatarMoeda(movimento.creditoEmCentavos / 100);
            }

            if (movimento.isCredit && movimento.creditoPre && !movimento.isEstorno) {
                creditoPre += movimento.creditoPreEmCentavos;
                this.props.location.state.history[index].valorParaImprimir = this.formatarMoeda(movimento.creditoPreEmCentavos / 100);
            }

            if (movimento.isEstorno) {
                estorno += movimento.creditoEmCentavos;
                this.props.location.state.history[index].valorParaImprimir = this.formatarMoeda(movimento.creditoEmCentavos / 100);
            }

            if (!movimento.isCredit && movimento.debitoEmCentavos) {
                debito += movimento.debitoEmCentavos;
                this.props.location.state.history[index].valorParaImprimir = this.formatarMoeda(-movimento.debitoEmCentavos / 100);
            }

            if (!movimento.isCredit && movimento.debitoPre) {
                debitoPre += movimento.debitoPre;
                this.props.location.state.history[index].valorParaImprimir = this.formatarMoeda(-movimento.debitoPre / 100);
            }
        }

        this.state.cardDebito = this.formatarMoeda((debito - estorno) / 100);
        this.state.cardDebitoPre = this.formatarMoeda(debitoPre / 100);
        this.state.cardCredito = this.formatarMoeda(credito / 100);
        this.state.cardCreditoPre = this.formatarMoeda(creditoPre / 100);
        this.state.history = this.props.location.state.history;

        // limpo
        history.replace('', null);
    }

    async componentDidMount() {
        const user = currentUser();
        if (user) {
            message.warning('A consulta de SALDO pelo número do cartão não está disponível para usuários logados');
            this.props.history.push('/dashboard');
        }
        else {
            this.renewTime();
        }
    }

    formatarMoeda(valor) {
        valor = parseFloat(valor).toLocaleString("pt-br", {
            style: "currency",
            currency: "BRL",
        });
        return valor;
    }

    startTimer(duration) {
        let timer = duration, minutes, seconds;
        const a_this = this;
        // eslint-disable-next-line
        this.state.interval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);

            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;

            const monitor = `Clique aqui para encerrar (${minutes + ":" + seconds})`;
            a_this.setState({ monitor });

            if (--timer < 0) {
                clearInterval(a_this.state.interval);
                history.goBack();
            }
        }, 1000);
    }

    handleSubmit = e => {
        clearInterval(this.state.interval);
        history.goBack();
    }

    renewTime = e => {
        clearInterval(this.state.interval);
        this.startTimer(20);
    }

    onClick() {
        this.setState({ loading: true });
        setTimeout(() => {
            const button = !this.state.button;
            this.setState({ loading: false, button: button });
        }, 1500)
    }

    checkCardPre() {
        let cardRes;
        if ((this.state.cardCreditoPre && this.state.cardDebitoPre && this.state.cardSaldoPre) === this.formatarMoeda(0 / 100)) {
            cardRes = true;
        } else {
            cardRes = false;
        }
        return cardRes;
    }

    render() {
        return (
            <div className="divSaldoPorCartao">
                <div className="labelNomeBeneficiario">
                    <label>{this.state.beneficiario.nome ? this.state.beneficiario.nome : ''}</label>
                    &nbsp;
                    &nbsp;
                    <Button type="primary" onClick={this.handleSubmit}>{this.state.monitor}</Button>
                    &nbsp;
                    <Button type="primary" onClick={this.renewTime}>Mais 20 segundos</Button>
                    &nbsp;
                    <Button type={this.state.button ? "primary" : ""} onClick={this.onClick} loading={this.state.loading}>
                        Extrato de Movimentação
                    </Button>
                </div>

                {this.state.button ? null :
                    <div>
                        <div className='Cards'>

                            <Card
                                className="cardSaldo"
                                title="Saldo Atual"
                                headStyle={{ background: 'rgb(56, 138, 18)', border: 0, color: 'white' }}
                                style={{ width: '18rem', flexGrow: 1, marginRight: '1.25rem' }}
                            >
                                <div className="labelSaldo">
                                    {this.state.cardSaldo}
                                </div>
                            </Card>

                            <Card
                                className="cardCredito"
                                title="Crédito Total"
                                headStyle={{ background: 'rgb(230, 230, 230)', border: 0 }}
                                style={{ width: '18rem', flexGrow: 1, marginRight: '1.25rem' }}
                            >
                                <div className="labelCredito">
                                    {this.state.cardCredito}
                                </div>

                            </Card>

                            <Card
                                className="cardDebito"
                                title="Débito Total"
                                headStyle={{ background: 'rgb(230, 230, 230)', border: 0 }}
                                style={{ width: '18rem', flexGrow: 1 }}
                            >
                                <div className="labelDebito">
                                    {this.state.cardDebito}
                                </div>

                            </Card>
                        </div>


                        {this.checkCardPre() ? null :
                            <div className='Cards'>

                                <Card
                                    className="cardSaldo"
                                    title="Saldo Desc. Folha Atual"
                                    headStyle={{ background: 'rgb(56, 138, 18)', border: 0, color: 'white' }}
                                    style={{ width: '18rem', flexGrow: 1, marginRight: '1.25rem' }}
                                >
                                    <div className="labelSaldo">
                                        {this.state.cardSaldoPre}
                                    </div>
                                </Card>


                                <Card
                                    title="Crédito - Desconto em Folha"
                                    headStyle={{ background: 'rgb(230, 230, 230)', border: 0 }}
                                    style={{ width: '18rem', flexGrow: 1, marginRight: '1.25rem' }}
                                >
                                    <div className="labelCredito">
                                        {this.state.cardCreditoPre}
                                    </div>

                                </Card>

                                <Card
                                    title="Débito - Desconto em Folha"
                                    headStyle={{ background: 'rgb(230, 230, 230)', border: 0 }}
                                    style={{ width: '18rem', flexGrow: 1 }}
                                >
                                    <div className="labelDebito">
                                        {this.state.cardDebitoPre}
                                    </div>

                                </Card>
                            </div>}

                        <div className="Cards">
                            <Card
                                title="Cartão"
                                headStyle={{ background: 'rgb(230, 230, 230)', border: 0 }}
                                style={{ width: '100%', height: '12rem' }}
                            >
                                <div>
                                    <p><strong>Número:</strong> {this.state.cartao.numeroFormatado}</p>
                                    <p><strong>Status:</strong> {this.state.cartao.status}</p>
                                    <p><strong>Validade:</strong> {this.state.cartao.validoAteBr}</p>
                                </div>
                            </Card>
                        </div>

                        <div>
                            <label className='nota'>
                                <strong>Nota:</strong> Os valores de Crédito, Débito, Débito Pré e Desconto em Folha, são referentes a 7 dias.
                            </label>
                        </div>
                    </div>}

                {this.state.button ? <div>
                    <label className='subtituloMovimentos'>Extrato movimentação: </label>
                    <div className="tableEstabelecimentos">
                        <Table
                            pagination={false}
                            columns={this.state.columns}
                            dataSource={this.state.history}
                        >
                        </Table>
                    </div>
                </div> : null}
            </div>
        )
    }
}

export default withRouter(SaldoCartao);

