const generator = require('creditcard-generator');

export function getNewOne(formatted) {
    let card = generator.GenCC('Mastercard', 1, Math.random)[0];
    if (formatted) {
        card = formatNumber(card);
    }
    card = card.charAt(0).replace('5', '2') + card.slice(1);
    const min = 100;
    const max = 999;
    const dv = Math.floor(Math.random() * (max - min + 1)) + min;
    const password = newPassword();
    return {
        'numero': card,
        dv,
        password
    };
}

export function newPassword() {
    var x = '';
    for (var i = 0; i < 4; i++) {
        x += Math.floor(Math.random() * 10);
    }
    return Number(x);
}

export function formatNumber(value) {
    var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
    var matches = v.match(/\d{4,16}/g);

    // eslint-disable-next-line no-mixed-operators
    var match = (matches && matches[0] || '')
    var parts = []

    for (let i = 0, len = match.length; i < len; i += 4) {
        parts.push(match.substring(i, i + 4))
    }

    if (parts.length) {
        return parts.join(' ')
    } else {
        return value
    }
}

export function unformatNumber(value) {
    return value.replace(/ /g, '');
}

export function genValidAt() {
    const d = new Date();
    const validAt = new Date(d.getFullYear() + 6, d.getMonth(), d.getDate());
    return validAt;
}