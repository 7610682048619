import React from 'react';
import { withRouter } from 'react-router';
import PageLayout from '../../../components/layout/PageLayout';
import { Select, Button, Table, Tooltip, Icon, message } from 'antd';
import { getConvenios, getCards, ignorePrint, markPrinted } from './PrintF';
import './Print.css'
import { currentUserCol, getUserType } from '../../../components/auth/auth-provider';
import mensagem from '../../../components/messages/message';
import history from '../../../history';
import funcs from '../../../components/funcs/funcs';
import { unformatNumber } from '../../../components/cartao/card-provider';

const { Option } = Select
const key = 'keymessagecard';


class CardsToPrint extends React.Component {

    state = {
        loading: true,
        conveniosIds: [],
        beneficiario: {},
        columns: [
            {
                title: 'Número',
                dataIndex: 'numero',
                key: 'numero',
            },
            {
                title: 'Beneficiário',
                dataIndex: 'nome',
                key: 'nome',
            },
            {
                title: 'Validade',
                dataIndex: 'validade',
                key: 'validade',
            },
            {
                title: 'Convênio',
                dataIndex: 'convenio',
                key: 'convenio',
            },
            {
                title: '',
                key: 'action',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="topLeft" title="Não imprimir">
                            <Icon
                                type="issues-close"
                                onClick={() => this.ignoreCardPrint(record)}
                            />
                        </Tooltip>


                    </span>
                ),
            },
        ],
        tableData: [],
        selectedRowKeys: [],
    }

    options = [];
    beneficiariosOptions = [];

    constructor(props) {
        super(props)
        this.onSelectChange = this.onSelectChange.bind(this);
        this.onFilterClick = this.onFilterClick.bind(this);
        this.ignoreCardPrint = this.ignoreCardPrint.bind(this);
        this.onTextGenerate = this.onTextGenerate.bind(this);
    }

    async componentDidMount() {
        const userCol = currentUserCol();
        const userType = await getUserType();
        if (userCol || userType !== 'Gestor') {
            const isOk = await funcs.podeAbrirPagina(userCol, 'cards-to-print', 'tools');
            if (!isOk) {
                this.setState({ loading: false });
                await mensagem.avisar('Você não tem permisão para acessar essa página.');
                history.push('/dashboard');
                history.go();
                return;
            }
        }

        const convenios = await getConvenios();
        if (!convenios) {
            return;
        }

        for (const item of convenios) {
            this.options.push(<Option key={item.key} value={item.key}>{item.nomeFantasia}</Option>)
        }

        this.setState({ loading: false });
    }

    async onSelectChange(conveniosIds) {
        this.setState({ conveniosIds });
    }

    async onFilterClick() {
        if (this.state.conveniosIds.length === 0) {
            message.warning({ content: 'Selecione um ou mais convênios', key });
            return;
        }
        this.setState({ loading: true });
        const tableData = await getCards(this.state.conveniosIds);
        this.setState({ loading: false, tableData });
    }

    async ignoreCardPrint(record) {

        const isOk = await mensagem.confirmar(`CUIDADO, pois esta rotina é irreversivel! Você tem certeza que quer ignorar a impressão do cartão de ${record.nome}?`);
        if (!isOk) {
            return;
        }

        this.setState({ loading: true });
        await ignorePrint(record.key);

        const tableData = this.state.tableData.filter(item => {
            return item.key !== record.key;
        });

        mensagem.openNotificationWithIcon('success', 'Ok!', `O cartão de ${record.nome} foi ignorado para impressão`, 3);
        this.setState({ tableData, loading: false });
    }

    async onTextGenerate() {
        const selectedRowKeys = this.state.selectedRowKeys;

        if (selectedRowKeys.length === 0) {
            message.warning({ content: 'Escolha quais cartões serão impressos', key });
            return;
        }

        this.setState({ loading: true });

        const list = [];
        for (let index = 0; index < selectedRowKeys.length; index++) {
            const item = selectedRowKeys[index];

            const card = this.state.tableData.filter((reg) => {
                return reg.key === item
            })[0];

            const text = `${unformatNumber(card.numero)},${card.nome.toUpperCase()},${card.validade},${card.convenio.toUpperCase()}`;
            list.push(text);
        }

        const element = document.createElement("a");
        const file = new Blob([list.join('\n')], { type: 'text/plain' });
        element.href = URL.createObjectURL(file);
        element.download = "to-print.txt";
        element.click();
        this.setState({ loading: false });
        const isOk = await mensagem.confirmar('O arquivo foi salvo corretamente?');
        if (!isOk) return;
        this.setState({ loading: true });

        // Marco como impressos
        let tableData = this.state.tableData;
        for (let index = 0; index < selectedRowKeys.length; index++) {
            const key = selectedRowKeys[index];
            await markPrinted(key);

            tableData = tableData.filter((reg) => {
                return reg.key !== key
            });
        }

        mensagem.openNotificationWithIcon('success', 'Tudo bem!', `O arquivo foi gerado com sucesso e os cartões selecionados foram marcados como impressos.`);
        this.setState({ tableData, selectedRowKeys: [], loading: false });


    }

    render() {

        // rowSelection object indicates the need for row selection
        const rowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                const list = [];
                for (let index = 0; index < selectedRows.length; index++) {
                    const key = selectedRows[index].key;
                    list.push(key);
                }
                this.setState({ selectedRowKeys: list });
            },
            getCheckboxProps: record => ({
                name: record.name,
            }),
        };

        return (
            <div>
                <PageLayout selectItem={"tools/cards/print"}>
                    <div className="containerCardsToPrint">
                        <label className="labelConvenios">Convênios: </label>
                        <Select
                            mode="multiple"
                            showSearch
                            filterOption={(input, option) =>
                                option.props.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled={this.state.loading}
                            optionFilterProp="children"
                            style={{ width: '500px' }}
                            placeholder='Selecione um ou mais convênios'
                            onChange={this.onSelectChange}
                            value={this.state.conveniosIds}
                        >{this.options}</Select>

                        <Button
                            className="btnFiltrar"
                            disabled={this.state.loading}
                            onClick={this.onFilterClick}
                            type="primary"
                        >Filtrar</Button>

                        <Button
                            className="btnGerarTxt"
                            disabled={this.state.loading || this.state.tableData.length === 0}
                            onClick={this.onTextGenerate}
                        >Gerar TXT</Button>

                    </div>

                    <label className='cardsToPrint'>{`Cartões para Impressão: ${this.state.tableData.length}`}</label>
                    <label className='cardsToPrint'>{`Cartões Selecionados: ${this.state.selectedRowKeys.length}`}</label>
                    <div className="tableCards">
                        <Table
                            pagination={{
                                defaultPageSize: 100,
                                position: 'both',
                                pageSizeOptions: ["50", "100", "150", "200", "250", "300"],
                                showSizeChanger: true,
                                locale: { items_per_page: "" }
                            }}
                            rowSelection={rowSelection}
                            columns={this.state.columns}
                            dataSource={this.state.tableData}
                            loading={this.state.loading}
                        >

                        </Table>
                    </div>
                </PageLayout>
            </div>
        )
    }

}

export default withRouter(CardsToPrint);

