import { getOwnerId } from '../../../components/auth/auth-provider';
import { formatNumber } from '../../../components/cartao/card-provider';
import beneficiarioDB from '../../../dataManager/dtmBeneficiario';
import cartaoDB from '../../../dataManager/dtmCartao';
import convenioDB from '../../../dataManager/dtmConvenio';


export async function getConvenios() {
  const ownerId = await getOwnerId();
  const convenios = await convenioDB.getByOwnerIdCustom(ownerId);
  return convenios
}

export async function ignorePrint(key) {

  await cartaoDB.update(key, {
    impressao: {
      ignorado: true,
      impresso: false,
    }
  });
}

export async function markPrinted(key) {

  await cartaoDB.update(key, {
    impressao: {
      ignorado: false,
      impresso: true,
    }
  });
}

export async function getCards(conveniosIds) {
  const ownerId = await getOwnerId();
  const data = [];
  const cards = await cartaoDB.getByConveniosIds(ownerId, conveniosIds, false);

  for (let index = 0; index < cards.length; index++) {
    const card = cards[index];
    console.log(card.beneficiarioId);

    if (card.status.toLowerCase().includes('cancelado')) continue;


    const bnf = await beneficiarioDB.getById(card.beneficiarioId);

    if (bnf === undefined) {
      continue;
    }

    const item = {
      key: card.key,
      numero: formatNumber(card.numero),
      nome: beneficiarioDB.format(bnf.nome, bnf.sobrenome),
      validade: card.validAt.toDate().toLocaleDateString(),
      convenio: card.convenio.nome,
    }
    data.push(item);
  }
  data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);
  return data;
}
