import React, { useDebugValue } from "react";
import { withRouter } from "react-router";
import { Layout, Dropdown, Divider, Avatar, Button, Tooltip } from "antd";
import history from "../../history";
import "./PageLayout.css";
import {
  currentUser,
  currentUserCol,
  logout,
  getUserType,
  isDebitPresent,
} from "../auth/auth-provider";
import funcs from "../funcs/funcs";
import MenuDebito from "./MenuDebito";
import MenuCashBack from "./MenuCashback";
import MenuFidelidade from "./MenuFidelidade";
import "./LayoutCSS";
import { getLogoClass } from "./LayoutCSS";
import Pusher from 'pusher-js';
import mensagem from "../messages/message";

const { Header, Content } = Layout;

const user = currentUser();
const pusher = new Pusher('779a79971a1347f6f8e0', { cluster: 'us2' });
const channel = pusher.subscribe(user ? user.uid : '');

class PageLayout extends React.Component {
  state = {
    versao: "0.1.4 | build 1.2021-08-04",
    collapsed: false,
    friendlyName: "",
    logoClass: "logoMoub",
    classLabelFooter: "footerVertteLabelNormal",
    photoURL: "",
    userType: "Gestor",
    permissoes: {
      gestores: true,

      // meus clientes:
      administradores: true,
      usuarios: true,
      beneficiarios: true,
      pedidos: true,
      estornoDebito: true,
      extrato: true,
    },
  };

  constructor(props) {
    super(props);
    this.onMenuClick = this.onMenuClick.bind(this);
  }

  async componentDidMount() {
    this.setState({ logoClass: getLogoClass() });

    const user = currentUser();
    const userType = await getUserType();
    let userCol = currentUserCol();

    const debitPresent = await isDebitPresent();
    const email = user.email.substring(0, user.email.indexOf("@"));
    let friendlyName = user.displayName ? `${user.displayName}` : `${email}`;

    if (user.displayName) {
      friendlyName = friendlyName
        .split(" ")[0]
        .toLocaleLowerCase()
        .replace(/\b\w/g, (l) => l.toUpperCase());
    }

    friendlyName = friendlyName.replace(
      "AdministradorUsuario",
      "Usuário Administrador"
    );

    if (userType === "Gestor" || userType === "AdministradorUsuario") {
      // Configuração dos relatórios:
      let relatorios = userCol
        ? userCol.permissoes.some(function (v) {
          return v.indexOf("relatorios") >= 0;
        })
        : true;
      let relatoriosMovimentacao = userCol
        ? (await funcs.podeAbrirRelatorio(
          userCol,
          "relatorios-movimentacao"
        )) && debitPresent
        : debitPresent;
      let relatoriosHistoricoMovimentacao = userCol
        ? (await funcs.podeAbrirRelatorio(
          userCol,
          "relatorios-historico-movimentacao"
        )) && debitPresent
        : debitPresent;
      let relatoriosSaldo = userCol
        ? (await funcs.podeAbrirRelatorio(userCol, "relatorios-saldos")) &&
        debitPresent
        : debitPresent;
      let extrato = userCol
        ? (await funcs.podeAbrirRelatorio(userCol, "relatorios-extrato")) &&
        debitPresent
        : debitPresent;

      // Quando o usuário tem acesso a todos os relatórios, as opções filhos não são gravados.
      // Por isso, faço o ajuste aqui nos filhos

      if (
        relatorios &&
        !relatoriosMovimentacao &&
        !relatoriosHistoricoMovimentacao &&
        !relatoriosSaldo
      ) {
        relatoriosMovimentacao = debitPresent;
        relatoriosHistoricoMovimentacao = debitPresent;
        relatoriosSaldo = debitPresent;
        extrato = debitPresent;
      }

      this.setState({
        permissoes: {
          gestores: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("gestores") >= 0;
            }) && debitPresent
            : debitPresent,
          administradores: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("administradores") >= 0;
            }) && debitPresent
            : true,
          usuarios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("usuarios") >= 0;
            }) && debitPresent
            : debitPresent,
          convenios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("convenios") >= 0;
            })
            : true,
          beneficiarios: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("beneficiarios") >= 0;
            }) && debitPresent
            : debitPresent,
          pedidos: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("pedidos") >= 0;
            }) && debitPresent
            : debitPresent,
          estabelecimentos: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("estabelecimentos") >= 0;
            }) && debitPresent
            : debitPresent,
          estornoDebito: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("estornoDebito") >= 0;
            }) && debitPresent
            : debitPresent,

          // Relatórios
          relatorios,
          relatoriosMovimentacao,
          relatoriosHistoricoMovimentacao,
          relatoriosSaldo,
          extrato,

          configuracoes: userCol
            ? userCol.permissoes.some(function (v) {
              return v.indexOf("configuracoes") >= 0;
            }) && debitPresent
            : debitPresent,
        },
      });
    }

    if (userType === "Administrador") {
      this.setState({
        permissoes: {
          gestores: false,
          administradores: false,
          usuarios: true,
          convenios: true,
          beneficiarios: true,
          pedidos: true,
          estabelecimentos: true,
          configuracoes: true,
          estornoDebito: false,
        },
      });
    }

    this.setState({
      friendlyName,
      userType,
      photoURL: user.photoURL
        ? user.photoURL
        : "https://storage.googleapis.com/bluve-bucket/base_img_avatar.png",
    });

    channel.bind('reprocess-charge-concluded', (data) => {
      if (data.code === 201) {
        mensagem.openNotificationWithIcon('success', 'Sucesso', data.message);
      } else {
        mensagem.openNotificationWithIcon('error', 'Erro', data.message);
      }
    })
  }

  onMenuClick(item) {
    // Para que o usuário veja o item ficar azul antes do redirect.
    // Não remover
    setTimeout(() => {
      history.push(`/${item.key}`);
      history.go();
    }, 300);
  }

  handleMenuClick(e) {
    console.clear();
    console.log(e);
    if (e.key === "menuSair") {
      logout();
      history.push("/login");
      history.go();
    }
  }

  render() {
    const menu = <Profile userType={this.state.userType}></Profile>;

    return (
      <React.Fragment>
        <Layout>
          <Layout style={{ height: "100vh" }}>
            <Header
              style={{
                padding: 0,
                display: "flex",
                height: "6.25rem",
                backgroundColor: "#143361",
              }}
            >
              <div className="logo">
                <div className={this.state.logoClass}>
                  <img alt="Moub"></img>
                </div>
              </div>

              <div style={{ width: "70%" }}>
                <MenuDebito
                  onMenuClick={this.onMenuClick}
                  selectItem={this.props.selectItem}
                ></MenuDebito>
                <MenuCashBack
                  onMenuClick={this.onMenuClick}
                  selectItem={this.props.selectItem}
                ></MenuCashBack>
                <MenuFidelidade
                  onMenuClick={this.onMenuClick}
                  selectItem={this.props.selectItem}
                ></MenuFidelidade>

              </div>

              <Dropdown overlay={menu} trigger={["click"]}>
                <Avatar
                  className="profileAvatar"
                  size={44}
                  src={this.state.photoURL}
                  style={{ margin: "1.75rem 1.25rem", marginLeft: "auto" }}
                />
              </Dropdown>
            </Header>

            <Content style={{ margin: "0.625rem" }}>
              <div
                style={{
                  background: "#fff",
                  minHeight: "100%",
                  padding: "1.25rem",
                  borderRadius: "0.75rem",
                }}
              >
                {this.props.children}
              </div>
            </Content>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

class Profile extends React.Component {
  state = {
    photoUrl: "",
    userType: "",
    userName: "",
    userEmail: "",
  };

  abbreviate(name) {
    var splitName = name.split(" ");
    if (splitName.length >= 3) {
      for (var i = 1; i < splitName.length - 1; i++) {
        splitName[i] = splitName[i].charAt(0) + ".";
      }
    }
    return splitName.join(" ");
  }

  UNSAFE_componentWillMount() {
    const user = currentUser();
    let userType = this.props.userType
      ? this.props.userType.replace(
        "AdministradorUsuario",
        "Usuário Administrador"
      )
      : "";
    if (user.displayName) {
      user.displayName = this.abbreviate(user.displayName);
      user.displayName = user.displayName
        .toLocaleLowerCase()
        .replace(/(?:^|\s)\S/g, function (a) {
          return a.toUpperCase();
        });
    }
    this.setState({
      photoURL: user.photoURL
        ? user.photoURL
        : "https://storage.googleapis.com/bluve-bucket/base_img_avatar.png",
      userType,
      userName: user.displayName
        ? user.displayName
        : user.email.substring(0, user.email.indexOf("@")),
      userEmail: user.email,
      versao: "0.2.5 | build 3.2021-07-20",
    });
  }

  btnSairClick() {
    logout();
    history.push("/login");
    history.go();
  }

  btnPerfilClick() {
    history.push("/perfil");
    history.go();
  }

  render() {
    return (
      <div className="card-profile">
        <img
          className="img-profile"
          src={this.state.photoURL}
          alt={this.state.userName}
        />
        <h1>{this.state.userName}</h1>
        <p className="title-profile">{this.state.userType}</p>

        <Tooltip placement="top" title={this.state.userEmail}>
          <p className="pspan">{this.state.userEmail} </p>
        </Tooltip>

        <Divider></Divider>

        <p>
          <Button
            type="primary"
            className="buttonProfile"
            onClick={this.btnPerfilClick}
          >
            Perfil
          </Button>
        </p>
        <p>
          <Button className="buttonProfileExit" onClick={this.btnSairClick}>
            Sair
          </Button>
        </p>

        {/* <label><i>4C Retail Solutions</i></label>
                <label><i>{this.state.versao}</i></label> */}
      </div>
    );
  }
}

export default withRouter(PageLayout);
