import React, { Component } from "react";
import { Menu } from "antd";
import {
  getUserType,
  isDebitPresent,
  isLoyaltyPresent,
} from "../auth/auth-provider";
import ReleaseNotes from "./ReleaseNotes";

const { SubMenu } = Menu;

class MenuFidelidade extends Component {
  state = {
    userType: "Gestor",
    debitPresent: undefined,
    loyaltyPresent: undefined,
    permissoes: {
      gestores: true,

      // meus clientes:
      administradores: true,
      usuarios: true,
      beneficiarios: true,
      pedidos: true,
      estornoDebito: true,
      showReleaseNotes: false,
    },
  };

  async componentDidMount() {
    const userType = await getUserType();
    let { permissoes } = this.state;

    if (userType === "Administrador") {
      permissoes = {
        gestores: false,
        administradores: true,
        usuarios: true,
        convenios: true,
        beneficiarios: true,
        pedidos: true,
        estabelecimentos: true,
        configuracoes: true,
        estornoDebito: true,
      };
    }

    const debitPresent = await isDebitPresent();
    const loyaltyPresent = await isLoyaltyPresent();

    this.setState({
      permissoes,
      debitPresent,
      loyaltyPresent,
      userType
    });
  }

  constructor(props) {
    super(props);
    this.showReleaseNotesElement = React.createRef();
    this.showReleaseNotes = this.showReleaseNotes.bind(this);
  }

  showReleaseNotes(showReleaseNotes) {
    this.setState({ showReleaseNotes });
  }

  render() {
    let dashMenuIsPresent = document.getElementById("dashboardMenu");
    if (!dashMenuIsPresent) {
      dashMenuIsPresent = document.getElementById("dashboardMenuCashBack");
    }

    let minhaRedeMenu = document.getElementById("minhaRedeMenu");
    if (!minhaRedeMenu) {
      minhaRedeMenu = document.getElementById("minhaRedeMenuCashback");
    }

    let configuracoesMenu = document.getElementById("configuracoesMenu");
    if (!configuracoesMenu) {
      configuracoesMenu = document.getElementById("configuracoesMenuCashback");
    }

    return (
      <div>
        <ReleaseNotes
          ref={this.showReleaseNotesElement}
          visible={this.state.showReleaseNotes}
          onCancel={this.showReleaseNotes}
        ></ReleaseNotes>

        {this.state.loyaltyPresent ? (
          <Menu
            defaultSelectedKeys={[this.props.selectItem]}
            defaultOpenKeys={[this.props.openMenu]}
            onClick={this.props.onMenuClick}
            theme="dark"
            mode="horizontal"
            style={{
              fontWeight: 600,
              fontSize: "1rem",
              backgroundColor: "transparent",
              lineHeight: "6.25rem",
            }}
          >
            {!dashMenuIsPresent ? (
              <Menu.Item key="dashboard" id="dashboardMenuFodeçodade">
                <span>Dashboard</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {!minhaRedeMenu ? (
              <SubMenu
                id="minhaRedeMenuFidelidade"
                key="meusClientes"
                title={
                  <span>
                    <span>Minha Rede</span>
                  </span>
                }
              >
                <Menu.Item className="antSubMenu" key="rede">
                  <span>Rede</span>
                </Menu.Item>

                <Menu.Item className="antSubMenu" key="lojas">
                  <span>Lojas</span>
                </Menu.Item>

                <Menu.Item className="antSubMenu" key="beneficiarios">
                  <span>Beneficiários</span>
                </Menu.Item>

                {/* <Menu.Item className="antSubMenu" key="administradores-usuarios">
                  <span>Usuários</span>
                </Menu.Item> */}
              </SubMenu>
            ) : (
              ""
            )}

            {!this.state.debitPresent ? (
              <Menu.Item key="configuracoes" id="configuracoesMenuFidelidade">
                <span>Configurações</span>
              </Menu.Item>
            ) : (
              ""
            )}

            {!this.state.debitPresent ? (
              <SubMenu
                key="helpMenu"
                title={
                  <span>
                    <span>Ajuda</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/releasenotes"
                  onClick={(e) => this.showReleaseNotes(true)}
                >
                  Notas da Versão
                </Menu.Item>
              </SubMenu>
            ) : (
              ""
            )}
          </Menu>
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default MenuFidelidade;
