import React from 'react';
import {
  Form,
  Steps,
  Button,
  Divider,
  DatePicker,
  InputNumber,
  message,
  Select,
  Progress,
  Icon,
} from 'antd';
import EditableFormTable from '../../../../editableTable/editableTable';
import CurrencyInput from 'react-currency-input';
import './PedidoCRUD.css';
import GerenciaNet from '../../../../gerenciaNet/gerencia-net-provider';
import mensagem from '../../../../../components/messages/message';
import beneficiarioDB from '../../../../../dataManager/dtmBeneficiario';
import { currentUser, getOwnerId, getUserType } from '../../../../auth/auth-provider';
import administradorDB from '../../../../../dataManager/dtmAdministrador';
import pedidoDB from '../../../../../dataManager/dtmPedido';
import moment from 'moment';
import { POS_PAGO, PRE_PAGO } from '../../../../funcs/constants';

const dateFormat = 'DD/MM/YYYY';
const { RangePicker } = DatePicker;

const { Option } = Select;

const { Step } = Steps;
const steps = [
  {
    title: 'Dados',
  },
  {
    title: 'Beneficiários',
  },
  {
    title: 'Resumo',
  },
  {
    title: 'Processamento',
  },
];


class PedidoCRUD extends React.Component {
  state = {
    percent: 0,
    formatProgress: '0%',
    novoPedidoId: '',
    stateEnabled: true,
    showCredDeb: false,
    applyValue: true,
    current: 0,
    loading: true,
    loadingRecarregarTodos: false,
    loadingRecarregarExcluidos: false,
    convenios: [],
    conveniosOriginal: [],
    beneficiarios: [],
    selecteds: [],
    beneficiariosComValor: 0,
    tipoPedido: POS_PAGO,
    periodo: {
      inicial: undefined,
      final: undefined,
      day: 1,
    },
    dataMine: {
      preReloadDataSource: [],
      originalDataSource: [],
      dataSource: [],
      deletedDataSource: [],
      valorTotal: 0,
    },
    valorTodos: 0,
    limiteCreditoConvenio: undefined,
    limiteCreditoConvenioEmReais: undefined,
    limiteCreditoConvenioAtingido: false
  };

  constructor(props) {
    super(props);
    this.cancelClick = this.cancelClick.bind(this);
    this.postCallBack = this.postCallBack.bind(this);
    this.deleteCallBack = this.deleteCallBack.bind(this);
    this.valueTodosChange = this.valueTodosChange.bind(this);
    this.tipoPedidoChange = this.tipoPedidoChange.bind(this);
    this.applyValue = this.applyValue.bind(this);
    this.next = this.next.bind(this);
    this.prev = this.prev.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onDayChange = this.onDayChange.bind(this);
    this.salvarPedido = this.salvarPedido.bind(this);
    this.pedidoDuplicado = this.pedidoDuplicado.bind(this);
    this.btnRecarregarBeneficiarios = this.btnRecarregarBeneficiarios.bind(this);
    this.retornarBeneficiariosForaListaOriginal = this.retornarBeneficiariosForaListaOriginal.bind(this);
    this.editableTableElement = React.createRef();
    this.handleCanClose = this.handleCanClose.bind(this);
    this.selectHandleChange = this.selectHandleChange.bind(this);
    this.criarOuAtualizarPedido = this.criarOuAtualizarPedido.bind(this);
    this.buildComboConvenios = this.buildComboConvenios.bind(this);
    this.gerarBoleto = this.gerarBoleto.bind(this);
  }

  async componentDidMount() {
    const userCol = await getUserType();
    const user = currentUser()
    // Literalmente um puxadinho para resolver temporariamente o problema!
    const showCredDeb = userCol === 'Gestor' || user.email === 'rhbruno@reginasupermercados.com.br';

    const convenios = await this.buildComboConvenios(this.props.record.convenios);
    const selecteds = [];

    const editMode = this.props.editMode;
    const record = this.props.record;

    if (editMode && record) {
      selecteds.push(record.convenio.id);

      let valorTodos = 0;
      if (typeof record.valorBeneficio === 'string') {
        valorTodos = Number(record.valorBeneficio.replace(/[^0-9.-]+/g, "") / 100);
      }
      else {
        valorTodos = record.valorBeneficio;
      }
      const periodo = {
        inicial: moment(record.dataInicial),
        final: moment(record.dataFinal),
        day: record.dataCredito.getDate(),
      };

      //const stateEnabled = this.props.record.rascunho && this.props.record.boleto === undefined;
      //(this.props.editMode && this.state.tipoPedido === PRE_PAGO) || 
      let stateEnabled = true;
      if (record.rascunho && record.boleto) {
        stateEnabled = false;
      }

      if (!record.rascunho) {
        stateEnabled = false;
      }

      // Acho que eu não preciso mais tratar pré-pago como pedido fechado... 
      // Muita mudança, aff, Thor!
      // if (record.tipoPedido === PRE_PAGO) {
      //   stateEnabled = false;
      // }
      this.setState({ selecteds, valorTodos, periodo, tipoPedido: this.props.record.tipoPedido, stateEnabled });
    } else {
      // Só seleciono automaticamente se o length for igual a 1 
      // para evitar erros de cadastramento
      if (this.props.record.convenios.length === 1) {
        selecteds.push(this.props.record.convenios[0].key);
        this.setState({ selecteds });
      }
    }

    this.setState({ showCredDeb, convenios, conveniosOriginal: this.props.record.convenios, loading: false });
  }

  async buildComboConvenios(convs) {
    const convenios = [];
    const { Option } = Select;
    for (let index = 0; index < convs.length; index++) {
      const item = convs[index];
      convenios.push(<Option key={item.key}>{item.nomeFantasia}</Option>);
    }
    return convenios;
  }

  selectHandleChange(value) {
    const selecteds = [];
    selecteds.push(value);
    let valorTodos = 0;
    if (this.state.tipoPedido === PRE_PAGO) {
      const convenio = this.state.conveniosOriginal.filter((convenio) => {
        return convenio.key === value;
      })[0];

      valorTodos = convenio.credito.valor;
    }

    const convenioLimiteCredito = this.props.record.convenios.filter((cnvn) => {
      return cnvn.key === value;
    })[0];

    let limiteCreditoConvenioEmReais = undefined;
    if (convenioLimiteCredito.limiteCreditoConvenio) {
      limiteCreditoConvenioEmReais = pedidoDB.formatarMoeda(convenioLimiteCredito.limiteCreditoConvenio/100);
    }

    this.setState({ 
      selecteds, 
      valorTodos, 
      applyValue: true, 
      limiteCreditoConvenio: convenioLimiteCredito.limiteCreditoConvenio,
      limiteCreditoConvenioEmReais
    });
  }


  daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  async next() {
    const current = this.state.current + 1;
    let beneficiariosComValor = 0;

    if (current === 1 && !this.props.editMode) {

      if (this.state.selecteds.length === 0) {
        message.warning("Informe o convênio");
        return;
      }


      if (!this.state.periodo.inicial) {
        message.warning("Informe o período do pedido");
        return;
      }

      const hoje = new Date();
      if (this.state.periodo.inicial._d <= hoje || this.state.periodo.final._d <= hoje) {
        message.warning("O período não pode ser inferior ou igual a hoje.");
        return;
      }

      if (this.state.periodo.inicial._d.getMonth() !== this.state.periodo.final._d.getMonth()) {
        message.warning("Os pedidos devem ser feitos dentro de um mesmo mês.");
        return;
      }
      const day = this.daysInMonth(this.state.periodo.inicial._d.getMonth(), this.state.periodo.inicial._d.getFullYear());
      if (this.state.periodo.day > day) {
        message.warning("Dia para pagamento maior que o total de dias no mês.");
        return;
      }

      if (this.state.periodo.day <= hoje.getDate() &&
        this.state.periodo.inicial._d.getMonth() <= hoje.getMonth() &&
        this.state.periodo.inicial._d.getFullYear() <= hoje.getFullYear()) {
        message.warning("Dia para pagamento não pode ser inferior ou igual a hoje");
        return;
      }

      this.setState({ loading: true });
      const ownerId = await getOwnerId();
      const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, this.state.selecteds[0]);

      this.state.beneficiarios = beneficiarios;
      this.state.dataMine.originalDataSource = beneficiarios;

      this.applyValue();
    } else if (current === 1 && this.props.editMode) {
      this.setState({ loading: true });

      const beneficiarios = await pedidoDB.getBeneficiariosDoPedido(this.props.record.key);
      beneficiarios.forEach(beneficario => {
        beneficario.nome = beneficario.beneficiario;
        beneficario.sobrenome = beneficario.sobrenome ? beneficario.sobrenome : '';
        delete beneficario.beneficiario;
      });

      this.state.beneficiarios = beneficiarios;
      this.state.dataMine.originalDataSource = beneficiarios;
      this.applyValue();
    } else if (current === 2) {
      beneficiariosComValor = this.state.dataMine.dataSource.filter((item) => {
        return item.valor !== 0
      }).length;

      if (beneficiariosComValor === 0) {
        message.warning("Não há beneficiários com valor");
        return;
      }
    }
    const limiteCreditoConvenioAtingido = this.state.dataMine.valorTotal > (this.state.limiteCreditoConvenio / 100);
    this.setState({ current, beneficiariosComValor, limiteCreditoConvenioAtingido, loading: false });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  findCommonElements2(array1, array2) {

    // Loop for array1 
    for (let i = 0; i < array1.length; i++) {

      // Loop for array2 
      for (let j = 0; j < array2.length; j++) {

        // Compare the element of each and 
        // every element from both of the 
        // arrays 
        if ((array1[i].key === array2[j].key) && array2[j].valorEmCentavos > 0) {
          // Return if common element found 
          return true;
        }
      }
    }

    // Return if no common element exist 
    return false;
  }

  async pedidoDuplicado(convenioId) {

    const dataIni = this.state.periodo.inicial._d.toISOString().slice(0, 10) + ' 00:00:00';
    const dataFim = this.state.periodo.final._d.toISOString().slice(0, 10) + ' 23:59:59';

    const pedidos = await pedidoDB.getByConvenioIdPeriodo(convenioId, dataIni, dataFim);

    if (pedidos.length === 0) {
      return false;
    }

    let duplicado = false;
    for (let index = 0; index < pedidos.length; index++) {
      const pedido = pedidos[index];

      // if (!pedido.boleto && pedido.rascunho === true) {
      //   return false;
      // }


      if (pedido.boleto && pedido.boleto.status !== 'canceled') {
        const beneficarios = await pedidoDB.getBeneficiariosDoPedido(pedido.key);
        if (!duplicado) {
          duplicado = this.findCommonElements2(beneficarios, this.state.dataMine.dataSource);
          if (duplicado) {
            return true;
          }
        }
      }
    }
    return duplicado;
  }

  handleCanClose(canClose) {
    if (this.props.handleCanClose) {
      this.props.handleCanClose(canClose);
    }
  }

  async criarOuAtualizarPedido(item, criar = true) {
    if (criar) {
      const res = await pedidoDB.add(item);
      if (!res.recorded) {
        mensagem.openNotificationWithIcon('error', 'Erro', 'Erro ao tentar salvar o pedido');
        return false;
      }
      this.state.novoPedidoId = res.id;
      await pedidoDB.addBeneficiariosPedido(res.id, this.state.dataMine.dataSource);
    } else {

      const res = await pedidoDB.update(this.props.record.key, item);
      if (!res.updated) {
        mensagem.openNotificationWithIcon('error', 'Erro', 'Erro ao tentar atualizar o pedido');
        return false;
      }

      await pedidoDB.updateBeneficiariosPedido(
        this.props.record.key,
        this.state.dataMine.dataSource,
        this.state.dataMine.deletedDataSource);
    }
    return true;
  }

  async gerarBoleto(convenioSelecionado, administrador, item) {
    message.info('Gerando boleto...');
    const gerencia = new GerenciaNet();

    let data = undefined;
    try {
      data = await gerencia.getToken(convenioSelecionado.key);
    } catch (error) {
      message.error('Erro ao tentar gerar o boleto');
      this.setState({ loading: false, current: this.state.current - 1 });
      return undefined;
    }

    if (!data.token) {
      mensagem.openNotificationWithIcon('error', 'Erro', 'Houve um problema ao gerar o boleto. Tente novamente');
      this.setState({ loading: false, current: this.state.current - 1 });
      return undefined;
    }

    try {
      const resp = await gerencia.createCharge(
        Number(item.valorTotal),
        `Benefício de ${item.dataInicial.split(' ')[0]} até ${item.dataFinal.split(' ')[0]}`,
        convenioSelecionado.prazo,
        convenioSelecionado.razaoSocial,
        convenioSelecionado.cnpj,
        convenioSelecionado.email ? convenioSelecionado.email : item.convenio.email,
        administrador.endereco.telefone,
        `${item.totalBeneficiarios} beneficiários`,
        data.token
      )
      return resp.boleto;

    } catch (error) {
      debugger;
      this.setState({ loading: false, current: this.state.current - 1 });
      message.error(error.message);
      return undefined;
    }
  }

  async salvarPedido(criarBoleto) {

    if (this.props.editMode && !this.state.stateEnabled) {
      this.props.handleCancel(undefined);
      return;
    }

    if (criarBoleto) {
      let isOk = await mensagem.confirmar('Ao fechar seu pedido, NÃO será mais possível editá-lo. Você revisou todo o seu pedido e deseja realmente prosseguir?');
      if (!isOk) {
        return;
      }
    }

    const current = this.state.current + 1;
    this.handleCanClose(false);
    this.setState({ percent: 0, formatProgress: '0%', current, loading: true });

    const dataIni = this.state.periodo.inicial._d.toISOString().slice(0, 10) + ' 00:00:00';
    let dataFim = this.state.periodo.final._d.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 10).replaceAll('/', '-');
    const spl = dataFim.split('-');
    dataFim = `${spl[2]}-${spl[1]}-${spl[0]} 23:59:59`;
    // let dataFim = this.state.periodo.final._d; //.toISOString(); //.slice(0, 10) + ' 23:59:59';
    // dataFim.setDate(dataFim.getDate() - 1);
    // dataFim = dataFim.toISOString().slice(0, 10) + ' 23:59:59';
    // const dataIni = this.state.periodo.inicial._d.toLocaleString("pt-BR", { timeZone: "America/Sao_Paulo" }).slice(0, 10).replaceAll('/', '-') + ' 00:00:00';

    const dataCredito = new Date(this.state.periodo.inicial._d.getFullYear(), this.state.periodo.inicial._d.getMonth(), this.state.periodo.day);

    const complemento = !this.props.editMode ? 'criado' : 'atualizado';

    let valorBeneficio = this.state.valorTodos;
    if (typeof valorBeneficio !== 'number') {
      Number(this.state.valorTodos.replace('R$ ', '').split('.').join('').split(',').join('.'));
    }

    // Pego aqui o email do administrador do convênio selecionado
    const convenioSelecionado = this.props.record.convenios.filter(item => {
      return item.key === this.state.selecteds[0];
    })[0];

    this.setState({ percent: 15, formatProgress: '15%' });
    const administrador = await administradorDB.getById(convenioSelecionado.administrador.id);
    const ownerId = await getOwnerId();

    const item = {
      tipoPedido: this.state.tipoPedido,
      ownerId,
      convenio: {
        id: this.state.selecteds[0],
        nome: convenioSelecionado.nomeFantasia,
        email: administrador.email,
        gerarCobranca: convenioSelecionado.gerarCobranca,
      },
      administrador: {
        id: administrador.key,
        nome: administrador.nomeFantasia,
      },
      dataInicial: dataIni,
      dataFinal: dataFim,
      dataCredito,
      creditado: false,
      valorTotal: this.state.dataMine.valorTotal.toFixed(2),
      totalBeneficiarios: this.state.beneficiariosComValor,
      valorBeneficio,
    }

    console.clear();
    const duplicado = await this.pedidoDuplicado(convenioSelecionado.key);

    if (duplicado) {
      mensagem.openNotificationWithIcon('error', 'Erro', 'Um ou mais beneficiários já incluído (s) em outro pedido no mesmo período');
      this.setState({ loading: false, percent: 0, formatProgress: '0%', current: this.state.current - 1 });
      this.handleCanClose(true);
      return;
    }

    this.setState({ percent: 50, formatProgress: '50%' });

    if (!convenioSelecionado.gerarCobranca || this.state.tipoPedido === PRE_PAGO) {
      let charge_id = '';

      if (criarBoleto) {
        let pedido = {};
        while (pedido !== undefined) {
          const nr = Math.floor(1000000 + Math.random() * 900000);
          charge_id = this.state.tipoPedido === PRE_PAGO ? `CRED-${nr}` : `OC-${nr}`;
          pedido = await pedidoDB.getByChargeId(charge_id);
        }

        item['boleto'] = {
          barcode: '00000.00000 00000.000000 00000.000000 0 00000000000000',
          charge_id: charge_id,
          creditoAutomaticoProcessado: false,
          status: criarBoleto ? 'paid' : 'waiting',
        }
      }
      // Naturalmente, ele pode cancelar esse pedido até um dia antes do vencimento
      item.podeCancelar = true;
      item.rascunho = !criarBoleto;
      this.setState({ percent: 80, formatProgress: '80%' });
      const isOk = await this.criarOuAtualizarPedido(item, !this.props.editMode);

      if (!isOk) {
        this.setState({ loading: false, current: this.state.current - 1, percent: 0 });
        return;
      }

      this.setState({ loading: false });
      item.status = criarBoleto ? 'Pago' : 'Rascunho';
      item.valorTotal = pedidoDB.formatarMoeda(Number(item.valorTotal));
      if (!item.key) {
        item.key = this.state.novoPedidoId ? this.state.novoPedidoId : this.props.record.key;
      }
      this.setState({ percent: 100, formatProgress: 'Pronto' });
      setTimeout(() => {
        this.props.handleOk(item);
        mensagem.openNotificationWithIcon('success', 'Perfeito!', `Seu ${item.boleto ? 'pedido' : 'rascunho'} foi ${complemento} com sucesso!`);
      }, 1500);
    }
    else {
      let boleto = undefined;
      if (criarBoleto === true) {
        this.setState({ percent: 65, formatProgress: '65%' });
        boleto = await this.gerarBoleto(convenioSelecionado, administrador, item);
        if (!boleto) {
          return;
        }
        message.info('Beleza! Seu boleto foi gerado com sucesso, aguarde agora a finalização do pedido...');
      } else {
        message.info('Salvando rascunho...');
      }

      setTimeout(async () => {
        if (boleto) {
          boleto['creditoAutomaticoProcessado'] = false;
          item['boleto'] = boleto;
        }

        item['rascunho'] = !criarBoleto;
        this.setState({ percent: 80, formatProgress: '80%' });
        const isOk = await this.criarOuAtualizarPedido(item, !this.props.editMode);

        if (!isOk) {
          this.setState({ loading: false, current: this.state.current - 1, percent: 0, formatProgress: '0%' });
          return;
        }

        item.valorTotal = pedidoDB.formatarMoeda(Number(item.valorTotal));
        item.status = criarBoleto ? 'Pendente' : 'Rascunho';
        if (!item.key) {
          item.key = this.state.novoPedidoId ? this.state.novoPedidoId : this.props.record.key;
        }

        this.setState({ percent: 100, formatProgress: 'Pronto' });
        setTimeout(() => {
          this.props.handleOk(item);
          mensagem.openNotificationWithIcon('success', 'Perfeito!', `Seu ${item.boleto ? 'pedido' : 'rascunho'} foi ${complemento} com sucesso!`);
        }, 1500);
      }, 4000);
    }
  }

  onDateChange(value) {
    this.setState({
      periodo: {
        inicial: value[0],
        final: value[1],
        day: value[0]._d.getDate(),
      }
    });
  }

  onDayChange(value) {
    this.setState({
      periodo: {
        inicial: this.state.periodo.inicial,
        final: this.state.periodo.final,
        day: value
      }
    });
  }

  applyValue(reloadValues, preserveOriginalValues) {
    if (!this.state.applyValue) {
      return;
    }

    this.setState({ applyValue: false });

    let valor = this.state.valorTodos;
    if (typeof this.state.valorTodos !== 'number') {
      valor = this.state.valorTodos.replace('R$ ', '');
    }

    const lista = this.state.beneficiarios;
    let benecificiarios = [];
    if (!this.props.editMode || reloadValues) {
      for (let i = 0; i < lista.length; i++) {
        const posPago = this.state.tipoPedido === POS_PAGO;
        const creditoConfigurado = lista[i].credito;
        const creditoAtivo = !posPago && (!lista[i].credito || lista[i].credito.ativo === true);
        const inserir = posPago || creditoAtivo;

        if (inserir) {
          const vlr = posPago || !creditoConfigurado ? valor : lista[i].credito.valor;
          const vlrEmCentavos = posPago || !creditoConfigurado ? valor * 100 : lista[i].credito.valorEmCentavos;

          if (preserveOriginalValues && !lista[i].valor) {
            const preReloadList = this.state.dataMine.preReloadDataSource;
            const bnf = preReloadList.filter(item => {
              return item.key === lista[i].key;
            })[0];

            lista[i].valor = bnf.valor;
            lista[i].valorEmCentavos = bnf.valorEmCentavos;

          } else {
            lista[i].valor = vlr.replace('R$ ', '');
            lista[i].valorEmCentavos = vlrEmCentavos;
          }
          benecificiarios.push(lista[i]);
        }
      }
    } else if (this.props.editMode) { // && this.state.tipoPedido === PRE_PAGO) {
      benecificiarios = this.state.beneficiarios;
    }

    if (this.state.tipoPedido === POS_PAGO) {
      this.postCallBack(benecificiarios);
    }
    else {
      const dataMine = this.state.dataMine;
      dataMine.originalDataSource = benecificiarios;
      this.postCallBack(benecificiarios, dataMine);
    }
  }

  cancelClick() {
    this.props.handleCancel(undefined);
  }

  postCallBack(data) {
    let valorTotal = 0;
    for (let i = 0; i < data.length; i++) {
      let valor = data[i].valor ? data[i].valor : 0;
      if (typeof valor === 'string') {
        valor = Number(data[i].valor.replace('R$ ', '').split('.').join('').split(',').join('.'));
        data[i].valorEmCentavos = Math.round(Number(data[i].valor.replace('R$ ', '').replace('.', '|').replace(',', '.').replace('|', '')) * 100);
      }
      valorTotal += valor;
    }

    const dataMine = {
      deletedDataSource: this.state.dataMine.deletedDataSource,
      originalDataSource: this.state.dataMine.originalDataSource,
      dataSource: data,
      valorTotal,
      convenio: this.state.dataMine.convenio,
      canEditValue: this.state.tipoPedido === POS_PAGO,
    }
    this.setState({ dataMine });
  }

  deleteCallBack(regKey) {
    if (!regKey) {
      return;
    }
    const dataMine = this.state.dataMine;
    dataMine.deletedDataSource.push(regKey);

    // https://stackoverflow.com/a/30341560/1347355    
    // this.setState({ dataMine });
    this.setState(currentState => {
      currentState.someProp++;
      return currentState;
    });
  }

  valueTodosChange(value) {
    this.setState({ valorTodos: value, applyValue: true });
  }

  async tipoPedidoChange(tipoPedido) {
    let convenios = [];
    if (tipoPedido === POS_PAGO) {
      convenios = this.state.conveniosOriginal;
    } else {
      convenios = this.state.conveniosOriginal.filter((convenio) => {
        return convenio.credito ? convenio.credito.ativo === true : false;
      });
    };
    convenios = await this.buildComboConvenios(convenios);
    this.setState({ tipoPedido, convenios, selecteds: [], valorTodos: 0, applyValue: true });
  }

  async retornarBeneficiariosForaListaOriginal(beneficiarios) {
    const foraFaixa = [];
    for (let index = 0; index < beneficiarios.length; index++) {
      console.clear();
      const beneficiario = beneficiarios[index];
      console.log(beneficiario.key);

      const hasData = this.state.dataMine.originalDataSource.filter(item => item.key === beneficiario.key)[0];
      if (!hasData) {
        beneficiario.added = true;
        beneficiario.valor = this.state.valorTodos
        foraFaixa.push(beneficiario);
      }
    }
    return foraFaixa;
  }

  async btnRecarregarBeneficiarios(recarregarExcluidos) {
    const msg = !recarregarExcluidos ? 'Deseja recarregar os beneficíarios? Essa opção irá zerar os valores que já possam ter sido digitados para cada beneficiários na lista.' : 'Deseja carregar APENAS os beneficíarios excluídos?';
    let isOk = await mensagem.confirmar(msg);
    if (!isOk) {
      return;
    }
    this.setState({ loadingRecarregarTodos: !recarregarExcluidos, loadingRecarregarExcluidos: recarregarExcluidos });


    setTimeout(async () => {
      const ownerId = await getOwnerId();
      const beneficiarios = await beneficiarioDB.getAtivosByConvenioId(ownerId, this.state.selecteds[0]);
      if (this.state.tipoPedido === PRE_PAGO) {
        this.state.applyValue = true;

        if (recarregarExcluidos) {
          const foraFaixa = await this.retornarBeneficiariosForaListaOriginal(beneficiarios);
          this.state.dataMine.preReloadDataSource = this.state.dataMine.originalDataSource;
          const originalDataSource = this.state.dataMine.originalDataSource;
          this.state.dataMine.originalDataSource = originalDataSource.concat(foraFaixa);
        }

        this.state.beneficiarios = beneficiarios;
        this.state.dataMine.originalDataSource = beneficiarios;
        this.applyValue(true, recarregarExcluidos);

        setTimeout(() => {
          this.setState({ loadingRecarregarTodos: false, loadingRecarregarExcluidos: false });
          mensagem.openNotificationWithIcon('success', 'Perfeito!', 'Beneficiários recarregados!');
        }, 3000);

        return;
      }

      const foraFaixa = await this.retornarBeneficiariosForaListaOriginal(beneficiarios);
      const originalDataSource = this.state.dataMine.originalDataSource;
      this.state.dataMine.originalDataSource = originalDataSource.concat(foraFaixa);

      // Nesta opção, apenas atualizo os valores daqueles que já estão adicionados sem reincluí-los
      if (!recarregarExcluidos) {
        for (let index = 0; index < this.state.dataMine.originalDataSource.length; index++) {
          if (!this.state.dataMine.originalDataSource[index].added) {
            this.state.dataMine.originalDataSource[index].valor = this.state.valorTodos;
            this.state.dataMine.originalDataSource[index].modified = true;
          }
        }
      }

      const dataMine = {
        deletedDataSource: this.state.dataMine.deletedDataSource,
        originalDataSource: this.state.dataMine.originalDataSource,
        dataSource: this.state.dataMine.originalDataSource,
        valorTotal: this.state.dataMine.valorTotal,
        convenio: this.state.dataMine.convenio,
      }
      this.setState({ dataMine, loadingRecarregarTodos: false, loadingRecarregarExcluidos: false });

      // const lista = this.state.tipoPedido === POS_PAGO ? this.state.beneficiarios : dataMine.dataSource;
      const lista = dataMine.dataSource;
      this.postCallBack(lista);
      mensagem.openNotificationWithIcon('success', 'Perfeito!', 'Beneficiários recarregados!');
    }, 500);
  }

  render() {
    return (
      <div>
        <Steps current={this.state.current}>
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className="steps-content">{steps[this.state.current].content}</div>
        <div className="steps-action">
          {this.state.current === 0 && (
            <div className="divStepDados">

              <div className="divStepDadosLabel" style={{ display: this.state.showCredDeb === true ? 'block' : 'none' }}>
                <label><strong>Tipo do Pedido:</strong></label>
              </div>

              <Select
                style={{ width: 200, display: this.state.showCredDeb === true ? 'block' : 'none' }}
                value={this.state.tipoPedido}
                onChange={this.tipoPedidoChange}
                disabled={this.props.editMode}
              >
                <Option value={POS_PAGO}>Pós pago</Option>
                <Option value={PRE_PAGO}>Pré pago (Crédito)</Option>
              </Select>
              <Divider style={{ display: this.state.showCredDeb === true ? 'block' : 'none' }}></Divider>

              <div className="divStepDadosLabel">
                <label><strong>Convênio:</strong></label>
              </div>

              <Select
                style={{ width: '100%' }}
                placeholder="Selecione um convênio"
                onChange={this.selectHandleChange}
                value={this.state.selecteds}
                showSearch
                disabled={this.props.editMode}

                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }

              >
                {this.state.convenios}
              </Select>
              <label className='paragrafoAvisoLimiteCreditoTelaInicial' style={{ display: this.state.limiteCreditoConvenio ? 'block' : 'none' }}>
                * Este convênio possui limite de crédito no valor de <strong>{this.state.limiteCreditoConvenioEmReais}</strong>. Caso ultrapasse, não será possível finalizar o pedido.</label>

              <div className="divStepDadosLabel">
                <label><strong>Aplicar este valor a todos beneficiários:</strong></label>
              </div>
              <div className="divStepDadosCurrency">
                <CurrencyInput
                  disabled={!this.state.stateEnabled}
                  className="wrapInputValueTotalValor"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  value={this.state.valorTodos}
                  onChange={this.valueTodosChange} />
              </div>
              <div className="divStepPeriodo">
                <Divider></Divider>
                <label><strong>Qual é o período deste pedido?</strong></label>
                <RangePicker
                  disabled={!this.state.stateEnabled}
                  format={dateFormat}
                  onChange={this.onDateChange}
                  placeholder={['Data Inicial', 'Data Final']}
                  value={[this.state.periodo.inicial, this.state.periodo.final]} />

                <Divider></Divider>
                <label><strong>Qual é o dia para entrar o crédito?</strong></label>
                <br></br>
                <InputNumber
                  disabled={!this.state.stateEnabled}
                  min={1} max={31}
                  value={this.state.periodo.day}
                  onChange={this.onDayChange} />
              </div>
            </div>
          )}

          {this.state.current === 1 && (
            <div className="divStepDados2">
              <div className="divRecarregarBeneficiariosPedidoCRUD">
                <Button
                  type="primary"
                  className="btnRecarregarBeneficiariosExcluidosPedidoCRUD"
                  onClick={() => this.btnRecarregarBeneficiarios(true)}
                  loading={this.state.loadingRecarregarExcluidos}
                  disabled={!this.state.stateEnabled || this.state.loadingRecarregarTodos}
                >Carregar Beneficiários Excluídos
                </Button>

                <Button
                  type="primary"
                  onClick={() => this.btnRecarregarBeneficiarios(false)}
                  loading={this.state.loadingRecarregarTodos}
                  disabled={!this.state.stateEnabled || this.state.loadingRecarregarExcluidos}
                >Recarregar Beneficiários
                </Button>
              </div>

              {(this.state.tipoPedido === PRE_PAGO && this.state.stateEnabled) && (
                <label className="avisoLimiteMaior"><strong>Atenção! </strong>NÃO INCLUA valores acima dos indicados abaixo. O sistema irá ignorar qualquer valor acima dos limites pré-estabelecidos pelo convênio ou pelo cadastro individual do beneficiário no momento da recarga de crédito.</label>
              )}

              <EditableFormTable
                className="tableBeneficiariosPedido"
                data={this.state.dataMine}
                callback={this.postCallBack}
                deleteCallBack={this.deleteCallBack}
                disabled={!this.state.stateEnabled}
                disabledEditValue={!this.state.stateEnabled}
                disableGrid={!this.state.stateEnabled}
              //disabledEditValue={this.state.tipoPedido !== POS_PAGO}
              />

              <CurrencyInput
                readOnly
                disabled
                className="wrapInputTotalValueTelaGrid"
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                value={this.state.dataMine.valorTotal} />


            </div>
          )}

          {this.state.current === 2 && (
            <div className="divStepsFinalizar">
              <label>Valor Total:</label>&nbsp;
              <CurrencyInput
                readOnly
                disabled
                className="wrapInputTotalValue"
                prefix="R$ "
                decimalSeparator=","
                thousandSeparator="."
                value={this.state.dataMine.valorTotal} />
              <br></br>
              <label>Total de Beneficiários:</label>&nbsp;
              <InputNumber
                className="wrapInputTotalValue"
                readOnly
                disabled
                value={this.state.beneficiariosComValor} />

              <br></br>
              <p
                style={{ display: this.state.limiteCreditoConvenioAtingido === true ? 'block' : 'none' }}
                className="paragrafoAvisoLimiteCredito">
                <Icon type="warning" />
                <label className="labelAvisoLimiteCredito">
                  <strong>&nbsp;ATENÇÃO!&nbsp;</strong>
                  Valor do pedido ultrapassa o limite do seu convênio que é de {this.state.limiteCreditoConvenioEmReais}
                </label></p>
            </div>
          )}

          {(this.state.current > 0 && this.state.current < steps.length - 1) && (
            <Button
              className="btnAnteriorPedido"
              disabled={this.state.loading || this.state.loadingRecarregarExcluidos || this.state.loadingRecarregarTodos}
              style={{ marginLeft: 8 }}
              onClick={() => this.prev()}>
              Anterior
            </Button>
          )}

          {(this.state.current === 0 || this.state.current === 1) && (
            <Button type="primary" onClick={() => this.next()} disabled={this.state.loading || this.state.loadingRecarregarExcluidos || this.state.loadingRecarregarTodos} loading={this.state.loading}>
              Próximo
            </Button>
          )}

          {(this.state.current === steps.length - 2 && !this.state.stateEnabled) && (
            <Button loading={this.state.loading} type="primary" onClick={() => this.salvarPedido(false)}>
              Fechar
            </Button>
          )}

          {(this.state.current === steps.length - 2 && this.state.stateEnabled) && (
            <Button loading={this.state.loading} type="primary" onClick={() => this.salvarPedido(false)}>
              Salvar Rascunho
            </Button>
          )}

          {(this.state.current === steps.length - 2 && this.state.stateEnabled) && (
            <Button 
            className="btnGerarPedidoComBoleto" 
            loading={this.state.loading} 
            disabled={this.state.limiteCreditoConvenioAtingido}
            type="danger" onClick={() => this.salvarPedido(true)}>
              Fechar Pedido e Gerar Boleto
            </Button>
          )}

          {(this.state.current === steps.length - 1) && (
            <div className="divProgress">
              <Progress type="circle" percent={this.state.percent} format={() => this.state.formatProgress} />
            </div>
          )}

        </div>
      </div >
    );
  }
}

export default Form.create()(PedidoCRUD);