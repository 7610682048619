import React from "react";
import { Switch, Route, Redirect, withRouter } from "react-router";
import { fireBase } from "./firebase/firebase";
import { logout } from './components/auth/auth-provider';
import ls from 'local-storage'
import Home from "./pages/home/Home"
import Login from "./pages/login/Login";
import Convenio from "./pages/convenio/Convenio";
import Gestor from "./pages/gestor/Gestor";
import Perfil from './pages/perfil/Perfil';
import Pedido from "./pages/pedidos/conveniado/Pedido";
// import Beneficiario from "./pages/beneficiarios/Beneficiario";
// import PedidoAdmin from "./pages/pedidos/administrador/PedidoAdmin";
import Estabelecimento from "./pages/estabelecimentos/Estabelecimento";
import Administrador from "./pages/administrador/Administrador";
import AdministradorUsuario from "./pages/administradorUsuario/AdministradorUsuario";
import AdministradorBeneficiario from "./pages/administradorBeneficiario/AdministradorBeneficiario";
import Configuracao from "./pages/configuracoes/Configuracao";
import Privacidade from "./pages/privacidade/Privacidade";
import Movimentos from "./pages/relatorios/movimentos/Movimentos";
import HistoricoMovimentos from './pages/relatorios/historicoMovimentos/HistoricoMovimentos';
import Saldos from './pages/relatorios/saldos/Saldos';
import Saldo from "./pages/saldo/Saldo";
import Extrato from './pages/relatorios/extrato/Extrato';
import SaldoCartao from "./pages/saldoCartao/SaldoCartao";
import Estorno from "./pages/estorno/Estorno";
import DescFolha from './pages/relatorios/desc-folha/DescFolha';
import CardsToPrint from "./pages/ferramentas/print/Print";
import NewCard from "./pages/ferramentas/novo-cartao/NovoCartao";
import Captura from "./pages/captura/Captura";
import SaldoConvenios from "./pages/relatorios/saldoConvenios/SaldoConvenios";
import Beneficiario from "./pages/beneficiarios/Beneficiario";
import Clientes from "./pages/relatorios/clientes/Clientes";
import VencimentoCartao from "./pages/relatorios/VencimentoCartao/VencimentoCartao";


class Routes extends React.Component {
    UNSAFE_componentWillMount() {
        fireBase.auth().onAuthStateChanged(user => {
            if (!user && this.props.location.pathname !== "/login" &&
                this.props.location.pathname !== "/privacidade-beneficiarios"
                && this.props.location.pathname !== "/saldo"
                && this.props.location.pathname !== "/saldo-cartao") {
                logout();
                this.props.history.push("/login");
            }
        });
    }

    render() {

        return (
            <div>

                <Switch>

                    {/* Telas que NÃO precisam estar logado */}

                    <Route path="/privacidade-beneficiarios" component={Privacidade} />
                    <Route path="/saldo" component={Saldo} />

                    {/* Telas que precisam estar logado */}

                    <PrivateRoute exact path="/dashboard">
                        <Home />
                    </PrivateRoute>

                    <PrivateRoute exact path="/perfil">
                        <Perfil />
                    </PrivateRoute>


                    {/* CRUDS */}

                    <PrivateRoute exact path="/convenios" >
                        <Convenio />
                    </PrivateRoute>

                    <PrivateRoute exact path="/lojas" >
                        <Convenio />
                    </PrivateRoute>

                    <PrivateRoute exact path="/administradores" >
                        <Administrador />
                    </PrivateRoute>

                    <PrivateRoute exact path="/rede" >
                        <Administrador />
                    </PrivateRoute>

                    <PrivateRoute exact path="/beneficiarios" >
                        <Beneficiario />
                    </PrivateRoute>



                    <PrivateRoute exact path="/gestores" >
                        <Gestor />
                    </PrivateRoute>

                    <PrivateRoute exact path="/administradores-usuarios" >
                        <AdministradorUsuario />
                    </PrivateRoute>

                    <PrivateRoute exact path="/administradores-beneficiarios" >
                        <AdministradorBeneficiario />
                    </PrivateRoute>

                    <PrivateRoute exact path="/estorno-debito" >
                        <Estorno />
                    </PrivateRoute>

                    <PrivateRoute exact path="/tools/captura" >
                        <Captura />
                    </PrivateRoute>

                    <PrivateRoute exact path="/configuracoes" >
                        <Configuracao />
                    </PrivateRoute>

                    {/* Usuários Conveniados */}

                    {/* <PrivateRoute exact path="/convenio-beneficiarios" >
                        <Beneficiario />
                    </PrivateRoute> */}

                    {/* <PrivateRoute exact path="/pedidos" >
                        <PedidoAdmin />
                    </PrivateRoute> */}

                    <PrivateRoute exact path="/convenio-pedidos" >
                        <Pedido />
                    </PrivateRoute>

                    <PrivateRoute exact path="/convenio-usuarios" >
                        <Gestor />
                    </PrivateRoute>

                    <PrivateRoute exact path="/estabelecimentos" >
                        <Estabelecimento />
                    </PrivateRoute>

                    {/*Relatórios */}
                    <PrivateRoute exact path="/relatorios/convenios" >
                        <Clientes />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/movimentacao" >
                        <Movimentos />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/historico-movimentacao" >
                        <HistoricoMovimentos />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/saldos" >
                        <Saldos />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/extrato" >
                        <Extrato />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/desconto-folha" >
                        <DescFolha />
                    </PrivateRoute>

                    <PrivateRoute exact path='/relatorios/saldo-convenios'>
                        <SaldoConvenios />
                    </PrivateRoute>

                    <PrivateRoute exact path="/relatorios/vencimento-cartao" >
                        <VencimentoCartao />
                    </PrivateRoute>



                    {/* Esse relatório não pode ser logado mesmo! Não altere. O path também não!*/}
                    <Route path="/saldo-cartao" component={SaldoCartao} />

                    {/* Usuários Conveniados - FIM */}


                    {/* Ferramentas */}
                    <PrivateRoute exact path="/tools/cards/print" >
                        <CardsToPrint />
                    </PrivateRoute>

                    <PrivateRoute exact path="/tools/cards/new" >
                        <NewCard />
                    </PrivateRoute>



                    <Route path="/login" component={Login} />
                    <Redirect from="*" to="/dashboard" />
                </Switch>
            </div>
        );
    }
}

export default withRouter(Routes);


function PrivateRoute({ children, ...rest }) {
    return (
        <Route
            {...rest}
            render={({ location }) => {
                const user = ls.get('user');
                const isOk = rest.debitRequired !== undefined ? rest.debitRequired : true;
                if (user && isOk) {
                    return children;
                }

                return <Redirect
                    to={{
                        pathname: "/login",
                        state: { from: location }
                    }}
                />
            }}
        />
    );
}
